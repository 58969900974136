// reservationCalendar

const initialState = {
    selectedMonth: "",
    reservations: [],
    freePlaces: [],
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SET_MONTH_RESEVATION":
            return {
                ...state,
                selectedMonth: payload,
            };
        case "SET_FREE_PLACES":
            return {
                ...state,
                freePlaces: payload,
            };
        case "GET_RESERVATIONS_CALENDAR":
            return {
                ...state,
                reservations: payload,
            };
        default:
            return state;
    }
};

export default reducer;
