import React, {useEffect} from "react";

import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

import PaymentStripe from "./PaymentStripe";
import {apiUrlPublic, stripeApiKey} from "../../../../helpers/apiUrl";

const StripeWrapper = () => {
    useEffect(() => {
        console.log("stripe_key: ", stripeApiKey);
        console.log("apiUrlPublic: ", apiUrlPublic);
    }, []);

    const stripePromise = loadStripe(stripeApiKey);
    // PROD
    // const stripePromise = loadStripe("pk_live_B8HdWAAi4FfaDV3u0pxI3g0b00FEs1qhOS");
    // DEV
    // const stripePromise = loadStripe("pk_test_0ABbdKVbMwdgiWXED81Dh2b500ApvrZZsX");
    return (
        <Elements stripe={stripePromise}>
            <PaymentStripe/>
        </Elements>
    );
};

export default StripeWrapper;
