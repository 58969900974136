import { useDispatch } from "react-redux";
const Dispatcher = () => {
    const dispatch = useDispatch();
    const setDateRangePicker = (name, value) => {
        dispatch({
            type: "SET_RANGE_DATE_PICKER",
            payload: { name, value },
        });
    };

    const setTimePicker = (name, value) => {
        dispatch({
            type: "SET_TIME_PICKER",
            payload: { name, value },
        });
    };
    const setResetDates = (value) => {
        dispatch({
            type: "SET_RESET_DATES",
            payload: value,
        });
        setTimePicker("arrival_at_time", "");
        setTimePicker("departure_at_time", "");
        setDateRangePicker("arrival_at", "");
        setDateRangePicker("departure_at", "");
    };
    const setBookingScreen = (name, value) => {
        dispatch({
            type: "SET_BOOKING_SCREEN",
            payload: { name, value },
        });
    };
    const setBookingData = (payload) => {
        dispatch({
            type: "SET_BOOKING_RESERVATION",
            payload,
        });
    };
    const toggleHomePopUps = (name, value) => {
        dispatch({
            type: "OPEN_POPUP_AUTH",
            payload: { name, value },
        });
    };
    const toggleCreateCar = (name, value) => {
        dispatch({
            type: "OPEN_POP_CLIENT",
            payload: { name, value },
        });
    };
    const setAuthError = (value) => {
        dispatch({
            type: "SET_AUTH_ERROR",
            payload: value,
        });
    };
    const setDisableSubmit = (value) => {
        dispatch({
            type: "SET_DISABLE_SUBMIT",
            payload: value,
        });
    };
    const clearDates = () => {
        dispatch({
            type: "CLEAR_DATES",
            payload: null,
        });
    };
    const setDates = (payload) => {
        dispatch({
            type: "SET_DATES",
            payload,
        });
    };
    const setPromocode = (payload) => {
        dispatch({
            type: "SET_PROMOCODE",
            payload,
        });
    };
    const clearPromocode = () => {
        dispatch({
            type: "CLEAR_PROMOCODE",
            payload: null,
        });
    };
    return {
        toggleCreateCar,
        setBookingScreen,
        setDateRangePicker,
        setTimePicker,
        setBookingData,
        toggleHomePopUps,
        setResetDates,
        setAuthError,
        setDisableSubmit,
        clearDates,
        setDates,
        setPromocode,
        clearPromocode
    };
};

export default Dispatcher;
