import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

import BackIcon from "../../../../images/icons/back-arrow-icon.svg";

import Preview from "./Preview/Preview";
import Edit from "./Edit/Edit";
import Reservations from "./Reservations/Reservations";
import ReservationsAdd from "./ReservationsAdd/ReservationsAdd";
import SingleLogic from "./SingleLogic";

const Single = ({ match }) => {
    const { t } = useTranslation();

    const tab = useSelector((state) => state.singleClient.tab);
    const client = useSelector((state) => state.singleClient.client);

    const { editMode, openEditClient, setTab, urlBase, openReservationEdit } = SingleLogic(match);

    const { id, first_name = "", last_name = "" } = client;
    const fullName = first_name + " " + last_name;

    const userPermitions = useSelector((state) => state.dashboardReducer.permitions);
    return (
        <>
            {userPermitions["create_reservation"] && <ReservationsAdd />}
            <div className='page-header top-section-list-nav'>
                <div className='nav-wrapper'>
                    <div className='username'>
                        <Link to={urlBase}>
                            <img className='back-arrow' src={BackIcon} />
                        </Link>

                        <div className='name'>
                            {t("clients.preview.main_title")} - {fullName}
                        </div>
                    </div>
                    <ul className='nav'>
                        <li>
                            <div
                                className={"menu-item " + (tab === 1 ? "active" : "")}
                                onClick={() => setTab(1)}
                            >
                                {t("clients.preview.information")}
                            </div>
                        </li>
                        {userPermitions["view_reservation"] && (
                            <li>
                                <div
                                    className={"menu-item " + (tab === 2 ? "active" : "")}
                                    onClick={() => setTab(2)}
                                >
                                    {t("clients.preview.reservations")}
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
                <div className='action-buttons'>
                    {tab === 1 && !editMode && (
                        <>
                            {userPermitions["update_client"] && (
                                <button
                                    onClick={() => openEditClient(id)}
                                    className='btn btn-submit mt-0 me-md-2'
                                >
                                    {t("clients.preview.edit")}
                                </button>
                            )}
                        </>
                    )}

                    {/* {tab === 2 && (
                        <>
                            {userPermitions["create_reservation"] && (
                                <button
                                    onClick={() => openReservationEdit()}
                                    className='btn btn-submit mt-0'
                                >
                                    {t("clients.add_new_reservation")}
                                </button>
                            )}
                        </>
                    )} */}
                </div>
            </div>
            {tab === 1 && !editMode && <Preview />}
            {tab === 1 && editMode && <Edit />}
            {tab === 2 && <Reservations />}
        </>
    );
};

export default Single;
