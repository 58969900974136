import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment-timezone";

import { CreateVacation } from "./_actions";
import { getVacationsCalendar } from "../Calendar/_actions";

const AddFreeDaysLogic = () => {
    const dispatch = useDispatch();
    const selectedMonth = useSelector((state) => state.vacationDays.selectedMonth);

    const initialState = {
        from_date: null,
        to_date: null,
        reason: "",
    };
    const [state, setState] = useState(initialState);

    const [loading, setLoader] = useState(false);

    const openSetFreeDays = (data) => {
        dispatch({
            type: "SHOW_ADD_FREEDAYS_POPUP",
            payload: data,
        });
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value,
        });
    };

    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);
        const { reason, from_date, to_date } = data;
        const moderated = {
            from_date: moment(from_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
            to_date: moment(to_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
            reason,
        };

        CreateVacation(moderated, dispatch).then((response = {}) => {
            setLoader(false);
            if (response.status === 201) {
                dispatch({
                    type: "SHOW_ADD_FREEDAYS_POPUP",
                    payload: false,
                });
                getVacationsCalendar(selectedMonth, dispatch);
                setState(data);
                setTimeout(() => {
                    setState(initialState);
                }, 1000);
            }

            if (response.errors) {
                let moderatedErrors = { error: "" };
                for (const property in response.errors) {
                    moderatedErrors = {
                        ...moderatedErrors,
                        [property]: response.errors[property].toString(),
                    };
                }

                setErrors(response.errors);
            }
        });
    };

    return { state, onChange, onSubmit, openSetFreeDays, loading };
};

export default AddFreeDaysLogic;
