import * as yup from "yup";

const Schema = yup.object().shape({
    first_name: yup.string().nullable().required("Requis!"),
    email: yup.string().email("Invalid Еmail").required("Requis!"),
    last_name: yup.string().nullable().required("Requis!"),
    phone: yup.string().nullable().required("Requis!"),
    brand: yup.string().nullable().required("Requis!"),
    model: yup.string().nullable().required("Requis!"),
    plate: yup.string().nullable().required("Requis!")
});

export default Schema;
