import { useDispatch } from "react-redux";
const Dispatcher = () => {
    const dispatch = useDispatch();
    const resetBookingForm = (name, value) => {
        dispatch({
            type: "RESET_BOOKING_DATA",
            payload: "",
        });
    };

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POPUP_AUTH",
            payload: { name, value },
        });
    };
    const showPriceCalculator = (val) => {
        dispatch({
            type: "SHOW_PRICE_CALCULATOR",
            payload: val,
        });
    };
    return {
        resetBookingForm,
        togglePopUp,
        showPriceCalculator,
    };
};

export default Dispatcher;
