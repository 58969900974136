import React from "react";

import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";
import { useSelector, useDispatch } from "react-redux";

import ClosePopup from "../../../images/icons/close-popup-icon.svg";

import logic from "./ClientProfileLogic";
import ProfilePreview from "./ProfilePreview";
import EditProfile from "./EditProfile/EditProfile";
import Reservations from "./Reservations";

import st from "./styles.module.scss";
const ClientProfile = () => {
    const { t } = useTranslation();

    // const screen = 2;
    const { show, togglePopUp, toggleScreen, screen } = logic();
    return (
        <>
            <div className={st.info_popup + " pop  client_forms " + (show ? "show" : "")}>
                <div className={st.inner + " inner"}>
                    <img
                        className={st.close}
                        src={ClosePopup}
                        onClick={() => togglePopUp("showProfile", false)}
                    />

                    <ul className={st.top_nav}>
                        <li
                            className={screen !== 3 ? st.active : ""}
                            onClick={() => toggleScreen(1)}
                        >
                            Profile
                        </li>
                        <li
                            className={screen === 3 ? st.active : ""}
                            onClick={() => toggleScreen(3)}
                        >
                            Reservations
                        </li>
                    </ul>

                    {screen === 1 && <ProfilePreview />}
                    {screen === 2 && <EditProfile />}
                    {screen === 3 && <Reservations />}
                </div>
            </div>
        </>
    );
};

export default ClientProfile;
