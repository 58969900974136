import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProfile, getVehicles, getReservations } from "./_actions";

const ClientProfileLogic = () => {
    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.clientAuth.token_client);
    const screen = useSelector((state) => state.clientProfile.screen);
    const show = useSelector((state) => state.clientProfile.showProfile);
    // const show = true;

    useEffect(() => {
        if (isAuth) {
            getProfile(dispatch);
            getVehicles(dispatch);
        }
    }, [isAuth]);

    useEffect(() => {
        if (show) {
            getVehicles(dispatch);
            getReservations(dispatch);
        }
    }, [show]);

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POP_CLIENT",
            payload: { name, value },
        });
    };
    const toggleScreen = (value) => {
        dispatch({
            type: "CHANGE_CLIENT_SCREEN",
            payload: value,
        });
    };

    return { show, togglePopUp, toggleScreen, screen };
};

export default ClientProfileLogic;
