import React from "react";
import { useTranslation } from "react-i18next";
import "../../languages/i18nextConf";

import { useSelector } from "react-redux";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import SearchIcon from "../../images/icons/search-icon.svg";
import PrevIcon from "../../images/icons/pagination-prev-icon.svg";
import NextIcon from "../../images/icons/pagination-next-icon.svg";

import ListTableLogic from "./ListTableLogic";

import st from "./styles.module.scss";

const ListTable = ({ children }) => {
    const { t } = useTranslation();

    const tableMeta = useSelector((state) => state.listTableReducer.meta);
    const headerResultLabel = useSelector((state) => state.listTableReducer.headerResultLabel);

    const {
        currentPage,
        perPage,
        lastPage,
        changeCurrentPage,
        handleChangeFilters,
        setPerPage,
        prevPage,
        nextPage,
        handleSort,
        sortTable,
        filterList,
        setDebounseSearch,
    } = ListTableLogic();
    const { total } = tableMeta || {};
    return (
        <>
            <div className={st.list_table_wrapper + " bg"}>
                <div className={st.header}>
                    <div className={st.search_bar}>
                        <img src={SearchIcon} />
                        <input
                            type='text'
                            placeholder={t("components.table.search")}
                            onChange={(e) => setDebounseSearch(e.target.value)}
                        />
                    </div>
                    <div className={st.filters}>
                        {Object.keys(filterList).map((el, i) => {
                            const item = filterList[el];
                            return (
                                <FormControl key={item.label}>
                                    <Select
                                        className={st.select}
                                        displayEmpty
                                        value={item.value}
                                        onChange={(e) => handleChangeFilters(e, i, item)}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            classes: { list: "filters_items" },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        <MenuItem value=''>{item.label}</MenuItem>
                                        {item.data.map((d) => {
                                            return (
                                                <MenuItem key={d.id} value={d.id}>
                                                    {d.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            );
                        })}
                    </div>
                    {headerResultLabel && <div className={st.result}>{total} results</div>}
                </div>
                <div className={st.table_wrap}>
                    <table className={st.list_table}>
                        <thead>
                            <tr>
                                {sortTable.map((item, i) => {
                                    return (
                                        <th
                                            onClick={() => handleSort(item)}
                                            key={i}
                                            className={
                                                item.status === "desc"
                                                    ? st.desc
                                                    : item.status === "asc"
                                                    ? st.asc
                                                    : ""
                                            }
                                        >
                                            {item.name !== "empty" ? item.label : ""}
                                            {item.sort && <div className={st.sort_icon}></div>}
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>{children}</tbody>
                    </table>
                </div>
                <div className={st.footer}>
                    <div className={st.result}>
                        {total} {t("components.table.result")}
                    </div>

                    <div className={st.pagination}>
                        <div
                            className={st.arrow + " " + (currentPage === 1 ? st.disabled : "")}
                            onClick={prevPage}
                        >
                            <img src={PrevIcon} />
                        </div>

                        <input type={st.text} value={currentPage} onChange={changeCurrentPage} />
                        <div className={st.info}>
                            {t("components.table.of")} {lastPage}
                        </div>

                        <div
                            className={
                                st.arrow + " " + (currentPage === lastPage ? st.disabled : "")
                            }
                            onClick={nextPage}
                        >
                            <img src={NextIcon} />
                        </div>
                    </div>

                    <div className={st.per_page}>
                        <p> {t("components.table.per_page")}</p>
                        <FormControl>
                            <Select
                                className={st.select}
                                displayEmpty
                                name='perPage'
                                value={perPage}
                                onChange={(e) => setPerPage(e.target.value)}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    classes: { list: "per_page_items" },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListTable;
