import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { getMyProfile, postProfileSetupInitial } from "./_actions";

const ProfileSetupLogic = (history) => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profileInfo.profile);

    useEffect(() => {
        getMyProfile(dispatch);
    }, []);

    const [state, setState] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        birth_date: null,
        city: "",
        street: "",
        street_number: "",
        district: "",
        post_code: "",
        insurance_number: "",
    });

    const [type, setType] = useState(0);

    const addProfilePic = (event) => {
        let file = event.target.files[0];

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setState({
                ...state,
                profile_photo_File: file,
                profile_photo: reader.result,
            });
        };
    };
    const removeProfilePic = (event) => {
        setState({
            ...state,
            profile_photo_File: null,
            profile_photo: null,
        });
    };

    useEffect(() => {
        if (Object.keys(profile).length !== 0) {
            const { is_owner, roles, status, birth_date, ...data } = profile;
            setState({ ...state, ...data });
        }
    }, [profile]);

    const [disableSubmit, setDisableSubmit] = useState(true);
    useEffect(() => {
        const { phone, country_id, city, accountant_experience } = state;
        if (type === 2) {
            if (phone && country_id && city && accountant_experience) {
                setDisableSubmit(false);
            }
        } else {
            if (phone && country_id && city) {
                setDisableSubmit(false);
            }
        }
    }, [state]);
    const [loading, setLoader] = useState(false);
    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);
        const { birth_date } = data;

        const birthDate = moment(birth_date).format("YYYY-MM-DD");

        postProfileSetupInitial({ ...data, birth_date: birthDate }, dispatch).then(
            (result = {}) => {
                const { status } = result;
                if (status === 204) {
                    history.push("/dashboard/my_profile");
                }
                if (result.errors) {
                    setErrors(result.errors);
                }
                setLoader(false);
            }
        );
    };

    return {
        disableSubmit,
        onSubmit,
        loading,
        state,
        type,
    };
};

export default ProfileSetupLogic;
