import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { createClient } from "./_actions";
import { getClientsList } from "./_actions";

const CreateLogic = () => {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.singleClient.client);
  const showCreate = useSelector((state) => state.singleClient.showCreate);

  const [loading, setLoader] = useState(false);

  const [switchState, setSwitchState] = useState({
    isChecked: false,
  });

  const handleChange = (event) => {
    setSwitchState({ ...state, [event.target.name]: event.target.checked });
  };

  const intialState = {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    brand: "",
    model: "",
    plate: ""
  };
  const [state, setState] = useState(intialState);
  const [active, toggleActive] = useState(false);
  const [type, toggleType] = useState(false);

  const clearForm = () => {
    setState({ ...state, id: null });
    setTimeout(() => {
      setState(intialState);
    }, 100);
  };

  const openClientCreate = () => {
    dispatch({
      type: "SHOW_CREATE_CLIENT_POPUP",
      payload: { id: "", status: false },
    });
  };

  const querySting = useSelector((state) => state.listTableReducer.query);
  const onSubmit = async (data, { setErrors, resetForm }) => {
    createClient(
      { ...data, country_id: "1", lang: "en", city: "sf", street: "sqw", post_code: 2000, street_number: "Vlasina", number: 12 },
      dispatch
    ).then((response = {}) => {
      setLoader(false);
      if (response.status === 200) {
        getClientsList(querySting, dispatch);
        dispatch({
          type: "SHOW_CREATE_CLIENT_POPUP",
          payload: { id: "", status: false },
        });
        resetForm({ values: "" });
        clearForm();
      }

      if (response.errors) {
        let moderatedErrors = { error: "" };
        for (const property in response.errors) {
          moderatedErrors = {
            ...moderatedErrors,
            [property]: response.errors[property].toString(),
          };
        }

        setErrors(response.errors);
      }
    });
  };

  return {
    openClientCreate,
    state,
    onSubmit,
    loading,
    clearForm,
    active,
    toggleActive,
    type,
    toggleType,
    switchState,
    handleChange,
  };
};

export default CreateLogic;
