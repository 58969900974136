import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../languages/i18nextConf";

import MetaTags from "react-meta-tags";

import { Link } from "react-router-dom";
import { Link as SrollLink } from "react-scroll";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import LogoTop from "../../images/home/logo-top.png";
import res_i from "../../images/home/res_i.png";
import phonePerson from "../../images/home/phone_person.png";
import LogoTopM from "../../images/home/logo-top-m.png";
import MM_icon from "../../images/home/mobile-menu_icon.png";
import ClosePopup from "../../images/icons/close-popup-icon.svg";

import st from "./styles.module.scss";

import Terms from "../../pages/Public/Home/Terms";
import Privacy from "../../pages/Public/Home/Privacy";
import Login from "../../pages/Public/ClientAuth/Login/Login";
import ForgotPassword from "../../pages/Public/ClientAuth/ForgotPassword/ForgotPassword";
import ResetPassword from "../../pages/Public/ClientAuth/ResetPassword/ResetPassword";
import CheckMail from "../../pages/Public/ClientAuth/CheckMail";
import EmailError from "../../pages/Public/ClientAuth/EmailError";
import EmailSuccess from "../../pages/Public/ClientAuth/EmailSuccess";

import ClientProfile from "../../pages/Public/ClientProfile/ClientProfile";
import ChangePassword from "../../pages/Public/ClientProfile/ChangePassword/ChangePassword";
import EditCar from "../../pages/Public/ClientProfile/EditCar/EditCar";
import DecreaseReservationSuccess from "../../pages/Public/ClientProfile/DecreaseReservationSuccess";
import DecreaseReservationError from "../../pages/Public/ClientProfile/DecreaseReservationError";
import CancelReservationSuccess from "../../pages/Public/ClientProfile/CancelReservationSuccess";
import CancelReservationError from "../../pages/Public/ClientProfile/CancelReservationError";
import CancelReservation from "../../pages/Public/ClientProfile/CancelReservation";
import EditReservation from "../../pages/Public/ClientProfile/EditReservation/EditReservation";
// import DatePickerRange from "../../components/DatePickerRange/DatePickerRange";
import TimePicker from "../../components/TimePicker/TimePicker";
import TimePickerMobile from "../../components/TimePickerMobile";

import HolidayError from "../../pages/Public/ClientBooking/HolidayError";

import Logic from "./Logic";
import { logout } from "../../pages/Public/ClientAuth/_actions";

const Index = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [menuState, setMenuState] = useState(false);

    const {
        isAuth,
        togglePopUp,
        togglePopUpProfile,
        language,
        changeLanguage,
        resetBookingForm,
        isReservacion,
    } = Logic();
    return (
        <>
            <MetaTags></MetaTags>
            <Login />
            <ResetPassword />
            <ForgotPassword />
            <CheckMail />
            <EmailSuccess />
            <EmailError />

            <ClientProfile />
            <ChangePassword />
            <DecreaseReservationSuccess />
            <DecreaseReservationError />
            <CancelReservationSuccess />
            <CancelReservationError />
            <CancelReservation />
            <EditReservation />
            <EditCar />

            <HolidayError />

            {/* <DatePickerRange /> */}
            <TimePicker />
            <TimePickerMobile />

            <Privacy />
            <Terms />

            <header className={st.header}>
                {!isReservacion && (
                    <div className={st.login_wrapper}>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    {!isAuth ? (
                                        <button
                                            type='button'
                                            className={st.login}
                                            onClick={() => {
                                                setMenuState(false);
                                                togglePopUp("showLogin", true);
                                            }}
                                        >
                                            {t("header.login")}
                                        </button>
                                    ) : (
                                        <button
                                            type='button'
                                            className={st.login}
                                            onClick={() => logout(dispatch)}
                                        >
                                            {t("header.logout")}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className={st.header_inner}>
                    <div className={st.nav_wrapper}>
                        <div className={st.logo_wrapper}>
                            <Link to={"/"}>
                                <img className={st.logo_d} src={LogoTop} alt='' />
                            </Link>

                            <ul className={st.main_nav}>
                                {!isReservacion ? (
                                    <>
                                        <li>
                                            <SrollLink
                                                to='about'
                                                spy={true}
                                                smooth={true}
                                                duration={500}
                                            >
                                                {t("header.menu_1")}
                                            </SrollLink>
                                        </li>
                                        <li>
                                            <SrollLink
                                                to='pricing'
                                                spy={true}
                                                smooth={true}
                                                duration={500}
                                            >
                                                {t("header.menu_2")}
                                            </SrollLink>
                                        </li>
                                        <li>
                                            <SrollLink
                                                to='services'
                                                spy={true}
                                                smooth={true}
                                                duration={500}
                                            >
                                                {t("header.menu_3")}
                                            </SrollLink>
                                        </li>
                                    </>
                                ) : (
                                    <li className={st.reservatin_title}>
                                        <img src={res_i} alt='' /> {t("header.menu_4")}
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className={st.button_wrapper}>
                            <div className={st.phone}>
                                <img src={phonePerson} alt='' />
                                <a href='tel:+41225101440'>+41 22 510 14 40</a>
                            </div>
                            <div
                                className={
                                    st.mobile_menu_wrapper + " " + (menuState ? st.show : "")
                                }
                                onClick={() => setMenuState(false)}
                            >
                                {!isAuth ? (
                                    <>
                                        <Link
                                            to={"/reservation"}
                                            className='green_btn_h'
                                            onClick={() => {
                                                setMenuState(false);
                                                resetBookingForm();
                                            }}
                                        >
                                            {t("book_btn")}
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            type='button'
                                            className='green_btn_h'
                                            onClick={() => {
                                                setMenuState(false);
                                                togglePopUpProfile("showProfile", true);
                                            }}
                                        >
                                            {t("header.profile")}
                                        </button>
                                    </>
                                )}
                                {isReservacion && (
                                    <>
                                        {!isAuth ? (
                                            <button
                                                type='button'
                                                className={st.login_b}
                                                onClick={() => {
                                                    setMenuState(false);
                                                    togglePopUp("showLogin", true);
                                                }}
                                            >
                                                {t("header.login")}
                                            </button>
                                        ) : (
                                            <button
                                                type='button'
                                                className={st.login_b}
                                                onClick={() => logout(dispatch)}
                                            >
                                                {t("header.logout")}
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={st.lang_selector}>
                        <button
                            className={language === "fr" ? st.active : ""}
                            type='button'
                            onClick={() => changeLanguage("fr")}
                        >
                            fr
                        </button>
                        <button
                            className={language === "en" ? st.active : ""}
                            type='button'
                            onClick={() => changeLanguage("en")}
                        >
                            en
                        </button>
                    </div>
                </div>
            </header>

            <header className={st.header_mobile}>
                <div className={st.header_inner}>
                    <Link to={"/"}>
                        <img className={st.logo} src={LogoTop} alt='' />
                    </Link>
                    <div className={st.action_buttons}>
                        {!isAuth ? (
                            <>
                                <Link
                                    to={"/reservation"}
                                    className='green_btn_h'
                                    onClick={() => {
                                        setMenuState(false);
                                        resetBookingForm();
                                    }}
                                >
                                    {t("book_btn")}
                                </Link>
                            </>
                        ) : (
                            <>
                                <button
                                    type='button'
                                    className={st.login}
                                    onClick={() => {
                                        setMenuState(false);
                                        togglePopUpProfile("showProfile", true);
                                    }}
                                >
                                    <i className='fas fa-user-alt'></i>
                                </button>
                            </>
                        )}
                    </div>
                    <div className={st.login_wrap}>
                        {!isAuth ? (
                            <button
                                type='button'
                                className={st.login}
                                onClick={() => {
                                    setMenuState(false);
                                    togglePopUp("showLogin", true);
                                }}
                            >
                                <i className='fas fa-sign-in-alt'></i>
                            </button>
                        ) : (
                            <button
                                type='button'
                                className={st.login}
                                onClick={() => logout(dispatch)}
                            >
                                <i className='fas fa-sign-out-alt'></i>
                            </button>
                        )}
                        <div
                            className={st.mobile_icon + " " + (menuState ? st.show : "")}
                            onClick={() => {
                                setMenuState(!menuState);
                            }}
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div className={st.mobile_menu_wrapper + " " + (menuState ? st.show : "")}>
                    <div className={st.lang_selector} onClick={() => setMenuState(false)}>
                        <button
                            className={language === "fr" ? st.active : ""}
                            type='button'
                            onClick={() => changeLanguage("fr")}
                        >
                            fr
                        </button>
                        <button
                            className={language === "en" ? st.active : ""}
                            type='button'
                            onClick={() => changeLanguage("en")}
                        >
                            en
                        </button>
                    </div>
                    <div className={st.button_wrapper}>
                        <ul className={st.main_nav}>
                            {!isReservacion ? (
                                <>
                                    <li>
                                        <SrollLink
                                            to='about'
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                            onClick={() => setMenuState(false)}
                                        >
                                            {t("header.menu_1")}
                                        </SrollLink>
                                    </li>
                                    <li>
                                        <SrollLink
                                            to='pricing'
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                            onClick={() => setMenuState(false)}
                                        >
                                            {t("header.menu_2")}
                                        </SrollLink>
                                    </li>
                                    <li>
                                        <SrollLink
                                            to='services'
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                            onClick={() => setMenuState(false)}
                                        >
                                            {t("header.menu_3")}
                                        </SrollLink>
                                    </li>
                                </>
                            ) : (
                                <li>
                                    <img src={res_i} alt='' /> {t("header.menu_4")}
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className={st.phone}>
                        <img src={phonePerson} alt='' />
                        <a href='tel:+41225101440'>{t("header.call_us")} +41 22 510 14 40</a>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Index;
