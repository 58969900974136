import React from "react";
import { Link } from "react-router-dom";

import PasswordIcon from "../../../images/icons/password-icon.svg";

import ButtonLoader from "../../../components/ButtonLoader/ButtonLoader";
import CreateAccountLogic from "./CreateAccountLogic";
import PasswordValidator from "../../../components/PasswordValidator/PasswordValidator";

const CreateAccount = ({ history }) => {
    const {
        onSubmit,
        setRulesCompleted,
        rulesCompleted,
        loading,
        errors,
        togglePassType,
        passType,
        state,
        onChange,
        handleErrors,
        email,
    } = CreateAccountLogic(history);

    const { password, password_confirmation } = state;
    return (
        <div className='intro-page'>
            <div className='intro-page-inner small'>
                <h1 className='title'>Park & Fly Geneva</h1>
                <h2 className='sub-title'>Create an account</h2>
                <p className='info-text text-center'>{email}</p>
                <form className='form-wrapper' noValidate onSubmit={onSubmit}>
                    <div className='simple-input-password'>
                        <input
                            name='password'
                            type={passType.password ? "text" : "password"}
                            placeholder='New password'
                            className='simple-input'
                            onChange={onChange}
                            value={password}
                        />
                        <img
                            className='icon password-icon'
                            onClick={() => togglePassType("password")}
                            src={PasswordIcon}
                        />
                    </div>
                    <div className='simple-input-password mt-3'>
                        <input
                            name='password_confirmation'
                            type={passType.password_confirmation ? "text" : "password"}
                            placeholder='Confirm new password'
                            className='simple-input'
                            onChange={onChange}
                            value={password_confirmation}
                        />
                        <img
                            className='icon password-icon'
                            onClick={() => togglePassType("password_confirmation")}
                            src={PasswordIcon}
                        />
                    </div>
                    {Object.keys(errors).map((error, i) => {
                        return (
                            <div key={i} className='custom-error'>
                                {errors[error]}
                            </div>
                        );
                    })}
                    <PasswordValidator
                        state={state}
                        handleErrors={handleErrors}
                        handleRulesCompleted={setRulesCompleted}
                    />
                    <button
                        disabled={loading || !rulesCompleted}
                        type='submit'
                        className='btn-large btn-submit full-width'
                    >
                        Confirm
                    </button>
                    <ButtonLoader show={loading} />
                </form>
                <div className='footer-text'>
                    Already have an account?{" "}
                    <Link to='/' className>
                        Log in
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CreateAccount;
