import React from "react";

import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-scroll";

import ErrorIcon from "../../../images/home/error_icon.png";
import ClosePopup from "../../../images/icons/close-popup-icon.svg";
import st from "./styles.module.scss";

const CancelReservationError = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const show = useSelector((state) => state.clientProfile.cancelReservationError);

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POP_CLIENT",
            payload: { name, value },
        });
    };
    return (
        <>
            <div
                className={
                    st.cancel_popup +
                    " " +
                    st.info_popup +
                    " pop client_forms " +
                    (show ? "show" : "")
                }
            >
                <div className={st.inner + " inner"}>
                    <img
                        className={st.close}
                        src={ClosePopup}
                        onClick={() => {
                            togglePopUp("cancelReservationError", false);
                            togglePopUp("showProfile", true);
                        }}
                    />
                    <div className={st.form_wrapper + " text-center"}>
                        <img src={ErrorIcon} alt='' className='mb-3' />
                        <h3 className='mb-0'>{t("client_profile.reservations.unsucess")}</h3>
                        <p className='mb-4'>
                            {t("client_profile.reservations.unsucess_message")}{" "}
                            <Link
                                className={st.book_now}
                                to='footer'
                                spy={true}
                                smooth={true}
                                duration={500}
                                onClick={() => {
                                    togglePopUp("cancelReservationError", false);
                                }}
                            >
                                <strong> {t("client_profile.reservations.contact")} </strong>
                            </Link>
                        </p>
                        <button
                            type='button'
                            onClick={() => {
                                togglePopUp("cancelReservationError", false);
                                togglePopUp("showProfile", true);
                            }}
                            className='green_btn_h'
                        >
                            {t("client_profile.reservations.continue")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CancelReservationError;
