// singleReservation

const initialState = {
    showPreview: false,
    showEdit: false,
    reservationId: "",
    client_id: "",
    reservation: {},
    allVallets: [],
    allClinets: [],
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    const { id, status, client_id } = payload || {};
    switch (type) {
        case "SHOW_PREVIEW_RESERVATION_POPUP":
            return {
                ...state,
                showPreview: status,
                reservationId: id,
            };
        case "SHOW_EDIT_RESERVATION_POPUP":
            return {
                ...state,
                showEdit: status,
                reservationId: id,
                client_id,
            };
        case "GET_ALL_VALLETS":
            const valets = payload.map((item) => {
                return { id: item.id, name: item.first_name + " " + item.last_name };
            });
            return {
                ...state,
                allVallets: valets,
            };
        case "GET_ALL_CLIENTS":
            const clients = payload.map((item) => {
                return {
                    id: item.id,
                    vehicles: item.vehicles,
                    name: item.first_name + " " + item.last_name,
                };
            });
            return {
                ...state,
                allClinets: clients,
            };
        case "GET_SINGLE_RESERVATION":
            return {
                ...state,
                reservation: payload,
            };
        default:
            return state;
    }
};

export default reducer;
