import { useDispatch } from "react-redux";

const Logic = () => {
    const dispatch = useDispatch();

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POPUP_AUTH",
            payload: { name, value },
        });
    };

    return {
        togglePopUp,
    };
};

export default Logic;
