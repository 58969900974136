import React from "react";
import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";

import { useSelector, useDispatch } from "react-redux";

import ClosePopup from "../../../images/icons/close-popup-icon.svg";
import st from "./styles.module.scss";

const Terms = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const show = useSelector((state) => state.clientAuth.showTerms);
    // const show = true;

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POPUP_AUTH",
            payload: { name, value },
        });
    };
    return (
        <div className={st.info_popup + " " + st.popup_terms + " pop  " + (show ? "show" : "")}>
            <div className={st.inner + " inner"}>
                <img
                    className={st.close}
                    src={ClosePopup}
                    onClick={() => togglePopUp("showTerms", false)}
                />
                <h4>{t("footer.terms_title")}</h4>
                <div className={st.text_wrapper}>
                    <p>
                        <strong>{t("footer.terms_text.title_1")}</strong>
                    </p>

                    <p> {t("footer.terms_text.text_1_1")} </p>
                    <p> {t("footer.terms_text.text_1_2")} </p>

                    <p className='mt-3'>
                        <strong>{t("footer.terms_text.title_2")}</strong>
                    </p>

                    <p> {t("footer.terms_text.text_2_1")} </p>
                    <p> {t("footer.terms_text.text_2_2")} </p>

                    <p className='mt-3'>
                        <strong>{t("footer.terms_text.title_3")}</strong>
                    </p>
                    <p>{t("footer.terms_text.text_3_1")}</p>
                    <p>{t("footer.terms_text.text_3_2")}</p>
                    <p className='mt-3'>
                        <strong>{t("footer.terms_text.title_4")}</strong>
                    </p>
                    <p>{t("footer.terms_text.text_4_1")} </p>
                    <p> {t("footer.terms_text.text_4_2")} </p>
                    <p> {t("footer.terms_text.text_4_3")} </p>
                    <p className='mt-3'>
                        <strong>{t("footer.terms_text.title_5")}</strong>
                    </p>

                    <p className='mt-2 mb-2'>{t("footer.terms_text.text_5_1")} </p>

                    <p className='mb-2'>{t("footer.terms_text.text_5_2")} </p>
                    <p className='mb-2'>{t("footer.terms_text.text_5_3")} </p>
                    <ol>
                        <li>{t("footer.terms_text.text_5_3_1")}</li>
                        <li>{t("footer.terms_text.text_5_3_2")}</li>
                        <li>{t("footer.terms_text.text_5_3_3")}</li>
                    </ol>
                    <p className='mt-3'>
                        <strong>{t("footer.terms_text.title_6")}</strong>
                    </p>

                    <p>{t("footer.terms_text.text_6_1")} </p>
                    <p>{t("footer.terms_text.text_6_2")} </p>
                    <p>{t("footer.terms_text.text_6_3")} </p>
                    <p>{t("footer.terms_text.text_6_4")} </p>
                    <p className='mt-3'>
                        <strong>{t("footer.terms_text.title_7")}</strong>
                    </p>
                    <p>{t("footer.terms_text.text_7")} </p>
                    <p className='mt-3'>
                        <strong>{t("footer.terms_text.title_8")}</strong>
                    </p>

                    <p>{t("footer.terms_text.text_8_1")} </p>
                    <p>{t("footer.terms_text.text_8_2")} </p>
                    <p>{t("footer.terms_text.text_8_3")} </p>
                </div>
            </div>
        </div>
    );
};

export default Terms;
