import React from "react";
import { Link } from "react-scroll";

import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";
import { TextField } from "formik-material-ui";
import { useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";

import forgotPassSchema from "../../../../_validations/forgotPassSchema";
import ClosePopup from "../../../../images/icons/close-popup-icon.svg";
import st from "../styles.module.scss";

import ForgotPasswordLogic from "./ForgotPasswordLogic";

const ForgotPassword = () => {
    const { t } = useTranslation();

    const show = useSelector((state) => state.clientAuth.showForgotPassword);

    const { togglePopUp, onSubmit, loading } = ForgotPasswordLogic();
    return (
        <div className={st.info_popup + " pop  " + (show ? "show" : "")}>
            <div className={st.inner + " inner"}>
                <img
                    className={st.close}
                    src={ClosePopup}
                    onClick={() => togglePopUp("showForgotPassword", false)}
                />
                <div className={st.form_wrapper}>
                    <h3>{t("client_auth.forgot_title")} </h3>
                    <p className={st.introText}>{t("client_auth.forgot_info")}</p>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            email: "",
                        }}
                        validationSchema={forgotPassSchema}
                        onSubmit={onSubmit}
                    >
                        {({ errors, isValid, dirty }) => {
                            return (
                                <Form className='client_forms'>
                                    <Field
                                        name='email'
                                        type='text'
                                        className='input_label'
                                        component={TextField}
                                        label={t("client_auth.email")}
                                    ></Field>
                                    <div className={st.btn_wrapper}>
                                        <button
                                            disabled={loading || !(isValid && dirty)}
                                            type='submit'
                                            className='green_btn_h'
                                        >
                                            {t("client_auth.send")}
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
