import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import dispatcher from "../_dispatcher";
import { calcPrice, checkForPlaces } from "../_actions";
import { formatDates } from "../../../../helpers/formatDates";

import moment from "moment";

const DatesLogic = () => {
  const {
    setBookingScreen,
    setBookingData,
    setResetDates,
    setDisableSubmit,
    setDates,
    setPromocode,
    clearDates,
  } = dispatcher();

  const dispatch = useDispatch();

  const initialState = {
    promocode: "",
  };
  const [state, setState] = useState(initialState);

  const onChange = (event) => {
    const { name, value } = event.target;
    console.log({name, value})
    setState({
      ...state,
      [name]: value,
    });
    if(name === 'promocode') {
        setPromocode(value)
    }
  };

  const resetDates = useSelector((state) => state.clientBooking.resetDates);
  const dates = useSelector((state) => state.clientBooking.dates) || {};
  const disableSumbit = useSelector(
    (state) => state.clientBooking.disableSumbit
  );
  const isAuth = useSelector((state) => state.clientAuth.token_client);
  const [customErrors, setCustomErrors] = useState(false);

  const aviableTime = () => {
    let hours = [];
    for (let hour = 4; hour < 24; hour++) {
      hours.push();
      hours.push(
        { time: moment({ hour }).format("HH:mm") },
        {
          time: moment({
            hour,
            minute: 15,
          }).format("HH:mm"),
        },
        {
          time: moment({
            hour,
            minute: 30,
          }).format("HH:mm"),
        },
        {
          time: moment({
            hour,
            minute: 45,
          }).format("HH:mm"),
        }
      );
    }

    return hours;
  };

  useEffect(() => {
    if (resetDates) {
      clearDates();
      setResetDates(false);
    }
  }, [resetDates]);

  useEffect(() => {
    const { arrival_at, departure_at, arrival_at_time, departure_at_time } =
      dates;
    if (
      Object.keys(dates).length > 0 &&
      arrival_at !== null &&
      departure_at !== null &&
      arrival_at_time.length > 0 &&
      departure_at_time.length > 0 &&
      !state.promocode
    ) {
      setCustomErrors(false);
      calcPrice({ ...formatDates(dates) }, dispatch).then((response = {}) => {
        if (response.status === 200) {
          const { price, real_days } = response.data.data;

          setDisableSubmit(false);
          const moderated = {
            ...formatDates(dates),
            price,
            real_days,
          };
          setBookingData(moderated);
        }
        if (response.errors) {
          const { arrival_at = [], departure_at = [] } = response.errors;
          if (
            arrival_at[0] === "Non-working Days!" ||
            departure_at[0] === "Non-working Days!"
          ) {
            setBookingScreen("showHolidayError", true);
            setDisableSubmit(true);
          }
        }
      });
    } else if (
      Object.keys(dates).length > 0 &&
      arrival_at !== null &&
      departure_at !== null &&
      arrival_at_time.length > 0 &&
      departure_at_time.length > 0 &&
      state.promocode
    ) {
      setCustomErrors(false);
      calcPrice({ ...formatDates(dates), promocode: state.promocode }, dispatch).then(
        (response = {}) => {
          if (response.status === 200) {
            const { price, real_days } = response.data.data;

            setDisableSubmit(false);
            const moderated = {
              ...formatDates(dates),
              price,
              real_days,
            };
            setBookingData(moderated);
          }
          if (response.errors) {
            const { arrival_at = [], departure_at = [] } = response.errors;
            if (
              arrival_at[0] === "Non-working Days!" ||
              departure_at[0] === "Non-working Days!"
            ) {
              setBookingScreen("showHolidayError", true);
              setDisableSubmit(true);
            }
          }
        }
      );
    }
  }, [dates, state]);

  let hasFreeSpot = false;
  const onSubmit = async (data, { setErrors }) => {
    const { arrival_at, arrival_at_time, departure_at, departure_at_time } =
      dates;

    if (
      arrival_at == null ||
      departure_at === null ||
      arrival_at_time.length === 0 ||
      departure_at_time.length === 0
    ) {
      return setCustomErrors(true);
    }
    checkForPlaces(formatDates(dates), dispatch).then((response = {}) => {
      if (response.status === 200) {
        const resp = response.data.data;
        for (let i = 0; i < Object.keys(resp).length; i++) {
          if (resp[Object.keys(resp)[i]].available) {
            hasFreeSpot = true;
            break;
          }
        }
        if (hasFreeSpot) {
          if (!isAuth) {
            setBookingScreen("active_step", 2.2);
          } else {
            setBookingScreen("active_step", 2.9);
          }

          setBookingScreen("complete_first_step", true);
          setBookingScreen("enable_second_step", true);
        }
      }
      if (response.errors) {
        setErrors(response.errors);
      }
    });
  };

  return {
    customErrors,
    aviableTime,
    dates,
    setDates,
    disableSumbit,
    onSubmit,
    onChange
  };
};

export default DatesLogic;
