import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";
import { Link } from "react-router-dom";

import phonePerson from "../../../../images/home/phone_person.png";

import Logic from "./Logic";

import st from "./styles.module.scss";

const Index = () => {
    const { t } = useTranslation();

    const {} = Logic();

    return (
        <>
            <div className={st.callUs_banner}>
                <div>
                    <p> {t("callUs.title")}</p>
                    <p>
                        {t("callUs.text_1")}
                        <br /> {t("callUs.text_2")}
                    </p>
                </div>
                <div className={st.phone}>
                    <img src={phonePerson} alt='' />
                    <a href='tel:+41225101440'>+41 22 510 14 40</a>
                </div>
            </div>
        </>
    );
};

export default Index;
