//listTableReducer

const initialState = {
    query: "page[number]=1&page[size]=10",
    columnHeaders: [],
    tableFilters: {},
    headerResultLabel: false,
    meta: {},
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SET_LIST_TABLE_QUERY":
            return {
                ...state,
                query: payload,
            };
        case "SET_TABLE_FILTERS_SORT":
            return {
                ...state,
                tableFilters: payload.tableFilters,
                columnHeaders: payload.column,
                headerResultLabel: payload.headerResultLabel,
            };
        case "SET_TABLE_META":
            return {
                ...state,
                meta: payload,
            };
        default:
            return state;
    }
};

export default reducer;
