import React, { useEffect, useState } from "react";

const PasswordValidatorLogic = (state, handleErrors, handleRulesCompleted) => {
    const [barsCounter, setBarsCounter] = useState(0);
    const [rules, setFRule] = useState({
        firsRule: false,
        secondRule: false,
        thirdRule: false,
    });

    const [firstConditionFlag, setFirstConditionFlag] = useState(true);
    const [secondConditionFlag, setSecondConditionFlag] = useState(true);
    const [thirdConditionFlag, setThirdConditionFlag] = useState(true);

    const hasLowerCase = (str) => {
        return str.toUpperCase() !== str;
    };
    const hasUpperCase = (str) => {
        return str.toLowerCase() !== str;
    };
    const hasNumber = (str) => {
        return /\d/.test(str);
    };
    const hasSpecialCharacter = (str) => {
        return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
    };
    useEffect(() => {
        const { password, password_confirmation } = state;
        const { firsRule, secondRule, thirdRule } = rules;
        let first = firsRule;
        let second = secondRule;
        let third = thirdRule;
        if (password !== undefined) {
            if (firstConditionFlag && password.length > 7) {
                first = true;
                setFirstConditionFlag(false);
            } else if (!firstConditionFlag && password.length < 8) {
                first = false;
                setFirstConditionFlag(true);
            }

            if (
                secondConditionFlag &&
                password.length > 0 &&
                hasLowerCase(password) &&
                hasUpperCase(password)
            ) {
                second = true;
                setSecondConditionFlag(false);
            } else if (
                !secondConditionFlag &&
                (!hasLowerCase(password) || !hasUpperCase(password))
            ) {
                second = false;
                setSecondConditionFlag(true);
            }

            if (
                thirdConditionFlag &&
                password.length > 0 &&
                (hasNumber(password) || hasSpecialCharacter(password))
            ) {
                third = true;
                setThirdConditionFlag(false);
            } else if (
                !thirdConditionFlag &&
                !hasNumber(password) &&
                !hasSpecialCharacter(password)
            ) {
                third = false;
                setThirdConditionFlag(true);
            }
            setFRule({
                firsRule: first,
                secondRule: second,
                thirdRule: third,
            });
        }
        if (password !== undefined && password_confirmation !== undefined) {
            if (
                password.length > 8 &&
                password_confirmation.length > 8 &&
                password !== password_confirmation
            ) {
                handleErrors("Passwords Do not match!");
            } else {
                handleErrors("");
            }
        }
    }, [state]);

    useEffect(() => {
        const { password, password_confirmation } = state;
        let counter = 0;
        Object.keys(rules).map((item) => {
            if (rules[item]) {
                counter++;
            }
        });
        setBarsCounter(counter);
        if (password === password_confirmation && counter === 3) {
            handleRulesCompleted(true);
        }
    }, [rules]);

    const [passType, setPassType] = useState({
        password: false,
        password_confirmation: false,
    });

    const togglePassType = (name) =>
        setPassType({
            ...passType,
            [name]: !passType[name],
        });

    return { togglePassType, passType, barsCounter };
};

export default PasswordValidatorLogic;
