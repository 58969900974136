import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useSelector } from "react-redux";

import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import InputAdornment from "@material-ui/core/InputAdornment";
import { DatePicker } from "formik-material-ui-pickers";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import InputLabel from "@material-ui/core/InputLabel";
import { Select } from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/en-gb";
import ButtonLoader from "../../../../components/ButtonLoader/ButtonLoader";
import ClosePopup from "../../../../images/icons/close-popup-icon.svg";

import EditEmployeeSchema from "../../../../_validations/EditEmployeeSchema";

import EditLogic from "./EditLogic";

import st from "./styles.module.scss";

const Edit = () => {
    const { t } = useTranslation();

    const { openEmployeeEdit, state, onSubmit, loading, show } = EditLogic();

    const { email, role_id, phone } = state;

    return (
        <>
            <div className={"pop  " + (show ? "show" : "")}>
                <div className={st.inner + " inner"}>
                    <img
                        className={st.close}
                        src={ClosePopup}
                        onClick={() => {
                            openEmployeeEdit();
                        }}
                    />

                    <div className={st.summary}>
                        <Formik
                            enableReinitialize
                            initialValues={state}
                            validationSchema={EditEmployeeSchema}
                            onSubmit={onSubmit}
                        >
                            {({ errors, touched, isValidating, isValid, dirty }) => {
                                return (
                                    <Form>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <h2>
                                                    <strong>{t("employees.single.title")}</strong>
                                                </h2>
                                                <p className='mb-4'>
                                                    <strong>{email}</strong>
                                                </p>
                                                <h3 className='title mb-3'>
                                                    {t("employees.single.info_title")}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='first_name'
                                                        type='text'
                                                        label={t("employees.single.first_name")}
                                                        component={TextField}
                                                        className=' mb-3'
                                                    ></Field>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='last_name'
                                                        type='text'
                                                        label={t("employees.single.last_name")}
                                                        component={TextField}
                                                        className=' mb-3'
                                                    ></Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='phone'
                                                        type='text'
                                                        label={t("employees.single.phone")}
                                                        component={TextField}
                                                    ></Field>{" "}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <MuiPickersUtilsProvider
                                                    libInstance={moment}
                                                    utils={MomentUtils}
                                                    locale='en-gb'
                                                >
                                                    <Field
                                                        autoOk={true}
                                                        showTodayButton={true}
                                                        format='DD.MM.YYYY'
                                                        name='birth_date'
                                                        type='text'
                                                        label={t("employees.single.birghtday")}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position='start'>
                                                                    <i className='far fa-calendar-alt'></i>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        className='full-width mb-3 date-input'
                                                        InputLabelProps={{ shrink: true }}
                                                        component={DatePicker}
                                                        inputVariant='outlined'
                                                    ></Field>
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='insurance_number'
                                                        type='text'
                                                        label={t("employees.single.insurance")}
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <FormControl
                                                    className='full-width select-input mb-3'
                                                    variant='outlined'
                                                    error={
                                                        touched["role_id"] && !!errors["role_id"]
                                                    }
                                                >
                                                    <InputLabel shrink={true}>
                                                        {t("employees.single.roleName")}
                                                    </InputLabel>
                                                    <Field
                                                        component={Select}
                                                        type='text'
                                                        name='role_id'
                                                    >
                                                        <MenuItem value={1}>Admin</MenuItem>
                                                        <MenuItem value={2}>Manager</MenuItem>
                                                        <MenuItem value={3}>Valet</MenuItem>
                                                    </Field>
                                                    {errors.role_id && (
                                                        <FormHelperText>
                                                            {errors.role_id}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-md-12'>
                                                <h3 className='title mb-3'>
                                                    {t("employees.single.adress_title")}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='city'
                                                        type='text'
                                                        label={t("employees.single.city")}
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='district'
                                                        type='text'
                                                        label={t("employees.single.district")}
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='post_code'
                                                        type='text'
                                                        label={t("employees.single.zip")}
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='street'
                                                        type='text'
                                                        label={t("employees.single.street")}
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='input-border mb-3'>
                                                    <Field
                                                        name='street_number'
                                                        type='text'
                                                        label={t("employees.single.street_number")}
                                                        component={TextField}
                                                    ></Field>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            disabled={loading}
                                            type='submit'
                                            className='btn btn-submit'
                                        >
                                            {t("employees.single.submit")}
                                        </button>

                                        <ButtonLoader show={loading} />
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Edit;
