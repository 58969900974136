// clientsList

const initialState = {
    items: [],
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "GET_CLIENTS_LIST":
            return {
                ...state,
                items: payload,
            };
        default:
            return state;
    }
};

export default reducer;
