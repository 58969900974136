import axios from "../../../../configAxios.js";
import { setMessage } from "../../../../components/AlertMessage/_actions.js";
export const getSingleClient = (id, dispatch) => {
    return axios
        .get("/clients/" + id + "?include=vehicles")
        .then((result) => {
            const { status, data } = result;
            if (status === 200) {
                dispatch({
                    type: "GET_SINGLE_CLIENT",
                    payload: data.data,
                });
                dispatch({
                    type: "GET_SINGLE_CLIENT_VECHICLES",
                    payload: data.data.vehicles,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const deleteSingleClient = (id, dispatch) => {
    return axios
        .delete("/clients/" + id)
        .then((result) => {
            const { status, data } = result;
            if (status === 204) {
                dispatch(setMessage(result));
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
