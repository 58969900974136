import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPricesList, setPrices } from "./_actions";

const ListLogic = () => {
    const dispatch = useDispatch();

    const list = useSelector((state) => state.pricesList.items);

    const [state, setState] = useState([]);
    useEffect(() => {
        getPricesList(dispatch);
    }, []);

    useEffect(() => {
        if (Object.keys(list) !== 0) {
            let prices = [];
            let current = "";
            for (let i = 1; i < 42; i++) {
                current = list.find((item) => item.day === i);

                if (current) {
                    prices.push(current);
                } else {
                    prices.push({ day: i, price: "" });
                }
            }
            setState(prices);
        }
    }, [list]);

    const onChange = (p, e) => {
        const { value } = e.target;

        const prices = state;
        const updated = prices.map((item) => {
            return item.day === p.day ? { day: p.day, price: parseInt(value) } : item;
        });

        setState(updated);
    };

    const [loading, setLoader] = useState(false);

    const onSubmit = (e) => {
        setLoader(true);
        e.preventDefault();
        setPrices({ price_list: state }, dispatch).then((response = {}) => {
            setLoader(false);
        });
    };

    return { state, list, onChange, onSubmit, loading };
};

export default ListLogic;
