import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useSelector } from "react-redux";

import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import InputAdornment from "@material-ui/core/InputAdornment";
import { DatePicker, TimePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/en-gb";

import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiTextField from "@material-ui/core/TextField";

import { Select } from "formik-material-ui";
import { MenuItem, FormControl, InputLabel } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";

import ButtonLoader from "../../../../components/ButtonLoader/ButtonLoader";
import ClosePopup from "../../../../images/icons/close-popup-icon.svg";

import {
  NewClient,
  ExistingClient,
} from "../../../../_validations/EditReservationsSchema";

import EditLogic from "./EditLogic";

import st from "./styles.module.scss";

const Edit = () => {
  const { t } = useTranslation();
  const show = useSelector((state) => state.singleReservation.showEdit);
  // const show = true;

  const {
    openReservationEdit,
    state,
    onSubmit,
    loading,
    daysDuration,
    handleClientChange,
    client_data,
    clients_list,
    parkingsList,
    cars_list,
    selectedClient,
  } = EditLogic();
  const { id = null } = state;
  const { id: selectedClientId = "" } = client_data;

  return (
    <>
      <div className={"pop  " + (show ? "show" : "")}>
        <div className={st.inner + " inner"}>
          <img
            className={st.close}
            src={ClosePopup}
            onClick={() => openReservationEdit()}
          />
          <div className={st.summary}>
            <Formik
              enableReinitialize
              initialValues={state}
              validationSchema={ExistingClient}
              onSubmit={onSubmit}
            >
              {({ errors, touched, setFieldValue, setTouched }) => {
                return (
                  <Form>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <h2>
                          <strong>
                            {id
                              ? t("reservations.single.edit_title")
                              : t("reservations.single.create_title")}
                          </strong>
                        </h2>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-border mb-3">
                          <Field
                            disabled={Boolean(selectedClient)}
                            name="client_data"
                            value={client_data}
                            className="autocomplete"
                            options={clients_list}
                            getOptionLabel={(option) => option.name}
                            component={Autocomplete}
                            onChange={(event, value) => {
                              handleClientChange(event, value);
                              setFieldValue("client_id", event.target.value);
                            }}
                            onBlur={() =>
                              setTouched({
                                ["client_id"]: true,
                              })
                            }
                            renderInput={(params) => {
                              return (
                                <MuiTextField
                                  label={t("reservations.single.client") + " *"}
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "off",
                                  }}
                                  name="client_id"
                                  error={
                                    touched["client_id"] && errors["client_id"]
                                  }
                                  helperText={
                                    touched["client_id"] && errors["client_id"]
                                  }
                                />
                              );
                            }}
                          ></Field>
                        </div>
                        <FormControl
                          className="full-width select-input mb-3"
                          variant="outlined"
                          error={
                            touched.vehicle_id && errors.vehicle_id
                              ? true
                              : false
                          }
                        >
                          <InputLabel>
                            {t("reservations.single.car")} *
                          </InputLabel>
                          <Field
                            disabled={selectedClientId.length === 0}
                            component={Select}
                            type="text"
                            name="vehicle_id"
                          >
                            {cars_list.map((item) => {
                              return (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.brand} {item.model} {item.plate}
                                </MenuItem>
                              );
                            })}
                          </Field>
                          {touched.vehicle_id && errors.vehicle_id && (
                            <FormHelperText>{errors.vehicle_id}</FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <FormControl
                          className="full-width select-input mb-3"
                          variant="outlined"
                          error={
                            touched.parking_id && errors.parking_id
                              ? true
                              : false
                          }
                        >
                          <InputLabel>
                            {t("reservations.single.parking")} *
                          </InputLabel>
                          <Field
                            component={Select}
                            type="text"
                            name="parking_id"
                          >
                            {parkingsList.map((item) => {
                              return (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Field>
                          {touched.parking_id && errors.parking_id && (
                            <FormHelperText>{errors.parking_id}</FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={MomentUtils}
                      locale="en-gb"
                    >
                      <div className="row">
                        <div className="col-md-3">
                          <Field
                            autoOk={true}
                            showTodayButton={true}
                            format="DD.MM.YYYY"
                            name="departure_at"
                            type="text"
                            label={t("reservations.single.departure_at") + " *"}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <i className="far fa-calendar-alt"></i>
                                </InputAdornment>
                              ),
                            }}
                            className="full-width mb-3 date-input"
                            InputLabelProps={{ shrink: true }}
                            component={DatePicker}
                            inputVariant="outlined"
                          ></Field>
                        </div>
                        <div className="col-md-3">
                          <Field
                            ampm={false}
                            name="departure_time"
                            type="text"
                            label={
                              t("reservations.single.departure_time") + " *"
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <i className="far fa-clock"></i>{" "}
                                </InputAdornment>
                              ),
                            }}
                            className="full-width mb-3 date-input"
                            InputLabelProps={{ shrink: true }}
                            component={TimePicker}
                            inputVariant="outlined"
                          ></Field>
                        </div>
                        <div className="col-md-3">
                          <Field
                            autoOk={true}
                            showTodayButton={true}
                            format="DD.MM.YYYY"
                            name="arrival_at"
                            label={t("reservations.single.arrival_at") + " *"}
                            type="text"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <i className="far fa-calendar-alt"></i>
                                </InputAdornment>
                              ),
                            }}
                            className="full-width mb-3 date-input"
                            InputLabelProps={{ shrink: true }}
                            component={DatePicker}
                            inputVariant="outlined"
                          ></Field>
                        </div>

                        <div className="col-md-3">
                          <Field
                            ampm={false}
                            name="arrival_time"
                            label={t("reservations.single.arrival_time") + " *"}
                            type="text"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <i className="far fa-clock"></i>{" "}
                                </InputAdornment>
                              ),
                            }}
                            className="full-width mb-3 date-input"
                            InputLabelProps={{ shrink: true }}
                            component={TimePicker}
                            inputVariant="outlined"
                          ></Field>
                        </div>
                      </div>
                    </MuiPickersUtilsProvider>
                    <div className="row">
                      <div className="col-6 mb-3">
                        <p className="">
                          {t("reservations.single.duration")}:
                          <strong>
                            {daysDuration}
                            {daysDuration === 1 ? " day" : " days"}
                          </strong>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-border textarea mb-3">
                          <Field
                            name="note"
                            type="text"
                            multiline={true}
                            label={t("reservations.single.note") + " *"}
                            component={TextField}
                          ></Field>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-border mb-3">
                          <Field
                            name="flight_number"
                            type="text"
                            label={t("reservations.single.flight") + " *"}
                            component={TextField}
                          ></Field>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-border mb-3">
                          <Field
                            name="promocode"
                            type="text"
                            label={t("reservations.single.promocode")}
                            component={TextField}
                          ></Field>
                        </div>
                      </div>
                    </div>

                    <button
                      disabled={loading}
                      type="submit"
                      className="btn btn-submit me-2"
                    >
                      {t("reservations.single.submit")}
                    </button>
                    <button type="button" className="btn simple-btn">
                      {t("reservations.single.clear")}
                    </button>

                    <ButtonLoader show={loading} />
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
