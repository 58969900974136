// alertMessage

const initialState = {
    show: false,
    success: "",
    message: "",
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SET_ALERT_MESSAGE":
            return {
                show: true,
                ...payload,
            };
        case "REMOVE_ALERT_MESSAGE":
            return {
                show: false,
                success: "",
                message: "",
            };
        default:
            return state;
    }
};

export default reducer;
