import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import Checkbox from "@material-ui/core/Checkbox";
import { TextField, Select } from "formik-material-ui";
import { MenuItem, FormControl, FormHelperText, Button } from "@material-ui/core";
import { Formik, Field, Form } from "formik";

import { carInfo, clientCar } from "../../../../_validations/clientRegisterBooking";

import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import StripeLogo from "../../../../images/home/stripe_methods.png";
import PlaneLoader from "./PlaneLoader";
import PaymentStripeLogic from "./PaymentStripeLogic";
import "./styles.css";

import st from "./styles.module.scss";
import { Link } from "react-scroll";

const PaymentStripe = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        toggleHomePopUps,
        vehicles,
        agree,
        toggleAgree,
        vehicle_id,
        changeVehicle,
        state,
        updateState,
        options,
        onSubmit,
        processing,
        succeeded,
        error,
        handleChange,
        disabled,
        setBookingScreen,
        aditional_payment,
        isAuth,
        isValid,
        showCreateCar,
        setShowCreateCar,
        set_vehicle_id,
        clearFields
    } = PaymentStripeLogic();
    const { flight_number, brand, model, plate } = state;

    const showCreateCarLogic = (value) => {
        setShowCreateCar(value)
        set_vehicle_id("")
        clearFields()
    }

    return (
        <div className='stripe_wrapper client_forms'>
            {processing && <PlaneLoader />}

            <Formik
                enableReinitialize
                initialValues={{ ...state, agree }}
                validationSchema={carInfo}
                onSubmit={onSubmit}
            >
                {({ errors, touched, dirty }) => {
                    return (
                        <Form className='client_forms'>
                            <h4 className={st.main_title}>
                                {t("client_booking.step_3.sec_title")}
                            </h4>
                            <div className={st.stripe_wrapper}>
                                <div className={st.car_wrapper}>
                                    <h5
                                        className={st.section_title}
                                        style={{ marginBottom: "19px" }}
                                    >
                                        {t("client_booking.step_3.car_title")}
                                    </h5>

                                    {vehicles.length > 0 && !showCreateCar && (
                                        <>
                                            <label className='my_label'>
                                                {t("client_booking.step_3.select_car")}
                                            </label>
                                            <FormControl
                                                className='input_label_select'
                                                variant='outlined'
                                            >
                                                <Field
                                                    component={Select}
                                                    type='text'
                                                    disabled={aditional_payment}
                                                    name='vehicle_id'
                                                    value={vehicle_id}
                                                    onChange={changeVehicle}
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left",
                                                        },
                                                        getContentAnchorEl: null,
                                                    }}
                                                >
                                                    {vehicles.map((item) => {
                                                        return (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item.brand +
                                                                    " " +
                                                                    item.model +
                                                                    " " +
                                                                    item.plate}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Field>
                                            </FormControl>
                                        </>
                                    )}
                                    
                                        <Button 
                                            style={{display: 'block', color: '#30d193', textDecoration: 'underline'}}
                                            className="mb-3"
                                            onClick={() => {showCreateCarLogic(!showCreateCar)}}>
                                            {showCreateCar ? t("client_booking.step_3.go_back") : t("client_booking.step_3.new_car_2")}
                                        </Button>
                                    
                                    {showCreateCar && (
                                        <>
                                            <label className='label_custom' htmlFor=''>
                                                {t("client_booking.step_3.brand") + "*"}
                                            </label>
                                            <>
                                                <Field
                                                    name='brand'
                                                    type='text'
                                                    className='input_label'
                                                    component={TextField}
                                                    disabled={aditional_payment}
                                                    value={brand}
                                                    onChange={updateState}
                                                ></Field>
                                                <label className='label_custom' htmlFor=''>
                                                    {t("client_booking.step_3.model") + "*"}
                                                </label>
                                                <Field
                                                    name='model'
                                                    type='text'
                                                    className='input_label'
                                                    component={TextField}
                                                    disabled={aditional_payment}
                                                    value={model}
                                                    onChange={updateState}
                                                ></Field>
                                                <label className='label_custom' htmlFor=''>
                                                    {t("client_booking.step_3.plate") + "*"}
                                                </label>
                                                <Field
                                                    name='plate'
                                                    type='text'
                                                    className='input_label'
                                                    component={TextField}
                                                    disabled={aditional_payment}
                                                    value={plate}
                                                    onChange={updateState}
                                                ></Field>
                                            </>
                                        </>
                                    )}

                                    <br />

                                    <label className='label_custom' htmlFor=''>
                                        {t("client_booking.step_3.flight_number") + "*"}
                                    </label>
                                    <Field
                                        name='flight_number'
                                        type='text'
                                        disabled={aditional_payment}
                                        className='input_label'
                                        component={TextField}
                                        value={flight_number}
                                        onChange={updateState}
                                    ></Field>
                                </div>

                                <div className={st.payment_fields}>
                                    <h5 className={st.section_title}>
                                        {t("client_booking.step_3.payment_title")}{" "}
                                    </h5>
                                    <label className='stripe_label'>
                                        <span>{t("client_booking.step_3.card_number") + "*"}</span>
                                        <CardNumberElement
                                            options={options}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <label className='stripe_label'>
                                        <span> {t("client_booking.step_3.card_date") + "*"}</span>

                                        <CardExpiryElement
                                            options={options}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <label className='stripe_label'>
                                        <span>{t("client_booking.step_3.cvc") + "*"} </span>

                                        <CardCvcElement options={options} onChange={handleChange} />
                                    </label>

                                    {error && (
                                        <div className='card-error' role='alert'>
                                            {error}
                                        </div>
                                    )}

                                    <div className='client_checkbox'>
                                        <Checkbox
                                            checked={agree}
                                            onChange={() => toggleAgree(!agree)}
                                            name='agree'
                                            color='primary'
                                            icon={<i className='fas fa-circle'></i>}
                                            checkedIcon={<i className='fas fa-check-circle'></i>}
                                        />
                                        {t("client_booking.step_3.tt_text")}{" "}
                                        <span
                                            onClick={() => toggleHomePopUps("showTerms", true)}
                                            className='link'
                                        >
                                            {t("client_booking.step_3.tt_link")}
                                        </span>
                                    </div>
                                    <button
                                        type='submit'
                                        className='green_btn_h'
                                        disabled={
                                            !agree
                                            // !isValid ||
                                            // !agree ||
                                            // processing ||
                                            // disabled ||
                                            // succeeded
                                        }
                                        id='submit'
                                    >
                                        {t("client_booking.step_3.pay_btn")}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>

            <div className='bottom_links_wrapper'>
                {!aditional_payment && (
                    <button
                        type='button'
                        className='btn_back '
                        onClick={() => setBookingScreen("active_step", !isAuth ? 2.4 : 1)}
                    >
                        {t("client_booking.back")}
                    </button>
                )}
                {aditional_payment && (
                    <button
                        type='button'
                        className='btn_back  '
                        onClick={() =>
                            dispatch({
                                type: "OPEN_POP_CLIENT",
                                payload: {
                                    name: "showEditReservation",
                                    value: true,
                                },
                            })
                        }
                    >
                        {t("client_booking.back")}
                    </button>
                )}
            </div>
        </div>
    );
};

export default PaymentStripe;
