export const TRANSLATIONS_FR = {
    book_btn: "Réserver",
    header: {
        login: "login",
        menu_1: " À propos",
        menu_2: "Nos tarifs",
        menu_3: "Notre service",
        menu_4: "Formulaire sécurisé de réservation",
        logout: "Se déconnecter",
        profile: "Profil & Réservations",
        call_us: "Nous appeler",
    },
    hero: {
        title: "Plus besoin de chercher une place en arrivant à l’aéroport Genève Cointrin.",
        secondary_title:
            "Service de stationnement pratique et abordable à l’aéroport Cointrin 7j/7 de 4h à 1h",
        list_1: "Planifiez et réservez facilement votre stationnement à l’avance",
        list_2: "Confiez votre véhicule à des professionnels de confiance",
        list_3: "Gagnez du temps et prenez votre avion sereinement",
        consult: "Consulter les tarifs",
        payments: "Mode de paiements approuvés",
    },
    google_baner: {
        title: "Google Reviews",
        info: "L'expérience de nos clients est notre priorité. Découvrez les avis des utilisateurs de ParkFly.",
        link: "Donner un avis",
        reviews: "Reviews",
    },
    advantages_box: {
        title_1: "Park Fly, le service de parking qui",
        title_2: "vous épargne bien des tracas",
        section_1_title: "Vous décollez bientôt de Genève Cointrin, mais vous craignez :",
        section_1_text_1: "de ne pas trouver de place?",
        section_1_text_2: "de tourner en rond sur les parkings?",
        section_1_text_3: "de devoir marcher des kilomètres?",
        section_1_text_4: "de rater votre avion?",
        section_2_title_1: "Notre service de valet est",
        section_2_title_2: " là pour vous aider :",
        section_2_text_1: "Parking à proximité & au meilleur prix",
        section_2_text_2: "Place réservée à l’avance",
        section_2_text_3: "Service adapté à vos besoins",
        section_2_text_4: "Départ en voyage sans prise de tête",
        section_2_link: "Calculer mon tarif",
    },
    advantages_list: {
        title_1: "Sérénité",
        text_1: "Fini le stress de ne pas trouver de place raidement. En quelques minutes, nos voituriers prennent en charge votre véhicule et vous pouvez partir vous envoler en toute décontraction.",
        title_2: "Gain de temps",
        text_2: "Plus besoin d’arriver des heures avant votre vol pour vous assurer d’être à l’heure. Prendre l’avion devient plus rapide et beaucoup plus pratique.",
        title_3: "Sécurité & Assurance",
        text_3: "Votre voiture est assurée et nous veillons à la mettre en sécurité. Vous avez la garantie de la retrouver dans le même état.",
    },
    callUs: {
        title: "Vous avez des questions?",
        text_1: "Contacter un membre de notre équipe,",
        text_2: " disponible 7 jours sur 7.",
    },
    reviews_section: {
        title_1: "Votre tranquillité d’esprit",
        title_2: "n’a pas de prix.",
        from: "sur",
    },
    history_section: {
        title_1: "Park Fly Geneva ",
        title_2: "- Votre service de voituriers depuis 2019",
        text_1: "Nous prenons en charge tout votre stationnement, de votre arrivée à votre départ…",
        text_2: "…pour moins cher que les navettes et la concurrence…",
        text_3: "…en vous offrant le meilleur en matière d’assurance illimitée, de sécurité et de garantie",
    },
    pricing_section: {
        title: "L’équipe de valets Park Fly Geneva est composée de professionnels qui ont déjà facilité la vie de milliers de voyageurs.",
        text_1_1: "Nos priorités",
        text_1_2: " votre tranquillité, votre satisfaction et la sécurité de votre véhicule.",
        text_2: "Nous sommes à votre disposition tous les jours, presque 24h/24. Contactez-nous à la moindre question.",
        link_1: "Calculer mon tarif",
        link_2: "Nous contacter",
        section_title: "Nos tarifs",
        per_day: "par jour supplémentaire",
        days: "jours",
        day: "jour",
    },
    how_does_work: {
        title_1: "Comment fonctionne ",
        title_2: "Park Fly concrètement?",
        box_title_1: "Réservez en ligne en 5 minutes chrono",
        box_text_1: "Réservez en ligne en 5 minutes chrono",
        box_title_3: "Recevez des nouvelles de votre voiture",
        box_text_3:
            "Une fois votre véhicule garé, vous recevez des photos de votre véhicule stationné et son relevé kilométrique.",
        box_title_2: "le Jour J, rendez-vous Route de Pré-Bois 14 – 1216 Cointrin",
        box_text_2:
            "Déposez votre véhicule à notre base de réception, votre chauffeur vous conduit immédiatement à l’aéroport (2 min de trajet).",
        box_title_4: "Récupérez facilement le véhicule à votre retour",
        box_text_4:
            "Nous vous tenons au courant avant votre arrivée et votre chauffeur vient vous chercher avec votre voiture.",
    },
    trust_us_section: {
        title: "Confier votre voiture à un voiturier n’est pas un luxe, vous pouvez vous aussi le faire et simplifier votre voyage.",
        sub_title: "Vous avez des doutes, vous vous posez des questions ? ",
        text: "Nous avons conscience que ce n’est pas évident de prime abord de confier sa voiture à un inconnu, de ne pas connaître précisément l’emplacement véhicule, de se questionner sur la récupération de véhicule.",
        list_1: "3 ans d’activité à l’aéroport de Genève",
        list_2: "X avis Google",
        list_2_link: "Consulter les avis",
        list_3: "X professionnels qualifiés et à l’écoute qui sont à votre service",
    },
    parking_section: {
        title: "Vous voyagez en groupe ou avec une famille nombreuse ?",
        text_1: "Profitez du service navette 100% électrique de notre partenaire LOW COST PARK:",
        text_2: " laissez votre véhicule sur le P+R Vailly et votre chauffeur vous conduit à l’aéroport de Genève en 15 minutes, puis vous récupère à votre retour.",
        link: "Découvrir le service",
        slots: "places de parking disponibles",
    },
    faq_section: {
        callUs_banner_1: "Vous avez toujours des questions concernant  ",
        callUs_banner_2: "le processus de",
        callUs_banner_3: "réservation ou le partage de votre véhicule?",
        title_1_1: "Questions",
        title_1_2: "les plus fréquemment posées",
        title_1: "Comment procéder à une réservation ?",
        text_1: "De manière sécurisée, simple et rapide sur notre site internet. Réservez, puis effectuez votre paiement en ligne. Vous recevrez ensuite par courrier électronique la confirmation de votre réservation avec votre facture acquittée.",
        title_2: "Quels informations dois-je donner lors de ma réservation ?",
        text_2: "En plus des informations courantes, l’information importante à nous transmettre est votre numéro de vol retour. Ainsi nous serons en mesure de vérifier votre heure d’arrivée, aussi de vérifier si votre vol a de l’avance ou du retard sur l’heure d’arrivée prévue.",
        title_3: "Quels me sont demandés au moment de la prise en charge ?",
        text_3: "Les documents que nous demandons sont votre dossier de réservation (sous sa forme physique imprimée, ou bien sur smartphone) ainsi que la copie des papiers de votre véhicule (carte grise, assurance). Ces documents devront être placés dans la boîte à gants de votre véhicule.",
        title_4: "Combien de véhicules pouvez-vous prendre en charge ?",
        text_4: "Chaque réservation vaut pour la prise en charge d’un seul véhicule.",
        title_5: "À quel endroit est situé la prise en charge ?",
        text_5: "Pour chaque départ, le point de prise en charge est à l'adresse suivante :Route de Pé-Bois 14 - 1216 Cointrin, à Genève. Votre chauffeur vous y rencontre puis il vous conduit à l’aéroport avec votre véhicule pour vous y déposer. Le temps de trajet est seulement de 2 minutes.",
        title_6: "Quels documents me seront demandés ? ",
        text_6: "Il vous faudra fournir le dossier de réservation (sous sa forme physique imprimée, ou bien sur smartphone) ainsi que les papiers du véhicule (carte grise, assurance). Une photocopie, placée dans la boîte à gants, se montrera suffisante.",
        title_7: "Une personne tierce peut-elle déposer ou récupérer mon véhicule ?",
        text_7: "Oui, seulement dans le cas où vous nous en donnez le droit en informant notre bureau qui enregistrera alors le nom, prénom et N° de téléphone mobile de la personne visée.",
        title_8: "Procédez-vous à une vérification de l’état de mon véhicule ?",
        text_8: "Par précaution et pour assurer votre sérénité, un état de votre véhicule est effectué en votre présence au départ de même qu’à l’arrivée.",
        title_9: "Est-il nécessaire de vous contacter avant mon retour de voyage ?",
        text_9: "Oui, le jour de votre retour, dès la récupération de vos bagages, appelez votre chauffeur qui viendra vous chercher avec votre voiture directement à l’aéroport. La ligne directe de votre chauffeur vous sera communiquée la veille de votre départ par SMS.",
        title_10: "À quel endroit vais-je récupérer mon véhicule ?",
        text_10:
            "Vous retrouverez votre véhicule exactement au même endroit où le chauffeur vous a déposé le jour de votre départ.",
        title_11: "Que dois-je faire si mon vol est retardé ou modifié ?",
        text_11:
            "Grâce au numéro de vol que vous nous avez fourni avec votre réservation, nous sommes à même de surveiller les horaires des vols pour nous adapter. Votre chauffeur sera dans tous les cas présents à votre retour. En cas de changement de vol, il faut vous connecter à votre espace client en ligne sur notre site et modifier votre réservation en cours. Une mise à jour sera faite, avec un nouveau tarif qu’il vous sera demandé de régler.",
        // title_12: "Réponse dans la question précédente donc l’effacer",
        // text_12:
        //     "Il faudra vous connecter a vote espace en ligne sur notre site et modifier la réservation en cours, une mise à jour du tarif sera effectuée, et le montant supplémentaire vous sera demandé à régler directement. ",
    },
    footer: {
        hours_1: "Nos horaires",
        hours_2: "Du Lundi au Dimanche",
        address_1: "Notre adresse",
        address_2: "Route de Pré-Bois 14",
        address_3: "1216 Cointrin",
        copyrignht: "Droits d’auteur 2021 - pour tous les droits de Park & Fly Genève",
        tc_link: "Termes et conditions",
        payment_methods: "Mode de paiements approuvés",
        terms_title: "Termes et conditions",
        terms_text: {
            title_1: "1. Champ d’application",
            text_1_1:
                "Park&Fly Geneva est une marque gérée par la société PFG N° IDE : CHE-289.931.510",
            text_1_2:
                "Ces conditions générales de vente sont valables pour l’acceptation de véhicules des clients après réservation sur www.parkflygeneva.ch La cession temporaire du véhicule se fait au seul titre de stationnement pour la durée de location convenue, jusqu'à la restitution du véhicule de l'entreprise au client.",
            title_2: "2. Élaboration du contrat",
            text_2_1:
                "L'envoi d'une réservation via le site www.parkflygeneva.ch constitue une offre ferme en vue de la conclusion d'un contrat, qui est conclu effectivement lors de l'envoi d'un courrier électronique de confirmation. La réservation doit être effectuée au moins 12 heures avant l'heure souhaitée.  Passé ce délai, les réservations ne sont plus possibles.",
            text_2_2:
                "Si la réservation est effectuée par un tiers, alors il sera solidairement responsable, avec le client effectif, de toutes les obligations contractuelles. Toute réclamation concernant cette relation contractuelle ne peut être effectuée que par le client, et ne peut être transférée à un tiers qu'avec le consentement effectif de PFG.",
            title_3: "3. Volume des prestations",
            text_3_1:
                "3.1 Pour chaque réservation, PFG met au service du client un seul titre de stationnement. Le véhicule sera gardé dans un parking, dans un emplacement choisi par PFG, durant toute la durée du contrat. La prestation recouvre le transfert par PFG du véhicule vers le parking, ainsi que sa restitution. Le client assure que son véhicule est en état de rouler pour toute la durée du contrat. En cas de problème technique, il appartient au client de prendre les mesures appropriées à ses frais propres. Si les agents de PFG notent une fuite de fluide, alors PFG se réserve le droit d'effectuer les réparations adéquates aux frais du client pour résoudre tout problème. Le client reconnaît devoir le montant de toute réparation éventuelle à PFG ",
            text_3_2:
                "3.1 Les horaires d'ouverture sont fixés par PFG et annoncées sur le site internet www.parkflygeneva.ch  PFG se réserve le droit de modifier ces horaires de façon unilatérale à condition de l'annoncer de façon adéquate. À la réservation, le client s'engage à respecter ces horaires d'ouverture et à remettre et récupérer son véhicule aux horaires convenus.",
            title_4: "4. Prix et conditions de paiement",
            text_4_1:
                "4.1 Les tarifs en vigueur sont indiqués sur la page d'accueil de www.parkflygeneva.ch, Ils sont en outre rappelés et confirmés lors de la demande de réservation. Les tarifs en vigueur incluent la TVA de 7,7%",
            text_4_2:
                "4.2 Le client est tenu de régler la prestation choisie sur www.parkflygeneva.ch avant la livraison de son véhicule. Le paiement se fait au moment de la réservation, par le moyen d'une carte de crédit. Toute extension ou violation de la période contractuelle de stationnement seront facturées conformément aux tarifs en vigueur indiqués sur notre site internet. Le client reconnaît dans ce cas devoir à www.parkflygeneva.ch le cout mis à jour selon les nouvelles prestations.",
            text_4_3:
                "4.3 En accord avec les lois en vigueur, PFG dispose d'un droit de rétention et peut, en cas de défaut de paiement ou de problème avec la carte de crédit employée à la réservation, refuser la restitution du véhicule au Client. Le cas échéant, PFG peut engager une poursuite en réalisation du véhicule et des objets qu'il contient, en conformité avec la Loi Fédérale sur la poursuite pour dettes et faillites.",
            title_5:
                "5. Annulations, modifications de réservation et modifications ultérieures des prestations réservées",
            text_5_1:
                "PFG offre la possibilité d’annuler ou modifier toute réservation jusqu'à 1 heure avant son début effectif. L'opération peut s'effectuer uniquement sur l’espace client en ligne, aucune  demande de modification ou annulation peut être effectué par mail ou téléphone. ",
            text_5_2: "Les modifications peuvent être effectuées sans limite et sans frais.",
            text_5_3: "Les annulations sont soumises à des frais administratives forfaitaires :",
            text_5_3_1:
                "Annulation jusqu’à 24 heures avant le commencement de la réservation, 25.- CHF ",
            text_5_3_2:
                "Annulation moins de 24 heures avant le commencement de la réservation, 50.-CHF",
            text_5_3_3: "En cas de non-show, pas de remboursement.",
            title_6: "6. Responsabilité et exonération de responsabilité",
            text_6_1:
                "6.1 En tant que prestataire, PFG s'engage à tout mettre en œuvre pour exécuter son mandant avec toute la diligence requise pour satisfaire le client.",
            text_6_2:
                "6.2 En tant que prestataire PFG n'assume une responsabilité qu'en cas de faute grave et démontrée, que cela concerne des dommages matériels, corporels ou moraux causées au véhicule ou au client, ou bien en cas de restitution hors délais. Toute réclamation doit être notifiée à PFG avant que le véhicule ne quitte son point de restitution. Sous la responsabilité de PFG le véhicule est assuré uniquement contre la collision, ne sont pas pris en charge le vol, les inondations, le feu, la grêle. PFG n’est pas responsable des vols d'objets situés à l'intérieur des véhicules qui lui sont confiés (tels qu'appareil photos, téléphone portable, autoradios, bijoux et autres objets de valeur…) toutes dommage sur des jantes, pneus ou des éventuelles griffes sur la carrosserie ne seraient pas couverts - PFG ne saurait être tenu responsable de ce qui échappe à son contrôle raisonnable.",
            text_6_3:
                "6.3 PFG est habilité à restituer le véhicule à la personne en possession du courrier électronique de prise en charge du véhicule. Pour garantir la sécurité du véhicule, PFG ne peut le restituer que sur la présentation d'un moyen d'identification adéquat. PFG n'est pas responsable en cas de perte ou de vol du courrier électronique de prise en charge, notamment si celui-ci est présenté par un tiers qui l'utilise de manière frauduleuse pour obtenir la remise du véhicule.",
            text_6_4:
                "6.6 En cas de perte des clés du véhicule de la part de PFG nous engageons à mettre à disposition du client un véhicule pour qu'il puisse rentrer. Nous nous chargerons de récupérer chez le client la clé de rechange en temps voulu pour pouvoir procéder à la restitution du véhicule. PFG prendra en charge les frais de confection d'une clé de remplacement.",

            title_7: "7. Remise des clés du véhicule",
            text_7: "Le client s'engage contractuellement à remettre les clés de son véhicule lors de sa remise aux collaborateurs de PFG",
            title_8: "8. Autre",
            text_8_1:
                "8.1. Les données personnelles fournies par le client à PFG, notamment lors de la réservation, sont utilisées exclusivement pour assurer la mise en œuvre des services contractuels, et ne peuvent en aucun cas être révélées à un quelconque tiers.",
            text_8_2:
                "8.2. Tout changement des Termes et Conditions doit être notifié par forme écrite. Tout changement injuste ou inégal est proscrit.",
            text_8_3:
                "8.3. Les relations contractuelles entre le client et PFG sont soumises exclusivement au droit suisse. Le for judiciaire exclusif est Genève.",
        },
        privacy_title: "Privacy Policy",
        privacy_text:
            " These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and [business entity name] (“we,” “us” or “our”), concerning your access to and use of the [website name.com] website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibited from using the Site and you must discontinue use immediately These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and [business entity name] (“we,” “us” or “our”), concerning your access to and use of the [website name.com] website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibited from using the Site and you must discontinue use immediately",
    },
    client_booking: {
        days_to_stay: "Nombre de jours",
        price_label: "Prix total",
        next: "Prochaine étape",
        back: "Retour",
        phone_placeholder: "p.ex. 79 123 45 67",
        side_bar: {
            title: "Réservez votre chauffeur",
            step_1: "Date et heure",
            step_2: "Information",
            step_3: "Véhicule & paiement",
            result_title: "Résumé de votre réservation",
            duration: "Durée",
            hours: "jours",
            depart: "Départ",
            arival: "Retour",
            price: "Prix total",
            info: "Pour plus d'informations",
        },
        step_1: {
            title_drop_off: "Quand allez-vous déposer votre véhicule ?",
            return: "Quand reviendrez-vous ?",
            promocode: "Promo code:",
            dateFormat: "DD-MM-AA",
            mobile_title: "Montant de la réservation",
            for: "pour",
            days_stay: "jours de stationnement",
            depart: "Départ",
            arival: "Retour",
            date: "Date",
            hour: "Heure",
            select: "Sélectionner",
            error_dates: "Veuillez sélectionner correctement la date de départ et de retour!",
        },
        step_2: {
            login: "login",
            login_title: "Se connecter à mon compte",
            forgot_pass: "Most de passe oublié?",
            forgot_pass_link: "Réinitialiser",
            reg_title: "Nouveau client?",
            reg_title_main: "Créer votre compte en quelques clicks seulement",
            reg_text:
                "Créer votre compte en quelques clics seulement. Afin de garantir un service optimal, la création d’un compte est nécessaire. La création de votre compte ne prendra pas plus de 2 minutes. Vous pourrez ensuite poursuivre votre réservation.",
            reg_link: "Créer un compte",
            email: "Email",
            password: "Mot de passe",
            login_btn: "Se connecter",
            sec_title: "Créer un compte",
            reg_title_form: "Se connecter à mon compte",
            country: "Pays",
            first_name: "Nom",
            last_name: "Prénom",
            phone: "Mobile",
            street: "Adresse",
            street_number: "Numéro",
            post_code: "Code postal",
            city: "Ville",
            company: "Société 'optionel'",
        },
        step_3: {
            go_back: "Retourner",
            sec_title: "Edit",
            car_title: "Détails du véhicule",
            new_car: "Créer une nouvelle voiture",
            new_car_2: "Ou crée une nouvelle voiture.",
            select_car: "Sélectionnez parmi vos véhicules",
            flight_number: "Numéro du vol retour",
            brand: "Véhicule",
            model: "Modèle",
            plate: "Numéro de plaque",
            payment_title: "Détails de paiement",
            card_number: "Numéro de carte",
            card_date: "Date d’expiration",
            cvc: "Cvc",
            tt_text: "Je suis d’accord avec le",
            tt_link: "Termes et conditions",
            pay_btn: "Payer et finaliser ma réservation",
        },
        thankyou: {
            title_1: "Nous vous remercions et vous",
            title_2: "confirmons votre réservation.",
            text: "Les détails de votre réservation, ainsi que les instruction pour la remise des clés de votre véhicule vous sont envoyés directement par email. Toutes l’équipie de Park Fly Geneva vous souhaite d’avance de bonne vacance.",
            greetings: "Pour toutes questions, contactez-nous",
        },
    },

    client_profile: {
        nav_1: "Profil",
        nav_2: "Réservations",
        personal_information: "Informations personnelles",
        edit: "Éditer",
        full_name: "Nom et prénom",
        first_name: "Prénom",
        last_name: "Nom",
        company: "Société",
        email: "Adresse e-mail",
        phone: "Numéro de téléphone",
        country: "Pays",
        city: "Ville",
        address: "Adresse",
        street_number: "Numéro",
        post_code: "Code postal",
        brand: "Marque",
        model: "Modèle",
        plate: "Numéro de plaque",
        actions: "Actions",
        cars: "voitures",
        no_cars: "Pas de voitures à montrer !",
        add_car: "Ajouter un autre véhicule",
        change_pass: "Changer le mot de passe",
        edit_personal_title: "Édition Informations personnelles",
        update: "mettre à jour",
        cancel: "Annuler",
        аdd_reservation: "Nouvelle réservation",
        edit_car: {
            title: "Ajouter un autre véhicule",
            title_edit: "Modifier les détails du véhicule",
            brand: "Marque",
            model: "Modèle",
            plate: "Numéro de plaque",
            submit: "Ajouter",
            submit_edit: "Appliquer les modifications",
        },
        reservations: {
            id: "ID",
            arrival: "Date de votre Retour",
            departure: "Date de votre Départ",
            stay: "Durée",
            status: "Statut",
            photos: "Photos",
            tax: "Impôt",
            actions: "Actions",
            no_photos: "Pas de photos",
            no_results: "Pas encore de réservations !",
            when_departure: "Heure de prise en charge",
            when_arrival: "Heure de votre Retour",
            select_car: "Sélectionnez le véhicule",
            edit_title: "Modifier la réservation",
            edit: "Éditer",
            price_label: "Prix Total",
            price: "prix",
            for: "pour",
            days_stay: "jours de stationnement",
            add_car: "Ajouter un autre véhicule",
            sorry_to_go: "nous sommes désolés de vous voir partir",
            successfully_canceled: "Vous avez annulé votre réservation avec succès",
            continue: "Continuer",
            contact: "contactez-nous",
            any_issues: "en cas de problème",
            unsucess: "Annulation non réussie !",
            unsucess_message: "Impossible d'annuler votre réservation.",
            unsucess_decreese: "Vous ne pouvez pas diminuer la réservation !",
            successfully_updated: "Vous avez réussi à mettre à jour votre réservation",
            decreased: "Votre réservation a été diminuée de",
            days: "jours",
            refunded_amount: "CHF ont été remboursés sur votre carte de crédit.",
            cancel: "Annule",
            edit_2: "Modifier",
        },
    },
    client_auth: {
        login: "Login",
        email: "Adresse e-mail",
        password: "le mot de passe",
        forgot: "Mot de passe oublié?",
        forgot_title: "Mot de passe oublié",
        forgot_info:
            "Entrez l'adresse e-mail associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe",
        reset: "Réinitialiser le mot de passe",
        no_account: "Vous n'avez pas de compte ?",
        book_now: "Créer un compte ici",
        send: "Envoyer",
        reset: "Réinitialiser le mot de passe",
        new_pass: "Nouveau mot de passe",
        pass_reset_info:
            "*Votre nouveau mot de passe doit être différent des précédents mots de passe utilisés",
        confirm_pass: "Confirmez le mot de passe",
        update_pass: "Mettre à jour le mot de passe",
        unauthorized: "Courriel ou mot de passe erroné",
    },

    date_picker_range: {
        confirm: "Confirmé",
    },
    time_picker: {
        hours: "Heures",
        minutes: "Minutes",
        confirm: "Confirmé",
    },
    validations: {
        password_rule:
            "Doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.",
    },
    cansel_reservation: {
        title: "Annuler la réservation",
        info_text:
            "Êtes-vous sûr de vouloir supprimer votre réservation ? Vous ne pouvez pas annuler cette action.",
        delete: "Supprimer",
        cancel: "Annuler",
    },
    components: {
        table: {
            search: "Search...",
            result: "result",
            of: "of",
            per_page: "Rows per page",
        },
        delete_pop: {
            yes: "yes",
            no: "no",
        },
    },

    sidebar: {
        Statistics: "Dashboard",
        VacationsDays: "Vacations Calendar",
        Employees: "Employees",
        Clients: "Clients",
        Reservations: "Reservations",
        AllClients: "All Clients",
        Calendar: "Calendar",
        Parkings: "Parkings",
        Promocodes: "Promo Codes",
        Prices: "Prices",
        LogOut: "Log Out",
    },
    employees: {
        title: "Employees",
        inviteButton: "Invite New Employee",
        inviteForm: {
            title: "Invite a new employee",
            first_name: "first name",
            last_name: "last name",
            email: "email",
            assign_role: "Assign Role",
            manager: "Manager",
            valet: "Valet",
            cancel: "cancel",
            submit: "Submit",
        },
        delete: {
            question_1: "Are you sure you want to remove",
            question_2: "from your employees? ",
        },
        table: {
            id: "ID",
            first_name: "first name",
            last_name: "last name",
            email: "email",
            phone: "phone",
            adress: "adress",
            role: "role",
            reservations: "Month reservations",
            additional_hours: "additional hours",
            actions: "Actions",
            noResults: "No results yet !",
        },
        preview: {
            info_title: "Account information",
            adress_title: "Adress",
            name: "Full name",
            email: "email",
            insurance: "Insurance Number",
            phone: "Phone number",
            birghtday: "Birthday",
            reservations: "Reservations Completed",
            hours: "Additional Hours",
            city: "city",
            district: "district",
            zip: "zip",
            street: "street",
            roleName: "Role",
        },
        single: {
            title: "Account information",
            info_title: "Account information",
            adress_title: "Adress",
            first_name: "First Name",
            last_name: "Last Name",
            email: "email",
            insurance: "Insurance Number",
            password: "password",
            phone: "Phone number",
            birghtday: "Birthday",
            city: "city",
            district: "district",
            zip: "zip",
            street: "street",
            street_number: "street number",
            submit: "Confirm",
            roleName: "Role",
        },
    },
    statistics: {
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December",
        profit_title: "Total profit",
        reservations_title: "Total Reservations",
        statistics: "statistics",
        reservations_btn: "Reservations",
    },
    clients: {
        title: "Clients",
        add_new_client: "Add new client",
        add_new_reservation: "Add new reservation",
        delete: {
            question_1: "Are you sure you want to remove",
            question_2: "from your clients? ",
        },
        table: {
            id: "ID",
            first_name: "first name",
            last_name: "last name",
            country: "country",
            email: "email",
            phone: "phone",
            reservations: "reservations",
            amount: "amount",
            actions: "actions",
            noResults: "No results yet !",
        },
        preview: {
            main_title: "Clients",
            information: "information",
            reservations: "reservations",
            edit: "Edit Client",
            delete: "Delete Client",
            first_title: "Account information",
            second_title: "Adress",
            first_name: "first name",
            last_name: "last name",
            company: "company",
            email: "email",
            phone: "phone",
            country: "country",
            city: "city",
            district: "district",
            post_code: "zip",
            street: "street",
            street_number: "street number",
            brand: "brand",
            model: "model",
            plate: "Plate number",
            noResults: "No results yet !",
        },
        single: {
            title: "Edit Information",
            first_title: "Account information",
            second_title: "Adress",
            first_name: "first name",
            last_name: "last name",
            company: "company",
            email: "email",
            phone: "phone",
            country: "country",
            city: "city",
            district: "district",
            discount_title: "discount",
            discount: "discount",
            post_code: "zip",
            street: "street",
            street_number: "street number",
            reset: "Cancel",
            submit: "submit",
            vehicles: "vehicles",
            brand: "brand",
            model: "model",
            plate: "Plate number",
        },
    },
    reservations: {
        title: "Reservations",
        add_new_button: "Add new reservation",
        delete: {
            question_1: "Are you sure you want to cancel",
            question_2: "? ",
        },
        table: {
            id: "Id",
            from: "Reservation At",
            arrival_at: "Arrival At",
            departure_at: "Departure At",
            stay: "stay",
            status: "status",
            payment_status: "payment status",
            images: "Gallery",
            tax: "tax",
            price: "price",
            flight: "flight",
            register_number: "register number",
            unpaid_notification: "unpaid notification",
            send: "Send",
            notsend: "Not send",
            action: "Actions",
            noResults: "No results yet !",
            none: "none",
        },
        single: {
            edit_title: "Edit reservation",
            create_title: "Add new reservation",
            create_label: "Created",
            client: "Choose client",
            car: "Choose car",
            parking: "Parking",
            departure_at: "departure at",
            departure_time: "departure time",
            arrival_at: "arrival at",
            arrival_time: "arrival time",
            duration: "duration",
            note: "note",
            flight: "Flight number",
            promocode: "Promo code",
            departure_valet: "Departure Valet",
            arrival_valet: "Departure Valet",
            submit: "Confirm",
            clear: "clear",
        },
        preview: {
            title: "Reservation",
            arrived: "arrived",
            returned: "returned",
            stay: "to stay",
            parked: "Park on parking",
            note: "Note",
            pictures: "Pictures",
            upload: "Upload Image",
            departure_valet: "Arrival valet ",
            arrival_valet: "Departure Valet",
            edit: "Edit Information",
            complete: "Mark As Complete",
            delete: "Delete",
            unknown: "unknown",
            no_images: "No uploaded Images",
            submit: "submit",
            active: "Arrived",
            not_come: "No Show",
            not_done: "Not delivered",
            declined: "cancel",
            done: "Returned",
            status: "Status",
            payment_status: "Payment status",
            change_status: "change status",
            change_status_payment: "change payment status",
            assign: "assign",
            pending: "Pending",
            paid: "Paid"
        },
    },
    reservation_calendar: {
        title: "Reservations Calendar",
        add_new: "Add New Reservation",
        come: "Coming",
        active: "Arrived",
        returned: "returned",
        go: "going",
        not_done: "Not delivered",
        not_come: "no show",
        day: "Day",
        week: "Week",
        month: "Month",
        ending: "ending",
        incoming: "incoming",
    },
    parkings: {
        title: "Parkings",
        add_new_button: "Add new parking",
        table: {
            id: "ID",
            name: "name",
            city: "city",
            street: "street",
            number: "number",
            phone: "phone",
            lots: "lots",
            action: "actions",
            noResults: "No results yet !",
        },
        preview: {
            title: "Parking",
            sub_title: "Parking information",
            name: "Full name",
            address: "address",
            city: "city",
            street: "street",
            street_number: "street number",
            phone: "Phone number",
            lots: "lots",
            edit_btn: "Edit Information",
        },
        single: {
            edit_title: "Edit parking",
            create_title: "Add new parking",
            name: "Full name",
            address: "address",
            city: "city",
            street: "street",
            street_number: "number",
            phone: "Phone number",
            lots: "lots",
            submit_btn: "submit",
            clear_btn: "clear",
        },
    },
    promocodes: {
        title: "Promo codes",
        add_new_button: "Add new promo code",
        table: {
            id: "ID",
            code: "Code",
            type: "Type - Fixed/Percent",
            value: "Value",
            active: "Active",
            number_of_uses: "Number of uses",
            from_date: "Start date",
            to_date: "End date",
            action: "actions",
            noResults: "No results yet !",
        },
        preview: {
            title: "Promo code",
            sub_title: "Promo code information",
            code: "Code",
            type: "Type - Fixed/Percent",
            value: "Value",
            active: "Active",
            number_of_uses: "Number of uses",
            from_date: "Start date",
            to_date: "End date",
            edit_btn: "Edit Information",
        },
        single: {
            edit_title: "Edit promo code",
            create_title: "Add new promo code",
            code: "Code",
            type: "Type - Fixed/Percent",
            value: "Value",
            active: "Active",
            number_of_uses: "Number of uses",
            from_date: "Start date",
            to_date: "End date",
            submit_btn: "submit",
            clear_btn: "clear",
        },
    },
    vacations: {
        title: "Vacations Calendar",
        add_vacations_btn: "Add non working days",
        approve_vacations_btn: "Approve Vacations",
        legend: {
            aproved: "Approved paid VacationDays",
            waiting: "Waiting for approval",
            sickness: "Sickness leave",
        },
        vacations_form: {
            title: "Add non-working days",
            from_date: "From Date *",
            to_date: "To Date *",
            sick: "Sickness",
            vacation: "Vacation",
            cancel_btn: "Cancel",
            submit_btn: "Submit",
        },

        approve_list: {
            title: "Approve Vacations",
            employee: "Employee",
            reason: "Reason",
            from_date: "From date",
            to_date: "To date",
            actions: "Actions",
            aprove_btn: "Approve",
            disaprove_btn: "Disapprove",
            aprove_label: "Approved",
            disaprove_label: "Disapproved",
            noResults: "No vacations for approval !",
        },
    },
    prices: {
        title: "Prices",
        day: "Day",
        submit_btn: "Submit",
    },
};
