import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getVacationsCalendar, getEmployeesBirthdays } from "./_actions";

import moment from "moment-timezone";

const CalendarLogic = () => {
    const dispatch = useDispatch();
    const vacations = useSelector((state) => state.vacationDays.vacations);
    const selectedMonth = useSelector((state) => state.vacationDays.selectedMonth);

    const [vacationsList, setVacationsList] = useState([]);

    useEffect(() => {
        const currentDate = new Date();
        const date = "01-" + moment(currentDate).format("MM-YYYY");
        dispatch({
            type: "SET_MONTH_VACANCIES",
            payload: date,
        });
    }, []);

    const openSetFreeDays = () => {
        dispatch({
            type: "SHOW_ADD_FREEDAYS_POPUP",
            payload: true,
        });
    };
    const openListForApproval = () => {
        dispatch({
            type: "SHOW_FOR_APPROVAL_POPUP",
            payload: true,
        });
    };
    useEffect(() => {
        if (selectedMonth) {
            getVacationsCalendar(selectedMonth, dispatch);
            // getEmployeesBirthdays(selectedMonth, dispatch);
        }
    }, [selectedMonth]);

    useEffect(() => {
        if (vacations) {
            const formatedEvents = vacations.map((item) => {
                const { status, reason } = item;

                let color = "";
                if (reason === "vacation") {
                    color = "#3174ad";
                } else if (reason === "sick") {
                    color = "#f06a00";
                }
                if (status === "pending") {
                    color = "#ff882a";
                }
                return {
                    color: color,
                    id: item.id,
                    start: moment(item.from_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
                    end: moment(item.to_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
                    title: item.employee_first_name + " " + item.employee_last_name,
                    allDay: true,
                };
            });

            setVacationsList(formatedEvents);
        }
    }, [vacations]);

    const onNavigate = (date, view, type) => {
        if (type === "NEXT" || type === "PREV") {
            dispatch({
                type: "SET_MONTH_VACANCIES",
                payload: "01-" + moment(date).format("MM-YYYY"),
            });
        }
    };

    const eventStyleGetter = (event, start, end, isSelected) => {
        let style = {
            backgroundColor: event.color,
            borderRadius: "0px",
            border: "none",
            padding: "3px 10px",
            opacity: 0.8,
            color: "#fff",
        };
        return { style: style };
    };

    return { openSetFreeDays, openListForApproval, vacationsList, onNavigate, eventStyleGetter };
};

export default CalendarLogic;
