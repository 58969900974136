export const TRANSLATIONS_DE = {
    site: {
        login: "Login",
        book: "Mes réservations",
        logout: "Ausloggen",
        profile: "Profil & Reservierungen",
        video_top: "Lebe das Privileg",
        video_second: "Genfs №1 Parkservice",
        video_list_1: "Arbeitszeit 04:00 - 01:00 / 7 Tage die Woche",
        video_list_2: "Erstklassige Sicherheit",
        video_list_3: "Versicherte Fahrzeuge",
        video_list_4: "Ihre Sicherheit und die Sicherheit Ihres Fahrzeugs ist unsere Priorität",
        video_list_5: "Ihr Fahrzeug ist versichert",
        video_book: "Reservieren",
        video_whatch: "Schau Video",
        slots_title: "Parkplätze verfügbar",
        slots: "Plätze",
        step_title: "So einfach ist das",
        step_1_title: "Reservieren",
        step_1_text:
            "Buchen Sie online mit ein paar Klicks. Vielzahl von Zahlungsmethoden, schnell und einfach.",
        step_2_title: "Ankommen",
        step_2_text:
            "Überlassen Sie Ihr Auto einem unserer qualifizierten und speziell für Sie zuständigen Parkwächter. Treffen Sie uns am Tag Ihrer Ankunft an unserer Adresse in der Route de Pré-Bois 14 - 1216 Cointrin und der Diener wird Sie zum Flughafen fahren. Die Fahrtzeit beträgt nur 2 Minuten.",
        step_2_adress: "Route de Pré-Bois 14 - 1216 Cointrin",
        step_3_title: "Benachrichtigt werden",
        step_3_text:
            "Sobald Ihr Auto auf einem unserer Parkplätze abgestellt wird, erhalten Sie eine Benachrichtigung, sowie die Fotos Ihres Autos und seine Laufleistung.",
        step_4_title: "Abgeholt",
        step_4_text:
            "Am Tag Ihrer Rückkehr, sobald Ihr Gepäck abgeholt wurde, rufen Sie den Diener an, der Sie mit Ihrem Auto direkt am Flughafen abholt.",
        review_title: "Was unsere Kunden teilen",
        faq_title: "F.A.Q",
        faq: {
            title_1: "Wie können Sie von unserem Service profitieren?",
            text_1: "Buchen Sie auf unserer Website und bezahlen Sie online. Sie erhalten eine Bestätigung Ihrer Bestellung und Ihre Rechnung per E-Mail.",
            title_2: "Welche Unterlagen sind bei der Buchung erforderlich?",
            text_2: "Wir benötigen Ihre Ankunftsflugnummer",
            title_3: "Können Sie mit mehreren Fahrzeugen umgehen?",
            text_3: "Ja, Sie müssen nur eine Reservierung pro Fahrzeug vornehmen.",
            title_4: "Akzeptieren Sie jede Art von Fahrzeug?",
            text_4: "Ja, sofern für Ihr Fahrzeug keine Sondergenehmigung erforderlich ist und die Höhe 250 cm nicht überschreitet.",
            title_5: "Wo kann ich mein Fahrzeug abstellen?",
            text_5: "In unseren Büros an der Route de Pé-Bois 14 - 1216 Cointrin, dann wird der Fahrer Sie mit Ihrem Fahrzeug zum Flughafen fahren. Die Fahrtzeit beträgt nur 2 Minuten.",
            title_6: "Welche Unterlagen werde ich benötigen?",
            text_6: "Sie müssen die Reservierungsunterlagen (in gedruckter Form oder auf einem Smartphone) sowie die Fahrzeugpapiere (Fahrzeugschein, Versicherung) vorlegen. Eine Fotokopie, die im Handschuhfach aufbewahrt wird, ist ausreichend.",
            title_7: "Kann eine andere Person mein Fahrzeug abstellen oder abholen?",
            text_7: "Ja, Sie müssen nur unser Büro informieren, um den Namen und die Handynummer der Person zu registrieren, die Ihr Fahrzeug abgibt oder abholt.",
            title_8: "Könnte der Trailer durchgeführt werden?",
            text_8: "Damit Sie sich keine Sorgen machen müssen, wird in Ihrem Beisein eine Bestandsaufnahme der Einrichtungsgegenstände durchgeführt und Ihnen zur Genehmigung vorgelegt.",
            title_9: "Soll ich euch anrufen, damit ihr wisst, dass ich komme?",
            text_9: "Ja, am Tag Ihrer Rückkehr, sobald Sie Ihr Gepäck abgeholt haben, rufen Sie den Parkservice an und er wird Sie mit Ihrem Auto direkt am Flughafen abholen. Die Durchwahl des Parkwächters wird Ihnen am Vortag per SMS mitgeteilt.",
            title_10: "Wo findet die Inzahlungnahme Ihres Fahrzeugs statt?",
            text_10:
                "Genau an der Stelle, an der der Parkservice Sie abgesetzt hat, als Sie wegfuhren.",
            title_11: "Mein Flug hat Verspätung, was soll ich tun?",
            text_11:
                "Dank der Flugnummer, die Sie bei Ihrer Buchung angegeben haben, sind wir in der Lage, die Flugzeiten zu überwachen und eventuelle Verspätungen zu berücksichtigen. Ihr Parkservice wird in jedem Fall auf Sie warten",
            // title_12:
            //     "Ich muss meinen Aufenthalt verlängern oder mein Flug wurde gestrichen. Was kann ich tun?",
            // text_12:
            //     "Sie müssen sich mit Ihrem Online-Platz auf unserer Website verbinden und die aktuelle Reservierung ändern, der Preis wird aktualisiert und der zusätzliche Betrag wird direkt zur Zahlung aufgefordert. ",
        },
        copyright: "COPYRIGHT 2021 PARK AND FLY GENEVA ALLE RECHTE VORBEHALTEN",
        payment_methods: "Zahlungsmethoden",
        pp_link: "Datenschutz-Bestimmungen",
        tc_link: "Geschäftsbedingungen",
        footer_adress: "Route de Pré-Bois 14 – 1216 Cointrin",
        terms_title: "Geschäftsbedingungen",
        terms_text: {
            title_1: "1. Champ d’application",
            text_1_1:
                "Park&Fly Geneva est une marque gérée par la société PFG N° IDE : CHE-289.931.510",
            text_1_2:
                "Ces conditions générales de vente sont valables pour l’acceptation de véhicules des clients après  réservation sur www.parkflygeneva.ch La cession temporaire du véhicule se fait au seul titre de  stationnement pour la durée de location convenue, jusqu'à la restitution du véhicule de l'entreprise au client.",
            title_2: "2. Élaboration du contrat",
            text_2_1:
                "L'envoi d'une réservation via le site www.parkflygeneva.ch constitue une offre ferme en vue de la conclusion d'un contrat, qui est conclu effectivement lors de l'envoi d'un courrier électronique de confirmation. La réservation doit être effectuée au moins 12 heures avant l'heure souhaitée.  Passé ce délai, les réservations ne sont plus possibles.",
            text_2_2:
                "Si la réservation est effectuée par un tiers, alors il sera solidairement responsable, avec le client effectif, de toutes les obligations contractuelles. Toute réclamation concernant cette relation contractuelle ne peut être effectuée que par le client, et ne peut être transférée à un tiers qu'avec le consentement effectif de PFG.",
            title_3: "3. Volume des prestations",
            text_3_1:
                "3.1 Pour chaque réservation, PFG met au service du client un seul titre de stationnement. Le  véhicule sera gardé dans un parking, dans un emplacement choisi par PFG, durant toute la durée du contrat. La prestation recouvre le transfert par PFG du véhicule vers le parking, ainsi que sa restitution. Le client assure que son véhicule est en état de rouler pour toute la durée du contrat.  En cas de problème technique, il appartient au client de prendre les mesures appropriées à ses  frais propres. Si les agents de PFG notent une fuite de fluide, alors PFG se réserve le droit  d'effectuer les réparations adéquates aux frais du client pour résoudre tout problème. Le client  reconnaît devoir le montant de toute réparation éventuelle à PFG ",
            text_3_2:
                "3.1 Les horaires d'ouverture sont fixés par PFG et annoncées sur le site internet www.parkflygeneva.ch PFG se réserve le droit de modifier ces horaires de façon unilatérale à condition de l'annoncer de façon adéquate. À la réservation, le client s'engage à  respecter ces  horaires d'ouverture et à remettre et récupérer son véhicule aux horaires convenus.",
            title_4: "4. Prix et conditions de paiement",
            text_4_1:
                "4.1 Les tarifs en vigueur sont indiqués sur la page d'accueil de www.parkflygeneva.ch, Ils sont en outre rappelés et confirmés lors de la demande de réservation. Les tarifs en vigueur incluent la TVA de 7,7%",
            text_4_2:
                "4.2 Le client est tenu de régler la prestation choisie sur www.parkflygeneva.ch avant la livraison de son véhicule. Le paiement se fait au moment de la réservation, par le moyen d'une carte de crédit. Toute extension ou violation de la période contractuelle de stationnement seront facturées  conformément aux tarifs en vigueur indiqués sur notre site internet. Le client reconnaît dans ce  cas devoir à www.parkflygeneva.ch le cout mis à jour selon les nouvelles prestations.",
            text_4_3:
                "4.3 En accord avec les lois en vigueur, PFG dispose d'un droit de rétention et peut, en cas de défaut de paiement ou de problème avec la carte de crédit employée à la réservation, refuser la  restitution du véhicule au Client. Le cas échéant, PFG peut engager une poursuite en réalisation  du véhicule et des objets qu'il contient, en conformité avec la Loi Fédérale sur la poursuite pour dettes et faillites.",
            title_5:
                "5. Annulations, modifications de réservation et modifications ultérieures des prestations réservées",
            text_5_1:
                "PFG offre la possibilité d’annuler ou modifier toute réservation jusqu'à 24 heures avant son début  effectif. L'opération peut s'effectuer en envoyant un mail à info@parkflygeneva.ch tout en  indiquant clairement le numéro ID de la réservation. L’avoir restant sera conservé par PFG sans limite de temps pour une prochaine réservation.  offrela possibilité d’annuler oumodifier toute réservation jusqu'à 24 heuresavant son début effectif. L'opération peut s'effectueren envoyant un mail àinfo@parkflygeneva.chtout en indiquantclairement le numéro ID de la réservation. L’avoir restant sera conservé par PFGsans limite de temps pourune prochaine réservation.",
            text_5_2:
                "Il est impossible d'annuler sa réservation moins de 24 heures avant la date convenue.",
            title_6: "6. Responsabilité et exonération de responsabilité",
            text_6_1:
                "6.1 En tant que prestataire, PFG s'engage à tout mettre en œuvre pour exécuter son mandant avec toute la diligence requise pour satisfaire le client.",
            text_6_2:
                "6.2 En tant que prestataire PFG n'assume une responsabilité qu'en cas de faute grave et démontrée, que cela concerne des dommages matériels, corporels ou moraux causées au véhicule ou au client, ou bien en cas de restitution hors délais. Toute réclamation doit être notifiée à PFG avant que le véhicule ne quitte son point de restitution. Sous la responsabilité de PFG le  véhicule est assuré uniquement contre la collision, ne sont pas pris en charge le vol, les  inondations, le feu, la grêle. PFG n’est pas responsable des vols d'objets situés à l'intérieur des véhicules qui lui sont confiés (tels qu'appareil photos, téléphone portable, autoradios, bijoux et  autres objets de valeur…) toutes dommage sur des jantes, pneus ou des éventuelles griffes sur la carrosserie ne seraient pas couverts - PFG ne saurait être tenu responsable de ce qui échappe à son contrôle raisonnable.",
            text_6_3:
                "6.3 PFG est habilité à restituer le véhicule à la personne en possession du courrier électronique  de prise en charge du véhicule. Pour garantir la sécurité du véhicule, PFG ne peut le restituer  que sur la présentation d'un moyen d'identification adéquat. PFG n'est pas responsable en cas  de perte ou de vol du courrier électronique de prise en charge, notamment si celui-ci est présenté par un tiers qui l'utilise de manière frauduleuse pour obtenir la remise du véhicule.",
            text_6_4:
                "6.6 En cas de perte des clés du véhicule de la part de PFG nous engageons à mettre à disposition du client un véhicule pour qu'il puisse rentrer. Nous nous chargerons de récupérer  chez le client la clé de rechange en temps voulu pour pouvoir procéder à la restitution du véhicule. PFG prendra en charge les frais de confection d'une clé de remplacement.",
            text_6_5: "",
            title_7: "7. Remise des clés du véhicule",
            text_7: "Le client s'engage contractuellement à remettre les clés de son véhicule lors de sa remise aux collaborateurs dePFG",
            title_8: "8. Autre",
            text_8_1:
                "8.1. Les données personnelles fournies par le client à PFG, notamment lors de la réservation,sont utilisées exclusivement pour assurer la mise en œuvre des services contractuels, et ne peuvent en aucun cas être révélées à un quelconque tiers.",
            text_8_2:
                "8.2. Tout changement des Termes et Conditions doit être notifié par forme écrite. Tout changement injuste ou inégal est proscrit.",
            text_8_3:
                "8.3.Les relations contractuelles entre le client et PFG sont soumises exclusivement au droit suisse. Le for judiciaire exclusif est Genève.",
        },
        privacy_title: "Privacy Policy",
        privacy_text:
            " These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and [business entity name] (“we,” “us” or “our”), concerning your access to and use of the [website name.com] website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibited from using the Site and you must discontinue use immediately These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and [business entity name] (“we,” “us” or “our”), concerning your access to and use of the [website name.com] website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibited from using the Site and you must discontinue use immediately",
    },
    client_profile: {
        nav_1: "Profil",
        nav_2: "Reservierungen",
        personal_information: "Persönliche Daten",
        edit: "Editieren",
        full_name: "Vollständiger Name",
        first_name: "Vorname",
        last_name: "Nachname",
        company: "Name der Firma",
        email: "E-Mail-Addresse",
        phone: "Telefonnummer",
        country: "Land",
        city: "Stadt",
        address: "Adresse",
        street_number: "Nummer",
        post_code: "zip",
        brand: "Marke",
        model: "Modell",
        plate: "Kennzeichen",
        actions: "Aktionen",
        cars: "Autos",
        no_cars: "Keine Autos zu zeigen!",
        add_car: "Weiteres Fahrzeug hinzufügen",
        change_pass: "Passwort ändern",
        edit_personal_title: "Persönliche Daten bearbeiten",
        update: "aktualisieren",
        cancel: "Abbrechen",
        аdd_reservation: "Reservierung hinzufügen",
        edit_car: {
            title: "Weiteres Fahrzeug hinzufügen",
            title_edit: "Fahrzeugdetails bearbeiten",
            brand: "Marke",
            model: "Modell",
            plate: "Kennzeichen",
            submit: "Hinzufügen",
            submit_edit: "Änderungen übernehmen",
        },
        reservations: {
            id: "ID",
            arrival: "Ankunft",
            departure: "Abfahrt",
            stay: "Aufenthalt",
            status: "Status",
            photos: "Fotos",
            tax: "Steuer",
            actions: "Aktionen",
            no_photos: "Keine Fotos",
            no_results: "Noch keine Reservierungen!",
            when_departure: "Wann ist Ihr Termin?",
            when_arrival: "Wann kommt Ihre Rückkehr?",
            select_car: "Fahrzeug auswählen",
            edit_title: "Reservierung bearbeiten",
            edit: "Editieren",
            price_label: "Es wird kosten",
            price: "price",
            for: "zum",
            days_stay: "Tage bleiben",
            add_car: "Weiteres Fahrzeug hinzufügen",
            sorry_to_go: "Es tut uns leid, dass Sie gehen!",
            successfully_canceled: "Sie haben Ihre Reservierung erfolgreich storniert.",
            continue: "Fortsetzung",
            contact: "Kontaktieren Sie uns",
            any_issues: "wenn es irgendwelche Probleme gibt",
            unsucess: "Erfolglose Annullierung",
            unsucess_decreese: "Sie können die Reservierung nicht verringern !",
            successfully_updated: "Sie haben Ihre Reservierung erfolgreich aktualisiert",
            decreased: "Ihre Reservierung wurde um",
            days: "Tage reduziert",
            refunded_amount: "CHF wurden auf Ihre Kreditkarte zurückerstattet",
        },
    },
    client_auth: {
        login: "Login",
        email: "E-Mail-Addresse",
        password: "Passwort",
        forgot: "Passwort vergessen?",
        forgot_title: "Passwort vergessen",
        forgot_info:
            "Geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts",
        reset: "Passwort zurücksetzen",
        no_account: "Sie haben kein Konto?",
        book_now: "Jetzt buchen und erstellen",
        send: "Senden",
        reset: "Passwort zurücksetzen",
        new_pass: "Neues Kennwort",
        pass_reset_info:
            "*Ihr neues Passwort muss sich von den zuvor verwendeten Passwörtern unterscheiden",
        confirm_pass: "Kennwort bestätigen",
        update_pass: "Kennwort aktualisieren",
        unauthorized: "Falsche E-Mail oder falsches Passwort",
    },
    client_booking: {
        addPayment: "Zahlung erforderlich",
        main_title: "Buchen Sie Ihren Parkservice",
        nav_date: "Datum und Uhrzeit",
        nav_details: "Details",
        payment: "Zahlung",
        arrival: "Ankunft",
        departure: "Abfahrt",
        when_departure: "Wann ist Ihr Termin?",
        when_arrival: "Wann kommt Ihre Rückkehr?",
        price_label: "Es wird kosten",
        for: "zum",
        days_stay: "Tage bleiben",
        next: "Nächster Schritt",
        calulator_link: "Preisrechner",
        days_to_stay: "Tage zu bleiben",
        back: "Zurück",
        type_1_title: "Ich bin ein neuer Kunde",
        type_1_button: "Nächster Schritt",
        type_2_title: "Ich habe bereits einen Account",
        type_2_button: "Nächster Schritt",
        first_name: "Vorname",
        last_name: "Nachname",
        company: "Name der Firma",
        company_placeholder: "Optional",
        password: "Passwort",
        email: "E-Mail-Addresse",
        phone: "Telefonnummer",
        phone_placeholder: "Beispiel 79123456",
        country: "Land",
        district: "Kreis",
        city: "Stadt",
        street: "Adresse",
        street_number: "Nummer",
        post_code: "zip",
        brand: "Marke",
        model: "Modell",
        plate: "VKennzeichen",
        flight_number: "Rückflugnummer",
        forgot: "Passwort vergessen?",
        reset: "Zurücksetzen",
        login: "Login",
        agree: "Ich stimme dem zu",
        and: "und",
        pp_link: "Datenschutz-Bestimmungen",
        tc_link: "Geschäftsbedingungen",
        to_payment: "Weiter zur Zahlung",
        select_car: "Fahrzeug auswählen",
        add_car: "Weiteres Fahrzeug hinzufügen",
        promo_code: "Promo code",
        days: "Tage zu bleiben",
        total: "gesamt",
        subtotal: "Zwischensumme",
        days: "Tage zu bleiben",
        taxt: "Steuern und Gebühren",
        finish: "Finish",
        cvc: "cvc",
        exp_date: "Steuern und Gebühren",
        card: "Kartennummer",
        complete: "Buchung abgeschlossen",
        complete_congrads:
            "Herzliche Glückwünsche. Ihre Buchung ist erfolgreich. Sie können die Details in Ihrem Profil verfolgen und bearbeiten.",
        my_reservations: "Meine Reservierungen",
        non_working_days: "Arbeitsfreie Tage",
        close: "schließen",
    },
    date_picker_range: {
        confirm: "Bestätigen",
    },
    time_picker: {
        hours: "Stunden",
        minutes: "Minutes",
        confirm: "Bestätigen",
    },
    validations: {
        password_rule:
            "Muss 8 Zeichen enthalten, ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen",
    },
    cansel_reservation: {
        title: "Cancel reservation",
        info_text:
            "Are you sure that you want to delete your reservation? You can’t undo this action.",
        delete: "Delete",
        cancel: "Cancel",
    },
};
