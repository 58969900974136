import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";
import { Link } from "react-router-dom";

import CkeckIcon from "../../../../images/home/ckeck.png";
import paf_logo_l from "../../../../images/home/paf_logo_l.png";
import adv_1 from "../../../../images/home_icons/adv_1.svg";
import adv_2 from "../../../../images/home_icons/advc.svg";
import adv_3 from "../../../../images/home_icons/adv_3.svg";

import Logic from "./Logic";

import st from "./styles.module.scss";

const Index = () => {
    const { t } = useTranslation();

    const { showPriceCalculator } = Logic();

    return (
        <>
            <div className={st.advantages_box}>
                <h3 className={st.title}>
                    {t("advantages_box.title_1")}
                    <b> {t("advantages_box.title_2")}</b>
                </h3>

                <div className={st.box_wrapper}>
                    <div className={st.small_box}>
                        <h4 className={st.section_title}>{t("advantages_box.section_1_title")}</h4>

                        <div className={st.content}>
                            <p>{t("advantages_box.section_1_text_1")}</p>
                            <p>{t("advantages_box.section_1_text_2")}</p>
                            <p>{t("advantages_box.section_1_text_3")}</p>
                            <p>{t("advantages_box.section_1_text_4")}</p>
                        </div>
                    </div>
                    <div className={st.big_box}>
                        <h4 className={st.section_title}>
                            {t("advantages_box.section_2_title_1")}
                            <br /> {t("advantages_box.section_2_title_2")}
                            <img src={paf_logo_l} className={st.logo} alt='' />
                        </h4>
                        <div className={st.content}>
                            <p>
                                <div className={st.check_icon}>
                                    <img src={CkeckIcon} alt='' />
                                </div>
                                {t("advantages_box.section_2_text_1")}
                            </p>
                            <p>
                                <div className={st.check_icon}>
                                    <img src={CkeckIcon} alt='' />
                                </div>
                                {t("advantages_box.section_2_text_2")}
                            </p>
                            <p>
                                <div className={st.check_icon}>
                                    <img src={CkeckIcon} alt='' />
                                </div>
                                {t("advantages_box.section_2_text_3")}
                            </p>
                            <p>
                                <div className={st.check_icon}>
                                    <img src={CkeckIcon} alt='' />
                                </div>
                                {t("advantages_box.section_2_text_4")}
                            </p>
                            <div className={st.link_wrapper}>
                                <Link
                                    className={st.link}
                                    to='/'
                                    // onClick={() => {
                                    //     showPriceCalculator(true);
                                    // }}
                                >
                                    {t("advantages_box.section_2_link")}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={st.advantages_list}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className={st.list_wrapper}>
                                <div className={st.single_list}>
                                    <div className={st.section_tilte}>
                                        <div className={st.name}>
                                            <p className={st.number}>01</p>
                                            <h5>{t("advantages_list.title_1")}</h5>
                                        </div>
                                        <div className={st.circle}>
                                            <img src={adv_1} alt='' />
                                        </div>
                                    </div>
                                    <p className={st.text}>{t("advantages_list.text_1")}</p>
                                </div>
                                <div className={st.single_list}>
                                    <div className={st.section_tilte}>
                                        <div className={st.name}>
                                            <p className={st.number}>02</p>
                                            <h5>{t("advantages_list.title_2")}</h5>
                                        </div>
                                        <div className={st.circle}>
                                            <img src={adv_2} alt='' />
                                        </div>
                                    </div>
                                    <p className={st.text}>{t("advantages_list.text_2")}</p>
                                </div>
                                <div className={st.single_list}>
                                    <div className={st.section_tilte}>
                                        <div className={st.name}>
                                            <p className={st.number}>03</p>
                                            <h5>{t("advantages_list.title_3")}</h5>
                                        </div>
                                        <div className={st.circle}>
                                            <img src={adv_3} alt='' />
                                        </div>
                                    </div>
                                    <p className={st.text}>{t("advantages_list.text_3")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
