// rangeDatePicker

const initialState = {
    departure_at: "",
    arrival_at: "",
    show: "",
    expectVal: false,
    departure_at_fixed: false,
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SET_RANGE_DATE_PICKER":
            const { name, value } = payload;
            return {
                ...state,
                [name]: value,
            };
        default:
            return state;
    }
};

export default reducer;
