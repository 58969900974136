export const setMessage = (result) => (dispatch) => {
    const { status, message, data: resultData = {} } = result || {};

    const { error: shownError = "" } = resultData.data || {};
    if (!shownError) {
        if (status === 200) {
            dispatch({
                type: "SET_ALERT_MESSAGE",
                payload: {
                    message: "SUCCESS !",
                    success: true,
                },
            });
        }
        if (status === 201) {
            dispatch({
                type: "SET_ALERT_MESSAGE",
                payload: {
                    message: "Created Successfully !",
                    success: true,
                },
            });
        }
        if (status === 204) {
            dispatch({
                type: "SET_ALERT_MESSAGE",
                payload: {
                    message: "Updated Successfully !",
                    success: true,
                },
            });
        }
    }

    if (status === 403) {
        dispatch({
            type: "SET_ALERT_MESSAGE",
            payload: {
                message: "This action is unauthorized !",
                success: false,
            },
        });
    }
    if (message) {
        dispatch({
            type: "SET_ALERT_MESSAGE",
            payload: {
                message: "This Action is Forbidden !",
                success: false,
            },
        });
    }
    if (status === 500) {
        dispatch({
            type: "SET_ALERT_MESSAGE",
            payload: {
                message: "INTERNAL SERVER ERROR !",
                success: false,
            },
        });
    }
    setTimeout(() => {
        dispatch({ type: "REMOVE_ALERT_MESSAGE", payload: "" });
    }, 2000);
};
