import axios from "../../../configAxiosPublic";
import { setMessage } from "../../../components/AlertMessage/_actions.js";

export const getProfile = (dispatch) => {
    return axios
        .get("profile")
        .then((result) => {
            const { status } = result;
            if (status === 200) {
                dispatch({
                    type: "SET_CLIENT_PROFILE",
                    payload: result.data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const getInvoice = (id, dispatch) => {
    return axios
        .get("reservation-invoice/" + id, {
            responseType: "blob", // important
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "invoice" + id + ".pdf");
            document.body.appendChild(link);
            link.click();
        });
};

export const getVehicles = (dispatch) => {
    return axios
        .get("vehicles")
        .then((result) => {
            const { status } = result;
            if (status === 200) {
                dispatch({
                    type: "SET_CLIENT_VEHICLES",
                    payload: result.data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const getReservations = (dispatch) => {
    return axios
        .get("reservation")
        .then((result) => {
            const { status } = result;
            if (status === 200) {
                dispatch({
                    type: "SET_CLIENT_RESERVATIONS",
                    payload: result.data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const updateProfile = (data, dispatch) => {
    return axios
        .put("profile", data)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const cancelReservation = (data, dispatch) => {
    return axios
        .put("reservation-cancel/" + data.id)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const editCar = (data, dispatch) => {
    const { id } = data;
    return axios
        .put("vehicles/" + id, data)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const editReservation = (data, dispatch) => {
    const { id } = data;
    return axios
        .put("reservation/" + id, data)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const createCar = (data, dispatch) => {
    return axios
        .post("vehicles", data)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.resonse));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const changePassword = (data, dispatch) => {
    return axios
        .put("profile/password", data)
        .then((result) => {
            const { status } = result;
            if (status === 204) {
                dispatch(setMessage(result));
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
export const calcPrice = (data, dispatch) => {
    const { arrival_at, departure_at } = data;
    return axios
        .get(
            "calc-price?departure_at=" +
                departure_at +
                ":00" +
                "&arrival_at=" +
                arrival_at +
                ":00&edit=true"
        )
        .then((result) => {
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
// dispatch({
//     type: "EXIT_PROFILE_SETUP",
//     payload: result.data.data,
// });
