import i18n from "../../../../../languages/i18nextConf";

export const headerResultLabel = false;
export const columnHeaders = () => {
    return [
        {
            name: "id",
            label: i18n.t("reservations.table.id"),
            sort: true,
            status: "asc",
        },
        {
            name: "from",
            label: i18n.t("reservations.table.from"),
            sort: false,
        },
        {
            name: "departure_at",
            label: i18n.t("reservations.table.departure_at"),
            sort: false,
        },
        {
            name: "arrival_at",
            label: i18n.t("reservations.table.arrival_at"),
            sort: false,
        },

        {
            name: "days",
            label: i18n.t("reservations.table.stay"),
            sort: true,
        },
        {
            name: "status",
            label: i18n.t("reservations.table.status"),
            sort: true,
        },
        {
            name: "payment_status",
            label: i18n.t("reservations.table.payment_status"),
            sort: true,
        },
        {
            name: "vehicle_photos",
            label: i18n.t("reservations.table.images"),
            sort: false,
        },
        {
            name: "tax",
            label: i18n.t("reservations.table.tax"),
            sort: true,
        },
        {
            name: "order_price",
            label: i18n.t("reservations.table.price"),
            sort: true,
        },
        {
            name: "flight_number",
            label: i18n.t("reservations.table.flight"),
            sort: false,
        },

        {
            name: "register_number",
            label: i18n.t("reservations.table.register_number"),
            sort: false,
        },
        {
            name: "unpaid_notification",
            label: i18n.t("reservations.table.unpaid_notification"),
            sort: false,
        },
        {
            name: "action",
            label: i18n.t("reservations.table.action"),
            sort: false,
        },
    ];
};
export const tableFilters = {
    // first_name: {
    //     label: "Demo",
    //     filterName: "Demo",
    //     data: [
    //         {
    //             id: 1,
    //             name: "Ivan",
    //         },
    //         {
    //             id: 2,
    //             name: "Dragan",
    //         },
    //         {
    //             id: 3,
    //             name: "Petkan",
    //         },
    //     ],
    // },
};
