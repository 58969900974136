import axios from "../../../configAxiosPublic";
import { setMessage } from "../../../components/AlertMessage/_actions.js";
import moment from "moment";

export const getReviews = (dispatch) => {
    return axios
        .get("reviews")
        .then((result) => {
            const { status, data } = result;
            if (status === 200) {
                dispatch({
                    type: "SET_SITE_REVIEWS",
                    payload: data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};

export const getParkingSlots = (dispatch) => {
    const departure_at = moment().format("YYYY-MM-DD HH:mm:ss");
    const arrival_at = moment().add("days", 5).format("YYYY-MM-DD HH:mm:ss");

    return axios
        .get("/parking-places?departure_at=" + departure_at + "&arrival_at=" + arrival_at)
        .then((result) => {
            const { status, data } = result;
            if (status === 200) {
                dispatch({
                    type: "SET_PARKING_LOTS",
                    payload: data.data,
                });
            }
            return result;
        })
        .catch((error) => {
            if (error.message) {
                dispatch(setMessage({ message: error.message }));
            } else {
                dispatch(setMessage(error.response));
            }
            if (error.response) {
                return error.response.data;
            }
        });
};
