const CustomTooltip = ({ active, payload, label, month, year }) => {
    if (active && payload && payload.length) {
        return (
            <div className='custom-tooltip'>
                <p className='label'>
                    Date:{" "}
                    <strong>
                        {label}.{month + 1}.{year}
                    </strong>
                    <br />
                    {payload[0].payload.name}
                </p>
                <p className='label'>
                    {payload[0].name}: <strong>{payload[0].value}</strong> CHF
                </p>
            </div>
        );
    }
    return null;
};

export default CustomTooltip;
