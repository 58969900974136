import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import CalendarToolbarLogic from "./CalendarToolbarLogic";

const CalendarToolbar = ({ label, onNavigate }) => {
    const { t } = useTranslation();
    const {} = CalendarToolbarLogic();

    return (
        <>
            <div className='calendar-toolbar'>
                <div className='row align-items-center'>
                    <div className='col-xl-4 text-center col-md-4'>
                        <ul className='legend'>
                            <li>
                                <span className='marker' style={{ background: "#3174ad" }}></span>
                                {t("vacations.legend.aproved")}
                            </li>
                            <li>
                                <span className='marker' style={{ background: "#ff882a" }}></span>
                                {t("vacations.legend.waiting")}
                            </li>
                            <li>
                                <span className='marker' style={{ background: "#f06a00" }}></span>
                                {t("vacations.legend.sickness")}
                            </li>
                        </ul>
                    </div>
                    <div className='col-xl-4 text-center col-md-4'>
                        <div className='calendar-nav-wrapper'>
                            <div
                                className='toolbar-navigation-button'
                                type='button'
                                onClick={() => onNavigate("PREV")}
                            >
                                <i className='fas fa-caret-left'></i>
                            </div>
                            <div className='current-month'>{label}</div>

                            {/* <span
                            className="today-label"
                            onClick={() => this.props.onNavigate('TODAY')}
                            >
                            today
                        </span> */}
                            <div
                                className='toolbar-navigation-button'
                                type='button'
                                onClick={() => onNavigate("NEXT")}
                            >
                                <i className='fas fa-caret-right'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CalendarToolbar;
