import Home from "../pages/Public/Home/Home";
import ClientBooking from "../pages/Public/ClientBooking";
import LogIn from "../pages/Public/Login/Login";
import ForgotPassword from "../pages/Public/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/Public/ResetPassword/ResetPassword";
import CreateAccount from "../pages/Public/CreateAccount/CreateAccount";
import ProfileSetup from "../pages/Public/ProfileSetup/ProfileSetup";
import ThankYou from "../pages/Public/ThankYou";

const PublicRoutes = [
    {
        component: Home,
        path: "/",
        exact: true,
    },
    {
        component: ClientBooking,
        path: "/reservation",
        exact: true,
    },
    {
        component: ThankYou,
        path: "/merci-pour-votre-reservation",
        exact: true,
    },
    {
        component: ThankYou,
        path: "/booking-success",
        exact: true,
    },
    {
        component: Home,
        path: "/reset-password",
        exact: true,
    },
    {
        component: LogIn,
        path: "/login",
        exact: true,
    },
    {
        component: ForgotPassword,
        path: "/forgot-password",
        exact: false,
    },
    {
        component: ResetPassword,
        path: "/admin/reset-password",
        exact: false,
    },
    {
        component: CreateAccount,
        path: "/create-account",
        exact: false,
    },
    {
        component: ProfileSetup,
        path: "/profile-setup",
        exact: false,
    },
];

export default PublicRoutes;
