import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useSelector } from "react-redux";

import { Formik, Field, Form } from "formik";

import { Select } from "formik-material-ui";
import { MenuItem, FormControl, InputLabel } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";

import InputAdornment from "@material-ui/core/InputAdornment";
import { DatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/en-gb";

import ButtonLoader from "../../../../components/ButtonLoader/ButtonLoader";

import ClosePopup from "../../../../images/icons/close-popup-icon.svg";
import addFreeDaysShema from "../../../../_validations/addFreeDaysShema";
import AddFreeDaysLogic from "./AddFreeDaysLogic";

import st from "./styles.module.scss";

const AddFreeDays = () => {
    const { t } = useTranslation();
    const show = useSelector((state) => state.employeesFreeDays.showAddFreeDays);
    // const show = true;

    const { state, onChange, onSubmit, openSetFreeDays, loading } = AddFreeDaysLogic();

    return (
        <>
            <div className={"pop " + (show ? "show" : "")}>
                <div className={st.inner + " inner"}>
                    <img
                        className={st.close}
                        src={ClosePopup}
                        onClick={() => openSetFreeDays(false)}
                    />
                    <h3> {t("vacations.vacations_form.title")} </h3>
                    <div className='form-wrapper'>
                        <Formik
                            enableReinitialize
                            initialValues={state}
                            validationSchema={addFreeDaysShema}
                            onSubmit={onSubmit}
                        >
                            {({ errors, touched, isValidating, isValid, dirty }) => {
                                return (
                                    <Form>
                                        <MuiPickersUtilsProvider
                                            libInstance={moment}
                                            utils={MomentUtils}
                                            locale='en-gb'
                                        >
                                            <div className='row pb-10'>
                                                <div className='col-md-6'>
                                                    <Field
                                                        autoOk={true}
                                                        showTodayButton={true}
                                                        format='DD.MM.YYYY'
                                                        name='from_date'
                                                        type='text'
                                                        label={t(
                                                            "vacations.vacations_form.from_date"
                                                        )}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position='start'>
                                                                    <i className='far fa-calendar-alt'></i>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        className='full-width date-input mb-3'
                                                        InputLabelProps={{ shrink: true }}
                                                        component={DatePicker}
                                                        inputVariant='outlined'
                                                    ></Field>
                                                </div>
                                                <div className='col-md-6'>
                                                    <Field
                                                        autoOk={true}
                                                        showTodayButton={true}
                                                        format='DD.MM.YYYY'
                                                        name='to_date'
                                                        type='text'
                                                        label={t(
                                                            "vacations.vacations_form.to_date"
                                                        )}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position='start'>
                                                                    <i className='far fa-calendar-alt'></i>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        className='full-width date-input mb-3'
                                                        InputLabelProps={{ shrink: true }}
                                                        component={DatePicker}
                                                        inputVariant='outlined'
                                                    ></Field>
                                                </div>
                                            </div>
                                        </MuiPickersUtilsProvider>

                                        <div className='row pb-10'>
                                            <div className='col-md-12'>
                                                <h4>Type</h4>
                                                <div className='radio_box' role='radio-group'>
                                                    <label>
                                                        <Field
                                                            type='radio'
                                                            name='reason'
                                                            value='sick'
                                                        />
                                                        <div className='input_holder'>
                                                            {t("vacations.vacations_form.sick")}

                                                            <span></span>
                                                        </div>
                                                    </label>
                                                    <label>
                                                        <Field
                                                            type='radio'
                                                            name='reason'
                                                            value='vacation'
                                                        />
                                                        <div className='input_holder'>
                                                            {t("vacations.vacations_form.vacation")}
                                                            <span></span>
                                                        </div>
                                                    </label>
                                                    {errors.role_id && touched.role_id && (
                                                        <div className='custom-error'>
                                                            {errors.role_id}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='text-end'>
                                            <button
                                                type='button'
                                                className='btn btn-cancel-plain'
                                                onClick={() => openSetFreeDays(false)}
                                            >
                                                {t("vacations.vacations_form.cancel_btn")}
                                            </button>
                                            <button
                                                disabled={loading || !(isValid && dirty)}
                                                type='submit'
                                                className='btn btn-submit'
                                            >
                                                {t("vacations.vacations_form.submit_btn")}
                                            </button>

                                            <ButtonLoader show={loading} />
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddFreeDays;
