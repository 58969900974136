import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPricePerDay } from "../../ClientBooking/_actions";

import dispatcher from "../_dispatcher";
const Logic = () => {
    const dispatch = useDispatch();
    const [days, setDays] = useState(0);
    const [value, setValue] = useState(1);

    const { resetBookingForm } = dispatcher();

    const setDaysFunc = (data) => {
        if (data.length === 0) {
            setValue(0);
            setDays(data);
        } else if (data.length < 4) {
            setDays(data);
        }
    };
    useEffect(() => {
        if (days) {
            getPricePerDay(days, dispatch).then((response = {}) => {
                if (response.status === 200) {
                    setValue(response.data.data.price);
                }
            });
        }
    }, [days]);

    return { value, resetBookingForm, days, setDaysFunc };
};

export default Logic;
