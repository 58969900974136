export const formatDates = (data) => {
    const { arrival_at, arrival_at_time, departure_at, departure_at_time } = data;

    const moderated = {
        note: "",
        departure_at: departure_at?.format("YYYY-MM-DD") + " " + departure_at_time,
        arrival_at: arrival_at?.format("YYYY-MM-DD") + " " + arrival_at_time,
    };

    return moderated;
};
