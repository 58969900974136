import React from "react";

import { Element } from "react-scroll";

import st from "./styles.module.scss";

import Hero from "./Hero";
import GoogleReviewBanner from "./GoogleReviewBanner";
import Advantages from "./Advantages";
import CallUsBaner from "./CallUsBaner";
import GoogleReviews from "./GoogleReviews";
import HistorySection from "./HistorySection";
import PricingSection from "./PricingSection";
import HowDoesWork from "./HowDoesWork";
import TrustUs from "./TrustUs";
import ParkingSlots from "./ParkingSlots";
import Faq from "./Faq";

import HomeLogic from "./HomeLogic";

import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
const Home = () => {
    const {} = HomeLogic();
    return (
        <>
            <div className={st.home_page}>
                <Header />
                <Hero />
                <GoogleReviewBanner />
                <Element name='about'>
                    <Advantages />
                </Element>
                <CallUsBaner />
                <GoogleReviews />
                <HistorySection />
                <Element name='pricing'>
                    <PricingSection />
                </Element>
                <HowDoesWork />
                <Element name='services'>
                    <TrustUs />
                </Element>
                <ParkingSlots />
                <Faq />

                {/* <Booking /> */}

                <Element name='footer'>
                    <Footer />
                </Element>
            </div>
        </>
    );
};

export default Home;
