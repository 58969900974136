import { useState } from "react";
import { useDispatch } from "react-redux";
import i18n from "../../languages/i18nextConf";
const SideBar = () => {
    const dispatch = useDispatch();

    const [showEmpoyees, toggleEmployees] = useState(true);
    const [showNav, toggleShowNav] = useState(false);
    const [showClients, toggleShowClients] = useState(true);

    const [language, setLanguage] = useState("fr");

    const changeLanguage = (e) => {
        e.preventDefault();
        setLanguage(e.target.value);
        i18n.changeLanguage(e.target.value);
    };

    const tempExit = () => {
        dispatch({
            type: "LOGOUT",
            payload: "",
        });
    };
    return {
        language,
        changeLanguage,
        toggleEmployees,
        showEmpoyees,
        showClients,
        toggleShowClients,
        tempExit,
        showNav,
        toggleShowNav,
    };
};

export default SideBar;
