import React, { useRef, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import "../../languages/i18nextConf";

import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel, Navigation } from "swiper";
import "swiper/swiper.min.css";

import ClosePopup from "../../images/icons/close-popup-icon.svg";

import moment from "moment";

SwiperCore.use([Mousewheel, Navigation]);

const TimePicker = ({}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [minutes, setMinutes] = useState("");
    const [hours, setHours] = useState("");
    const [swiperHours, setSwiperHours] = useState(null);
    const [swiperMinutes, setSwiperMinutes] = useState(null);

    const departure_at = useSelector((state) => state.rangeDatePicker.departure_at);
    const arrival_at = useSelector((state) => state.rangeDatePicker.arrival_at);

    const type = useSelector((state) => state.timePicker.type);
    // departure_at_time, arrival_at_time

    const nightBlock = ["24/12", "31/12"];
    const daysBlock = ["25/12", "01/01"];
    const blockHoursNight = [18, 19, 20, 21, 22, 23];
    const blockHoursDay = [4, 5, 6, 7, 8];
    const departureDate = moment(departure_at, "DD/MM/YYYY").format("DD/MM");
    const arrivalDate = moment(arrival_at, "DD/MM/YYYY").format("DD/MM");

    const createHours = () => {
        let times = [];
        for (var i = 4; i < 24; i++) {
            if (type === "departure_at_time") {
                if (nightBlock.includes(departureDate)) {
                    if (!blockHoursNight.includes(i)) {
                        times.push(("0" + i).slice(-2));
                    }
                } else if (daysBlock.includes(departureDate)) {
                    if (!blockHoursDay.includes(i)) {
                        times.push(("0" + i).slice(-2));
                    }
                } else {
                    times.push(("0" + i).slice(-2));
                }
            } else if (type === "arrival_at_time") {
                if (nightBlock.includes(arrivalDate)) {
                    if (!blockHoursNight.includes(i)) {
                        times.push(("0" + i).slice(-2));
                    }
                } else if (daysBlock.includes(arrivalDate)) {
                    if (!blockHoursDay.includes(i)) {
                        times.push(("0" + i).slice(-2));
                    }
                } else {
                    times.push(("0" + i).slice(-2));
                }
            } else {
                times.push(("0" + i).slice(-2));
            }
        }
        return times;
    };
    const createMinutes = () => {
        let times = [];
        for (var i = 0; i < 60; i += 10) {
            times.push(("0" + i).slice(-2));
        }
        return times;
    };

    const show = useSelector((state) => state.timePicker.show);

    const chosenTime = useSelector((state) => state.timePicker.chosenTime);

    const swipteToHour = (hour) => {
        if (type === "departure_at_time") {
            if (nightBlock.includes(departureDate)) {
                swiperHours.slideToLoop(Number(hour - blockHoursNight.length - 4));
            } else if (daysBlock.includes(departureDate)) {
                swiperHours.slideToLoop(Number(hour - blockHoursDay.length - 4));
            } else {
                swiperHours.slideToLoop(Number(hour - 4));
            }
        } else if (type === "arrival_at_time") {
            if (nightBlock.includes(arrivalDate)) {
                swiperHours.slideToLoop(Number(hour - blockHoursNight.length - 4));
            } else if (daysBlock.includes(arrivalDate)) {
                swiperHours.slideToLoop(Number(hour - blockHoursDay.length - 4));
            } else {
                swiperHours.slideToLoop(Number(hour - 4));
            }
        } else {
            swiperHours.slideToLoop(Number(hour - 4));
        }
    };

    useEffect(() => {
        if (swiperHours) {
            const hours = moment().hours();
            if (hours <= 4 && hours > 1) {
                setHours(4);
                swiperHours.slideToLoop(0);
            } else {
                setHours(hours);
                swipteToHour(hours);
            }
        }
        if (swiperMinutes) {
            const min = moment().minutes();
            const minZero = ("0" + min).slice(-2);

            swiperMinutes.slideToLoop(Number(minZero.toString()[0]));
            setMinutes(minZero.toString()[0]);
        }
    }, [swiperHours, swiperMinutes]);

    useEffect(() => {
        if (chosenTime) {
            const hours = moment(chosenTime, "HH:mm").hours();
            const minutes = moment(chosenTime, "HH:mm").minutes();
            const minZero = ("0" + minutes).slice(-2);
            if (swiperHours) {
                setHours(hours);
                swipteToHour(hours);
            }
            if (swiperMinutes) {
                swiperMinutes.slideToLoop(Number(minZero.toString()[0]));
                setMinutes(minZero.toString()[0]);
            }
        }
    }, [chosenTime]);

    const setDispatch = (name, value) => {
        dispatch({
            type: "SET_TIME_PICKER",
            payload: { name, value },
        });
    };

    const setSelectedDates = () => {
        setDispatch([type], ("0" + hours).slice(-2) + ":" + (minutes + "0"));
        setDispatch("chosenTime", "");
        setDispatch("type", "");
        setDispatch("show", false);
    };

    const [pickers, setPickers] = useState({
        hoursPicker: false,
        minutesPicker: false,
    });

    const togglePickers = (hoursPicker, minutesPicker) => {
        setPickers({ hoursPicker, minutesPicker });
    };

    const { hoursPicker, minutesPicker } = pickers;

    return (
        <>
            <div className={"time_picker_wrapper pop " + (show ? "show" : "")}>
                <div className='inner'>
                    {/* <img
                        className='close'
                        src={ClosePopup}
                        onClick={() => setDispatch("show", false)}
                    /> */}
                    <div className='pickers_wrapper'>
                        <div className={"time_picker_with_label " + (hoursPicker ? "open" : "")}>
                            <div
                                className={"overlay " + (hoursPicker ? "hide" : "")}
                                onClick={() => togglePickers(true, false)}
                            ></div>
                            <div className={"time_picker " + (hoursPicker ? "open" : "")}>
                                <div className='inner-wrap'>
                                    <Swiper
                                        onSwiper={setSwiperHours}
                                        slidesPerView={7}
                                        mousewheel={true}
                                        centeredSlides={true}
                                        slideToClickedSlide={true}
                                        loop={true}
                                        navigation={true}
                                        className='mySwiper'
                                        direction={"vertical"}
                                        onClick={() => togglePickers(false, false)}
                                        onSlideChange={(swiper) => {
                                            const { clickedSlide = {} } = swiper;
                                            const { exactHour = {} } =
                                                clickedSlide.attributes || {};
                                            if (exactHour.value) {
                                                setHours(exactHour.value);
                                            } else {
                                                setHours(swiper.realIndex + 4);
                                            }
                                        }}
                                    >
                                        {createHours().map((hour) => (
                                            <SwiperSlide exactHour={hour} key={hour}>
                                                {hour}
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                            <span className='type'>{t("time_picker.hours")}</span>
                        </div>
                        <span className='dots'>:</span>
                        <div className={"time_picker_with_label " + (minutesPicker ? "open" : "")}>
                            <div
                                className={"overlay " + (minutesPicker ? "hide" : "")}
                                onClick={() => togglePickers(false, true)}
                            ></div>
                            <div className={"time_picker " + (minutesPicker ? "open" : "")}>
                                <div className='inner-wrap'>
                                    <Swiper
                                        onSwiper={setSwiperMinutes}
                                        slidesPerView={7}
                                        mousewheel={true}
                                        centeredSlides={true}
                                        slideToClickedSlide={true}
                                        loop={true}
                                        navigation={true}
                                        className='mySwiper'
                                        direction={"vertical"}
                                        onClick={() => togglePickers(false, false)}
                                        onSlideChange={(swiper) => setMinutes(swiper.realIndex)}
                                    >
                                        {createMinutes().map((min) => (
                                            <SwiperSlide key={min}>{min}</SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                            <span className='type'>{t("time_picker.minutes")}</span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <button
                                type='button'
                                className='green_btn_h'
                                onClick={setSelectedDates}
                            >
                                {t("time_picker.confirm")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TimePicker;
