import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment/locale/fr-ch";

import CalendarToolbar from "../Toolbar/CalendarToolbar";
import CalendarLogic from "./CalendarLogic";
import ColoredDateCellWrapper from "./ColoredDateCellWrapper";
import DateHeader from "./DateHeader";
import AddFreeDays from "../AddFreeDays/AddFreeDays";
import ForApproval from "../ForApproval/ForApproval";

const localizer = momentLocalizer(moment);

const CalendarPage = () => {
    const { t } = useTranslation();
    const { openSetFreeDays, openListForApproval, vacationsList, onNavigate, eventStyleGetter } =
        CalendarLogic();

    const userPermitions = useSelector((state) => state.dashboardReducer.permitions);
    const role = useSelector((state) => state.dashboardReducer.role);

    return (
        <>
            {userPermitions["create_vacation"] && <AddFreeDays />}
            {userPermitions["update_vacation"] && <ForApproval />}
            <div className='page-header top-section-list'>
                <h2 className='title'> {t("vacations.title")}</h2>

                <div className='action-buttons'>
                    {userPermitions["create_vacation"] && (
                        <button
                            type='button'
                            className='btn simple-btn'
                            onClick={() => openSetFreeDays()}
                        >
                            {t("vacations.add_vacations_btn")}
                        </button>
                    )}
                    {role !== 3 && userPermitions["update_vacation"] && (
                        <button
                            type='button'
                            className='btn-green'
                            onClick={() => openListForApproval()}
                        >
                            {t("vacations.approve_vacations_btn")}
                        </button>
                    )}
                </div>
            </div>
            <div className='wrapper'>
                <div className='calendar_wrapper vacations'>
                    <Calendar
                        popupOffset={{ x: 30, y: 20 }}
                        localizer={localizer}
                        events={vacationsList}
                        popup={true}
                        components={{
                            toolbar: CalendarToolbar,
                            dateCellWrapper: ColoredDateCellWrapper,
                            month: {
                                dateHeader: DateHeader,
                            },
                        }}
                        eventPropGetter={eventStyleGetter}
                        defaultView={"month"}
                        culture='en'
                        views={["month"]}
                        onNavigate={onNavigate}
                        startAccessor='start'
                        endAccessor='end'
                    />
                </div>
            </div>
        </>
    );
};

export default CalendarPage;
