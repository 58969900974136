import * as yup from "yup";
import i18n from "../languages/i18nextConf";

const Schema = yup.object().shape({
    password: yup
        .string()
        .required("Le mot de passe est obligatoire")
        .min(6, "Doit comporter au moins 6 symboles"),
    // .matches(
    //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //     "validations.password_rule"
    // ),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "Les mots de passe doivent correspondre"),
});

export default Schema;
