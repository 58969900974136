import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getSinglePromocode } from "./_actions";

const PreviewLogic = () => {
    const dispatch = useDispatch();

    const promocodeId = useSelector((state) => state.singlePromocode.promocodeId);
    const showPreview = useSelector((state) => state.singlePromocode.showPreview);

    useEffect(() => {
        if (promocodeId && showPreview) {
            getSinglePromocode(promocodeId, dispatch);
        }
    }, [promocodeId, showPreview]);

    const openPromocodePreview = (data) => {
        dispatch({
            type: "SHOW_PREVIEW_PROMOCODE_POPUP",
            payload: { id: "", status: data },
        });
    };

    const openPromocodeEdit = (id) => {
        dispatch({
            type: "SHOW_EDIT_PROMOCODE_POPUP",
            payload: { id, status: true },
        });
        dispatch({
            type: "SHOW_PREVIEW_PROMOCODE_POPUP",
            payload: { id, status: false },
        });
    };

    return { openPromocodePreview, openPromocodeEdit };
};

export default PreviewLogic;
