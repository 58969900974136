// singlepromocode

const initialState = {
    showPreview: false,
    promocodeId: "",
    showEdit: false,
    promocode: {},
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    const { id, status } = payload || {};
    switch (type) {
        case "SHOW_PREVIEW_PROMOCODE_POPUP":
            return {
                ...state,
                showPreview: status,
                promocodeId: id,
            };
        case "SHOW_EDIT_PROMOCODE_POPUP":
            return {
                ...state,
                showEdit: status,
                promocodeId: id,
            };
        case "GET_SINGLE_PROMOCODE":
            return {
                ...state,
                promocode: payload,
            };
        default:
            return state;
    }
};

export default reducer;
