import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import ScrollToTop from "../helpers/ScrollToTop";

import PublicRoutes from "./PublicRoutes";
import PrivateRoute from "./PrivateRoute";

import Dashboard from "../pages/Dashboard/_index";
import AlertMessage from "../components/AlertMessage/AlertMessage";

const MainRouter = ({ history }) => {
    const isAuthenticated = useSelector((state) => state.auth.token);
    const profileSetup = useSelector((state) => state.auth.profileSetup);

    useEffect(() => {
        if (profileSetup) {
            history.push("/profile-setup");
        } else if (!history.location.pathname.includes("dashboard") && Boolean(isAuthenticated)) {
            history.push("/dashboard");
        }
    }, [isAuthenticated, profileSetup]);

    return (
        <div className='App'>
            <AlertMessage />
            <ScrollToTop>
                <Switch>
                    {PublicRoutes.map((item) => {
                        return (
                            <Route
                                key={item.path}
                                exact={item.exact}
                                path={item.path}
                                component={item.component}
                            />
                        );
                    })}
                    <PrivateRoute path='/dashboard/' component={Dashboard} />
                    <Route render={() => <h1>Not found!</h1>} />
                </Switch>
            </ScrollToTop>
        </div>
    );
};

export default withRouter(MainRouter);
