import React from "react";
import moment from "moment";
import KucHelper from "../../../helpers/KucHelper";
import KucIcon from "../../../images/kuc_icon.png";

const CustomEventDay = (event) => {
    const { start, type, id, payment_status, departure_valet, arrival_valet } = event.event;

    const startTime = moment(start).format("HH:mm -- DD-MM-YYYY");

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "auto",
            }}
        >
            <div>
                {id}
                {KucHelper.isKucReservation(event.event) ?
                    <span>
                            <img src={KucIcon} alt='Kuc' style={{width: "20px", height: "20px", margin:"2px"}}/>
                        </span> : null
                }
                {payment_status === "pending" && (
                    <span style={{ marginLeft: "10px" }}>
                        <i className='fas fa-exclamation-triangle'></i>
                    </span>
                )}
                {type === "start" && !Boolean(departure_valet) && (
                    <i
                        style={{
                            fontSize: "10px",
                            margin: "0 10px",
                        }}
                        className='fas fa-user-slash'
                    ></i>
                )}
                {type === "end" && !Boolean(arrival_valet) && (
                    <i
                        style={{
                            fontSize: "10px",
                            margin: "0 10px",
                        }}
                        className='fas fa-user-slash'
                    ></i>
                )}
            </div>

            <div
                style={{
                    textAlign: "right",
                }}
            >
                <strong>{startTime}</strong>
                {event.title}
            </div>
        </div>
    );
};

export default CustomEventDay;
