import React from "react";
import { Link } from "react-router-dom";

import { Formik, Field, Form } from "formik";
import PasswordIcon from "../../../images/icons/password-icon.svg";

import ButtonLoader from "../../../components/ButtonLoader/ButtonLoader";
import LoginLogic from "./LoginLogic";
import loginSchema from "../../../_validations/loginSchema";

const Login = () => {
    const { onSubmit, loading, passType, togglePassType } = LoginLogic();

    return (
        <>
            <div className='intro-page'>
                <div className='intro-page-inner'>
                    <h1 className='title'>Park&Fly</h1>
                    <h2 className='sub-title'>Admin Login</h2>
                    <div className='form-wrapper'>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                email: "",
                                password: "",
                            }}
                            validationSchema={loginSchema}
                            onSubmit={onSubmit}
                        >
                            {({ errors, touched, isValidating, isValid, dirty }) => {
                                return (
                                    <Form>
                                        <Field
                                            name='email'
                                            type='text'
                                            placeholder='Email address'
                                            className='simple-input mb-3 mt-2'
                                        ></Field>
                                        {errors.email && touched.email && (
                                            <div className='custom-error'>{errors.email}</div>
                                        )}

                                        <div className='simple-input-password'>
                                            <Field
                                                name='password'
                                                type={passType ? "text" : "password"}
                                                placeholder='Password'
                                                className='simple-input'
                                            ></Field>
                                            <img
                                                className='icon password-icon'
                                                onClick={togglePassType}
                                                src={PasswordIcon}
                                            />
                                        </div>
                                        {errors.password && touched.password && (
                                            <div className='custom-error'>{errors.password}</div>
                                        )}

                                        <Link to='forgot-password' className='link'>
                                            Forgot password
                                        </Link>

                                        <button
                                            // disabled={loading || !(isValid && dirty)}
                                            disabled={loading}
                                            type='submit'
                                            className='btn-large btn-submit full-width'
                                        >
                                            Log in
                                        </button>
                                        <ButtonLoader show={loading} />
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
