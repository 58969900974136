import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../../../languages/i18nextConf";

import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiTextField from "@material-ui/core/TextField";

import ButtonLoader from "../../../../../components/ButtonLoader/ButtonLoader";

import clientShema from "../../../../../_validations/clientShema";

import EditLogic from "./EditLogic";

import st from "./styles.module.scss";

const Edit = () => {
    const { t } = useTranslation();
    const {
        state,
        loading,
        onSubmit,
        handleCountryChange,
        countriesList,
        country,
        setInital,
        goBack,
    } = EditLogic();

    const userPermitions = useSelector((state) => state.dashboardReducer.permitions);
    return (
        <div className='container-fluid wrapper'>
            <div className='row'>
                <div className='col-md-8'>
                    <h3> {t("clients.single.title")}</h3>
                    <div className={st.client_summary + " bg pb-0 mb-5"}>
                        <div className={st.summary}>
                            <div className='form-wrapper'>
                                <Formik
                                    enableReinitialize
                                    initialValues={state}
                                    validationSchema={clientShema}
                                    onSubmit={onSubmit}
                                >
                                    {({ errors, touched, setFieldValue, setTouched }) => {
                                        return (
                                            <Form>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <h3>{t("clients.single.first_title")}</h3>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='input-border mb-3'>
                                                            <Field
                                                                name='first_name'
                                                                type='text'
                                                                label={
                                                                    t("clients.single.first_name") +
                                                                    " *"
                                                                }
                                                                component={TextField}
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='input-border mb-3'>
                                                            <Field
                                                                name='last_name'
                                                                type='text'
                                                                label={
                                                                    t("clients.single.last_name") +
                                                                    " *"
                                                                }
                                                                component={TextField}
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='input-border mb-3'>
                                                            <Field
                                                                name='company'
                                                                type='text'
                                                                label={
                                                                    t("clients.single.company") +
                                                                    " *"
                                                                }
                                                                component={TextField}
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='input-border mb-3'>
                                                            <Field
                                                                name='email'
                                                                type='text'
                                                                label={
                                                                    t("clients.single.email") + " *"
                                                                }
                                                                component={TextField}
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='input-border mb-3'>
                                                            <Field
                                                                name='phone'
                                                                type='text'
                                                                label={
                                                                    t("clients.single.phone") + " *"
                                                                }
                                                                component={TextField}
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3 className='mt-3'>
                                                    {t("clients.single.second_title")}
                                                </h3>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='input-border mb-3'>
                                                            <Field
                                                                name='country'
                                                                value={country}
                                                                className='autocomplete'
                                                                options={countriesList}
                                                                getOptionLabel={(option) =>
                                                                    option.name
                                                                }
                                                                component={Autocomplete}
                                                                onChange={
                                                                    ((e) =>
                                                                        setFieldValue(
                                                                            "country_id",
                                                                            e.target.value
                                                                        ),
                                                                    handleCountryChange)
                                                                }
                                                                onBlur={() =>
                                                                    setTouched({
                                                                        ["country_id"]: true,
                                                                    })
                                                                }
                                                                renderInput={(params) => {
                                                                    return (
                                                                        <MuiTextField
                                                                            label={
                                                                                t(
                                                                                    "clients.single.country"
                                                                                ) + " *"
                                                                            }
                                                                            {...params}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                autocomplete:
                                                                                    "nope",
                                                                            }}
                                                                            name='country_id'
                                                                            error={
                                                                                touched[
                                                                                    "country_id"
                                                                                ] &&
                                                                                errors["country_id"]
                                                                            }
                                                                            helperText={
                                                                                touched[
                                                                                    "country_id"
                                                                                ] &&
                                                                                errors["country_id"]
                                                                            }
                                                                        />
                                                                    );
                                                                }}
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='input-border mb-3'>
                                                            <Field
                                                                name='city'
                                                                type='text'
                                                                label={
                                                                    t("clients.single.city") + " *"
                                                                }
                                                                component={TextField}
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='input-border mb-3'>
                                                            <Field
                                                                name='post_code'
                                                                type='text'
                                                                label={
                                                                    t("clients.single.post_code") +
                                                                    " *"
                                                                }
                                                                component={TextField}
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='input-border mb-3'>
                                                            <Field
                                                                name='street'
                                                                type='text'
                                                                label={
                                                                    t("clients.single.street") +
                                                                    " *"
                                                                }
                                                                component={TextField}
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='input-border mb-3'>
                                                            <Field
                                                                name='street_number'
                                                                type='text'
                                                                label={
                                                                    t(
                                                                        "clients.single.street_number"
                                                                    ) + " *"
                                                                }
                                                                component={TextField}
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3 className='mt-3'>
                                                    {t("clients.single.discount_title")}
                                                </h3>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='input-border mb-3'>
                                                            <Field
                                                                name='discount'
                                                                type='text'
                                                                label={
                                                                    t("clients.single.discount") +
                                                                    " *"
                                                                }
                                                                component={TextField}
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={st.button_wrapper}>
                                                    {userPermitions["update_client"] && (
                                                        <>
                                                            <button
                                                                type='button'
                                                                className='btn simple-btn'
                                                                onClick={() => {
                                                                    // setInital();
                                                                    goBack();
                                                                }}
                                                            >
                                                                {t("clients.single.reset")}
                                                            </button>
                                                            <button
                                                                disabled={loading}
                                                                type='submit'
                                                                className='btn btn-submit'
                                                            >
                                                                {t("clients.single.submit")}
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                                <ButtonLoader show={loading} />
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Edit;
