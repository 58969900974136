import { useTranslation } from "react-i18next";
import i18n from "../../../../languages/i18nextConf";

export const headerResultLabel = false;
export const columnHeaders = () => {
    return [
        {
            name: "id",
            label: i18n.t("employees.table.id"),
            sort: true,
            status: "desc",
        },
        {
            name: "first_name",
            label: i18n.t("employees.table.first_name"),
            sort: true,
            // status: "desc"
            status: "none",
        },
        {
            name: "last_name",
            label: i18n.t("employees.table.last_name"),

            sort: true,
            // status: "desc"
            status: "none",
        },
        {
            name: "email",
            label: i18n.t("employees.table.email"),
            label: "Email",
            sort: false,
        },
        {
            name: "phone",
            label: i18n.t("employees.table.phone"),
            label: "phone",
            sort: true,
            status: "none",
        },
        {
            name: "adress",
            label: i18n.t("employees.table.adress"),
            label: "adress",
            sort: false,
        },
        {
            name: "roles.name",
            label: i18n.t("employees.table.role"),
            sort: true,
            status: "none",
        },
        {
            name: "reservations",
            label: i18n.t("employees.table.reservations"),
            sort: false,
            status: "none",
        },
        {
            name: "additional_hours",
            label: i18n.t("employees.table.additional_hours"),
            sort: false,
            status: "none",
        },
        {
            name: "actions",
            label: i18n.t("employees.table.actions"),
            sort: false,
        },
    ];
};

export const tableFilters = {
    // first_name: {
    //     label: "Demo",
    //     filterName: "Demo",
    //     data: [
    //         {
    //             id: 1,
    //             name: "Ivan",
    //         },
    //         {
    //             id: 2,
    //             name: "Dragan",
    //         },
    //         {
    //             id: 3,
    //             name: "Petkan",
    //         },
    //     ],
    // },
};
