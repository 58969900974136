// timePicker

const initialState = {
    type: "",
    chosenTime: "",
    arrival_at_time: "",
    departure_at_time: "",
    show: "",
    expectVal: false,
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SET_TIME_PICKER":
            const { name, value } = payload;
            return {
                ...state,
                [name]: value,
            };
        default:
            return state;
    }
};

export default reducer;
