// singleClient

const initialState = {
    tab: 1,
    client: {},
    vehicles: [],
    clientId: "",
    showCreate: false,
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    const { roles = [], id, status } = payload || {};
    switch (type) {
        case "SET_CLIENT_TAB":
            return {
                ...state,
                tab: payload,
            };
        case "GET_SINGLE_CLIENT":
            return {
                ...state,
                client: payload,
            };
        case "GET_SINGLE_CLIENT_VECHICLES":
            return {
                ...state,
                vehicles: payload,
            };
        case "SHOW_CREATE_CLIENT_POPUP":
            return {
                ...state,
                showCreate: status,
                clientId: id,
            };
        default:
            return state;
    }
};

export default reducer;
