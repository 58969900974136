// clientProfile

const initialState = {
    showProfile: false,
    showChangePassword: false,
    showEditCar: false,
    showEditReservation: false,
    cancelReservation: false,
    cancelReservationSuccess: false,
    cancelReservationError: false,
    decreaseReservationError: false,
    decreaseReservationSuccess: false,
    screen: 1,
    profile: {},
    vehicles: [],
    selectedCar: {},
    selectedReservation: {},
    reservations: [],
    refunded_amount: "",
    refunded_days: "",
};

const clientAuth = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "OPEN_POP_CLIENT":
            const { name, value } = payload;
            return {
                ...state,
                [name]: value,
            };
        case "CHANGE_CLIENT_SCREEN":
            return {
                ...state,
                screen: payload,
            };
        case "SET_CLIENT_PROFILE":
            return {
                ...state,
                profile: payload,
            };
        case "SET_DECREASED_RESERVATION_DATA":
            return {
                ...state,
                refunded_amount: payload.refunded_amount,
                refunded_days: payload.refunded_days,
            };
        case "SET_CLIENT_VEHICLES":
            return {
                ...state,
                vehicles: payload,
            };
        case "SET_SELECTED_CAR":
            return {
                ...state,
                selectedCar: payload,
            };
        case "SET_CLIENT_RESERVATIONS":
            return {
                ...state,
                reservations: payload,
            };

        case "SET_SELECTED_RESERVATION":
            return {
                ...state,
                selectedReservation: payload,
            };

        default:
            return state;
    }
};

export default clientAuth;
