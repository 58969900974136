import React from "react";

import { useSelector } from "react-redux/";
import st from "./styles.module.scss";

const AlertMessage = () => {
    const state = useSelector((state) => state.alertMessage);
    const { show, success, message } = state;
    return (
        <>
            <div
                className={
                    st.message_wrapper +
                    " " +
                    (show ? st.show : "") +
                    " " +
                    (success === true ? st.success : "") +
                    " " +
                    (success === false ? st.error : "")
                }
            >
                <i className='fas fa-exclamation-triangle me-3'></i>
                {message}
            </div>
        </>
    );
};

export default AlertMessage;
