import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { PostInviteEmployee } from "./_actions";

const InviteEmployeeLogic = () => {
    const dispatch = useDispatch();
    const initialState = {
        email: "",
        first_name: "",
        last_name: "",
        role_id: "",
    };
    const [state, setState] = useState(initialState);

    const [loading, setLoader] = useState(false);

    const showPopUp = (data) => {
        dispatch({
            type: "SHOW_INVITE_EMPLOYEE_POPUP",
            payload: data,
        });
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value,
        });
    };

    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);

        PostInviteEmployee(data, dispatch).then((response = {}) => {
            setLoader(false);
            if (response.status === 201) {
                dispatch({
                    type: "SHOW_INVITE_EMPLOYEE_POPUP",
                    payload: false,
                });
                setState(data);
                setTimeout(() => {
                    setState(initialState);
                }, 1000);
            }

            if (response.errors) {
                let moderatedErrors = { error: "" };
                for (const property in response.errors) {
                    moderatedErrors = {
                        ...moderatedErrors,
                        [property]: response.errors[property].toString(),
                    };
                }

                setErrors(response.errors);
            }
        });
    };

    return { state, onChange, onSubmit, showPopUp, loading };
};

export default InviteEmployeeLogic;
