import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { forgotPassword } from "./_actions";

const ForgotPasswordLogic = (history) => {
    const dispatch = useDispatch();
    const [loading, setLoader] = useState(false);

    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);
        forgotPassword(data, dispatch).then((data = {}) => {
            setLoader(false);
            if (data.errors) {
                setErrors(data.errors);
            }
        });
    };

    return { onSubmit, loading };
};

export default ForgotPasswordLogic;
