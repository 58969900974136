import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useSelector } from "react-redux";
import ClosePopup from "../../../../images/icons/close-popup-icon.svg";
import ForApprovalLogic from "./ForApprovalLogic";

import st from "./styles.module.scss";

const ForApproval = () => {
    const { t } = useTranslation();
    const show = useSelector((state) => state.freeDaysForApproval.showForApproval);

    const { state, setStatus, openForApproval } = ForApprovalLogic();

    return (
        <>
            <div className={"pop " + (show ? "show" : "")}>
                <div className={st.inner + " inner"}>
                    <img
                        className={st.close}
                        src={ClosePopup}
                        onClick={() => openForApproval(false)}
                    />
                    <h3> {t("vacations.approve_list.title")} </h3>

                    <div className='row pb-4'>
                        <div className='col-md-12'>
                            <table className={st.for_approval_table}>
                                <thead>
                                    <tr>
                                        <th>{t("vacations.approve_list.employee")}</th>
                                        <th>{t("vacations.approve_list.reason")}</th>
                                        <th>{t("vacations.approve_list.from_date")}</th>
                                        <th>{t("vacations.approve_list.to_date")}</th>
                                        <th>{t("vacations.approve_list.actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.length > 0 &&
                                        state.map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>
                                                        {item.employee_first_name +
                                                            " " +
                                                            item.employee_last_name}
                                                    </td>
                                                    <td>{item.reason}</td>
                                                    <td>{item.from_date}</td>
                                                    <td>{item.to_date}</td>
                                                    {item.status === "pending" && (
                                                        <td>
                                                            <button
                                                                type='button'
                                                                className='small btn simple-btn me-md-2 mt-0'
                                                                onClick={() =>
                                                                    setStatus(item.id, "declined")
                                                                }
                                                            >
                                                                {t(
                                                                    "vacations.approve_list.disaprove_btn"
                                                                )}
                                                            </button>
                                                            <button
                                                                type='button'
                                                                className='small btn btn-green'
                                                                onClick={() =>
                                                                    setStatus(item.id, "approved")
                                                                }
                                                            >
                                                                {t(
                                                                    "vacations.approve_list.aprove_btn"
                                                                )}
                                                            </button>
                                                        </td>
                                                    )}
                                                    {item.status === "approved" && (
                                                        <td>
                                                            <i className='fas fa-check'></i>
                                                            {t(
                                                                "vacations.approve_list.aprove_label"
                                                            )}
                                                        </td>
                                                    )}
                                                    {item.status === "declined" && (
                                                        <td>
                                                            <i class='fas fa-times'></i>
                                                            {t(
                                                                "vacations.approve_list.disaprove_label"
                                                            )}
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })}

                                    {state.length === 0 && (
                                        <tr>
                                            <td colSpan={5} className='text-center'>
                                                {t("vacations.approve_list.noResults")}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForApproval;
