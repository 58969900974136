import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { columnHeaders, tableFilters, headerResultLabel } from "./list_helper";

import { getReservationsListClient } from "./_actions";

const ReservationsLogic = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const querySting = useSelector((state) => state.listTableReducer.query);
    const list = useSelector((state) => state.reservationsListClient.items);

    const [componentMounted, setComponentMounted] = useState(false);
    useEffect(() => {
        setComponentMounted(true);
        return () => {
            dispatch({
                type: "SET_LIST_TABLE_QUERY",
                payload: "",
            });
        };
    }, []);
    useEffect(() => {
        if (params._clientId && querySting && componentMounted) {
            getReservationsListClient(params._clientId, querySting, dispatch);
        }
    }, [componentMounted, querySting]);

    useEffect(() => {
        const column = columnHeaders();
        dispatch({
            type: "SET_TABLE_FILTERS_SORT",
            payload: { column, tableFilters, headerResultLabel },
        });
    }, []);

    const openReservationPreview = (id) => {
        dispatch({
            type: "SHOW_PREVIEW_RESERVATION_POPUP",
            payload: { id, status: true },
        });
    };

    return { list, openReservationPreview };
};

export default ReservationsLogic;
