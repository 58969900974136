import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";

import { Element } from "react-scroll";

import SuccessIcon from "../../../images/home/success_icon.png";
import LogoBlue from "../../../images/logo_blue.png";

import st from "./styles.module.scss";

import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
const Index = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className={st.page_wrapper}>
                {/* <Header /> */}
                <Link to='/'>
                    <img src={LogoBlue} alt='' className='mt-2' />
                </Link>

                <div className={st.book_section}>
                    <div className={st.inner}>
                        <img src={SuccessIcon} alt='' className='mb-3' />
                        <h3> {t("thankyou.title")}</h3>
                        <p> {t("thankyou.text")}</p>
                        <Link to='/' className={st.btn_blank}>
                            {t("thankyou.reservations")}
                        </Link>
                        <br />
                        <Link to='/' className={st.btn}>
                            {t("thankyou.home")}
                        </Link>
                    </div>
                </div>

                <Element name='footer'>
                    <Footer />
                </Element>
            </div>
        </>
    );
};

export default Index;
