import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
// import "moment/locale/gb";

import CalendarToolbar from "./Toolbar/CalendarToolbar";
import CustomEvent from "./CustomEvent";
import CustomEventDay from "./CustomEventDay";
import CustomEventWeek from "./CustomEventWeek";

import EventWrapper from "./EventWrapper";
import CalendarLogic from "./CalendarLogic";
import ColoredDateCellWrapper from "./ColoredDateCellWrapper";
import AddReservation from "../Reservations/Single/Edit";
import PreviewReservation from "../Reservations/Single/Preview";

import st from "./styles.module.scss";

const localizer = momentLocalizer(moment);

const CalendarPage = () => {
    const { t } = useTranslation();
    const userPermitions = useSelector((state) => state.dashboardReducer.permitions);

    let formats = {
        timeGutterFormat: "HH:mm",
    };
    const {
        addReservation,
        reservationsList,
        onNavigate,
        eventStyleGetter,
        onSelectReservation,
        freePlaces,
        onDrillDown,
    } = CalendarLogic();

    return (
        <>
            <AddReservation />
            <PreviewReservation />
            <div className='page-header top-section-list'>
                <h2 className='title'> {t("reservation_calendar.title")} </h2>
                {/* <div className='action-buttons'>
                    {userPermitions["create_reservation"] && (
                        <button
                            type='button'
                            className='btn-green'
                            onClick={() => addReservation()}
                        >
                            {t("reservation_calendar.add_new")}
                        </button>
                    )}
                </div> */}
                {freePlaces.length > 0 && (
                    <table className={st.parking_table}>
                        <thead>
                            <tr>
                                <th>parking name</th>
                                <th>free lots</th>
                                <th>taken lots</th>
                            </tr>
                        </thead>
                        <tbody>
                            {freePlaces.map((item) => {
                                return (
                                    <tr>
                                        <td> {item.parking_name} </td>
                                        <td> {item.info[0].lots_available} </td>
                                        <td> {item.info[0].lots_taken}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
            <div className='wrapper'>
                <div className='calendar_wrapper reservations'>
                    <Calendar
                        popupOffset={{ x: 30, y: 20 }}
                        localizer={localizer}
                        formats={formats}
                        events={reservationsList}
                        popup={true}
                        components={{
                            toolbar: CalendarToolbar,
                            dateCellWrapper: ColoredDateCellWrapper,
                            month: { event: CustomEvent },
                            week: { event: CustomEventWeek },
                            day: { event: CustomEventDay },
                        }}
                        dayLayoutAlgorithm='no-overlap'
                        culture='en'
                        eventPropGetter={eventStyleGetter}
                        defaultView={"month"}
                        onDrillDown={onDrillDown}
                        onSelectEvent={onSelectReservation}
                        views={["month", "week", "day"]}
                        onNavigate={onNavigate}
                        startAccessor='start'
                        endAccessor='end'
                    />
                </div>
            </div>
        </>
    );
};

export default CalendarPage;
