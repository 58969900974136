import * as yup from "yup";

export const NewClient = yup.object().shape({
    email: yup.string().nullable().required("Requis!"),
    phone: yup.string().nullable().required("Requis!"),
    first_name: yup.string().nullable().required("Requis!"),
    last_name: yup.string().nullable().required("Requis!"),
    parkingId: yup.string().nullable().required("Requis!"),
    place: yup.string().nullable().required("Requis!"),
    from_date: yup.string().nullable().required("Requis!"),
    to_date: yup.string().nullable().required("Requis!"),
    from_time: yup.string().nullable().required("Requis!"),
    to_time: yup.string().nullable().required("Requis!"),
    note: yup.string().nullable().required("Requis!"),
    brand: yup.string().nullable().required("Requis!"),
    model: yup.string().nullable().required("Requis!"),
    register_number: yup.string().nullable().required("Requis!"),
    fligh_number: yup.string().nullable().required("Requis!"),
});

export const ExistingClient = yup.object().shape({
    client_id: yup.string().nullable().required("Requis!"),
    vehicle_id: yup.string().nullable().required("Requis!"),
    parking_id: yup.string().nullable().required("Requis!"),
    departure_at: yup.string().nullable().required("Requis!"),
    arrival_at: yup.string().nullable().required("Requis!"),
    departure_time: yup.string().nullable().required("Requis!"),
    arrival_time: yup.string().nullable().required("Requis!"),
    note: yup.string().nullable().required("Requis!"),
    flight_number: yup.string().nullable().required("Requis!"),
});
