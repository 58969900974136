import React from "react";
import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";

import { useSelector, useDispatch } from "react-redux";

import ClosePopup from "../../../images/icons/close-popup-icon.svg";
import st from "./styles.module.scss";

const Privacy = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const show = useSelector((state) => state.clientAuth.showPrivacy);

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POPUP_AUTH",
            payload: { name, value },
        });
    };
    return (
        <div className={st.info_popup + " pop  " + (show ? "show" : "")}>
            <div className={st.inner + " inner"}>
                <img
                    className={st.close}
                    src={ClosePopup}
                    onClick={() => togglePopUp("showPrivacy", false)}
                />
                <h4>{t("footer.privacy_title")}</h4>
                <div className={st.text_wrapper}>{t("footer.privacy_text")}</div>
            </div>
        </div>
    );
};

export default Privacy;
