import React from "react";

import { Formik, Field, Form } from "formik";

import ButtonLoader from "../../../components/ButtonLoader/ButtonLoader";
import ForgotPasswordLogic from "./ForgotPasswordLogic";
import forgotPassSchema from "../../../_validations/forgotPassSchema";

const ForgotPassword = ({ history }) => {
    const { onSubmit, loading } = ForgotPasswordLogic(history);

    return (
        <div className='intro-page'>
            <div className='intro-page-inner medium'>
                <h2 className='mid-title'>Forgot your password?</h2>
                <p className='info-text'>
                    Enter the email you use for Park&fly Geneva and we’ll send you instructions to
                    reset your password.
                </p>
                <div className='form-wrapper'>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            email: "",
                        }}
                        validationSchema={forgotPassSchema}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched, isValidating, isValid, dirty }) => {
                            return (
                                <Form>
                                    <Field
                                        name='email'
                                        type='text'
                                        placeholder='Email address'
                                        className='simple-input'
                                    ></Field>
                                    {errors.email && touched.email && (
                                        <div className='custom-error'>{errors.email}</div>
                                    )}

                                    <button
                                        disabled={loading || Object.keys(errors).length}
                                        type='submit'
                                        className='btn-large btn-submit full-width'
                                    >
                                        Reset password
                                    </button>
                                    <ButtonLoader show={loading} />
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
