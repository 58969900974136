export const TRANSLATIONS_EN = {
    book_btn: "Book",
    header: {
        login: "login",
        menu_1: "About",
        menu_2: "Our rates",
        menu_3: "Our service",
        menu_4: "Secure booking form",
        logout: "Logout",
        profile: "Profile & Reservations",
        call_us: "Call us",
    },
    hero: {
        title: "No need to look for a parking lot arriving at Geneva Cointrin airport",
        secondary_title:
            "Convenient and affordable parking service at Cointrin Airport 7 days a week from 4am to 1am",
        list_1: "Plan and book your parking easily in advance",
        list_2: "Entrust your vehicle to trusted professionals",
        list_3: "Save time and take your plane with peace of mind",
        consult: "Check rates",
        payments: "Approved payment methods",
    },
    google_baner: {
        title: "Google Reviews",
        info: "Our customers' experience is our priority. Check out what our users have to say about ParkFly.",
        link: "Give a review",
        reviews: "Reviews",
    },
    advantages_box: {
        title_1: "Park Fly, the parking service that",
        title_2: "saves you a lot of hassle",
        section_1_title: "You're about to take off from Geneva Cointrin, but you're afraid:",
        section_1_text_1: "not to find a seat?",
        section_1_text_2: "that you'll end up driving in circles in the car parks?",
        section_1_text_3: "to have to walk for miles?",
        section_1_text_4: "miss your plane?",
        section_2_title_1: "Our valet service is",
        section_2_title_2: "here to help you",
        section_2_text_1: "Parking nearby & at the best price",
        section_2_text_2: "Pre-booked space",
        section_2_text_3: "Service tailored to your needs",
        section_2_text_4: "Worry-free travel",
        section_2_link: "Calculate my fare",
    },
    advantages_list: {
        title_1: "Serenity",
        text_1: "No more stressing about not finding a parking lot? In a few minutes, our valets will take charge of your vehicle and you can take off in complete relaxation",
        title_2: "Saves time",
        text_2: "No need to arrive hours before your flight to make sure you are on time. Flying becomes faster and much more convenient",
        title_3: "Safety & Insurance",
        text_3: "Your car is insured and we make sure it is safe. You are guaranteed to find it in the same condition",
    },
    callUs: {
        title: "Do you have any questions?",
        text_1: "Contact a member of our team,",
        text_2: "available 7 days a week.",
    },
    reviews_section: {
        title_1: "Your peace of mind",
        title_2: "priceless.",
        on: "about",
        from: "from",
    },
    history_section: {
        title_1: "Park Fly Geneva ",
        title_2: "- Your valet parking service since 2019",
        text_1: "We take care of all your parking, from arrival to departure...",
        text_2: "...for less than the cost of shuttles and the competition...",
        text_3: "...offering you the best in unlimited insurance, security and guarantees",
    },
    pricing_section: {
        title: "The Park Fly Geneva valet team is made up of professionals who have already made life easier for thousands of travellers",
        text_1_1: "Our priorities:",
        text_1_2: "Your peace of mind, your satisfaction and the safety of your vehicle.",
        text_2: "We are at your disposal every day, almost 24 hours a day. Contact us if you have any questions",
        link_1: "Calculate my rate",
        link_2: "Contact us",
        section_title: "Our rates",
        per_day: "per additional day",
        days: "days",
        day: "day",
    },
    how_does_work: {
        title_1: "How does it work?",
        title_2: "How does Park Fly work?",
        box_title_1: "Book online in 5 minutes",
        box_text_1: "Book online in 5 minutes",
        box_title_3: "Receive news about your car",
        box_text_3:
            "Once your car is parked, you receive pictures of your parked car and its mileage",
        box_title_2: "On the day, meet at Route de Pré-Bois 14 - 1216 Cointrin",
        box_text_2:
            "Drop off your vehicle at our reception base, your driver will drive you immediately to the airport (2 min drive).",
        box_title_4: "Pick up the vehicle easily on your return",
        box_text_4:
            "We keep you informed before your arrival and your driver picks you up with your car",
    },
    trust_us_section: {
        title: "Entrusting your car to a valet is not a luxury, you can also do it and simplify your trip",
        sub_title: "You have doubts, you ask yourself questions? ",
        text: "We are aware that it is not easy at first to entrust your car to a stranger, to not know the exact location of the vehicle, to wonder about the recovery of the vehicle",
        list_1: "3 years of activity at Geneva airport",
        list_2: "X Google reviews",
        list_2_link: "Consult the reviews",
        list_3: "X qualified and attentive professionals who are at your service",
    },
    parking_section: {
        title: "Are you travelling in a group or with a large family?",
        text_1: "Take advantage of the 100% electric shuttle service of our partner LOW COST PARK:",
        text_2: "Leave your car at the P+R Vailly and your driver will drive you to Geneva airport in 15 minutes, then pick you up on your return",
        link: "Discover the service",
        slots: "available parking spaces",
    },
    faq_section: {
        callUs_banner_1: "You still have questions about ",
        callUs_banner_2: "the process of",
        callUs_banner_3: "booking or sharing your vehicle",
        title_1_1: "Questions",
        title_1_2: "les plus fréquemment posées",
        title_1: "How to benefit from our service?",
        text_1: "Book on our website and pay online. You will receive confirmation of your order and your invoice by e-mail",
        title_2: "What documents are required at the time of booking?",
        text_2: "We need your arrival flight number",
        title_3: "Can you pick up several vehicles?",
        text_3: "Yes, you only need to make one reservation per vehicle",
        title_4: "Do you accept any type of vehicle?",
        text_4: "Yes, as long as your vehicle does not require a special permit and its height does not exceed 250 cm",
        title_5: "Where do I drop off my vehicle?",
        text_5: "In our offices located Route de Pé-Bois 14 - 1216 Cointrin, then the driver will drive you to the airport with your vehicle. The journey time is only 2 minutes",
        title_6: "What documents will I be asked for?",
        text_6: "You will need to provide the booking file (in its physical printed form, or on a smartphone) as well as the vehicle's papers (registration document, insurance). A photocopy, placed in the glove box, will be sufficient",
        title_7: "Can another person drop off or pick up my vehicle?",
        text_7: "Yes, just inform our office to register the name and mobile number of the person who will drop off or pick up your vehicle",
        title_8: "Can the trailer be executed?",
        text_8: "To ensure your peace of mind, a survey will be carried out in your presence and submitted for your approval",
        title_9: "Should I call you to let you know I'm coming?",
        text_9: "Yes, on the day of your return, as soon as you have collected your luggage, call the valet and he will come and collect you with your car directly from the airport. The direct line of the valet will be communicated to you the day before by SMS",
        title_10: "Where is the pick-up of your car?",
        text_10: "Exactly at the same place where the valet dropped you off at your departure",
        title_11: "My flight is delayed, what should I do?",
        text_11:
            "Thanks to the flight number you provided when you booked, we are able to monitor flight schedules to account for any delays. Your valet will be waiting for you in any case",
        // title_12 : "I need to extend my stay or my flight is cancelled. What can I do?
        // text_12:
        // "You will need to log in to your online space on our website and modify the current booking, the price will be updated and the additional amount will be requested to be paid directly. ",
    },
    footer: {
        hours_1: "Our schedule",
        hours_2: "Monday to Sunday",
        address_1: "Our address",
        address_2: "Route de Pré-Bois 14",
        address_3: "1216 Cointrin",
        copyrignht: "ParkFly Geneva all rights reserved",
        tc_link: "Terms and conditions",
        payment_methods: "Approved payment methods",
        terms_title: "Terms & Conditions",
        terms_text: {
            title_1: "1. scope of application",
            text_1_1:
                "Park&Fly Geneva is a brand managed by the company PFG N° IDE : CHE-289.931.510",
            text_1_2:
                "These general terms and conditions of sale are valid for the acceptance of vehicles from customers after booking on www.parkflygeneva.ch. The temporary transfer of the vehicle is made for the sole purpose of parking for the agreed rental period, until the return of the company's vehicle to the customer.",
            title_2: "2. drawing up the contract",
            text_2_1:
                "The sending of a reservation via the website www.parkflygeneva.ch constitutes a binding offer for the conclusion of a contract, which is effectively concluded when a confirmation e-mail is sent. The reservation must be made at least 12 hours before the desired time.  After this time, reservations are no longer possible",
            text_2_2:
                "If the booking is made by a third party, then they will be jointly and severally liable with the actual customer for all contractual obligations. Any claims in respect of this contractual relationship may only be made by the customer, and may only be transferred to a third party with the effective consent of PFG.",
            title_3: "3. volume of services",
            text_3_1:
                "3.1 For each reservation, PFG shall provide the customer with a single parking ticket. The vehicle will be kept in a car park in a location chosen by PFG for the duration of the contract. The service covers the transfer of the vehicle by PFG to the car park, as well as its return. The client ensures that the vehicle is roadworthy for the duration of the contract. In the event of a technical problem, it is the client's responsibility to take appropriate action at their own expense. If PFG's agents note a fluid leak, then PFG reserves the right to carry out appropriate repairs at the customer's expense to resolve any problems. The customer acknowledges that they owe PFG the cost of any repairs",
            text_3_2:
                "3.1 The opening hours are set by PFG and announced on the website www.parkflygeneva.ch PFG reserves the right to change these hours unilaterally provided that this is adequately announced. The client undertakes to respect these opening hours and to hand in and collect the vehicle at the agreed times",
            title_4: "4. prices and payment conditions",
            text_4_1:
                "4.1 The current rates are indicated on the homepage of www.parkflygeneva.ch, and are also recalled and confirmed at the time of the reservation request. The current rates include 7.7% VAT",
            text_4_2:
                "4.2 The customer is obliged to pay for the chosen service on www.parkflygeneva.ch before delivery of the vehicle. Payment shall be made at the time of booking by means of a credit card. Any extension or violation of the contractual parking period will be charged in accordance with the current rates indicated on our website. In this case, the customer acknowledges that he/she owes www.parkflygeneva.ch the updated cost according to the new services",
            text_4_3:
                "4.3 In accordance with the applicable laws, PFG has a right of retention and may, in the event of non-payment or problems with the credit card used for the reservation, refuse to return the vehicle to the Customer. If necessary, PFG may take legal action for the realisation of the vehicle and the objects contained therein, in accordance with the Federal Law on Debt Collection and Bankruptcy",
            title_5:
                "5. Cancellations, changes to bookings and subsequent changes to booked services",
            text_5_1:
                "PFG offers the possibility of cancelling or modifying any reservation up to 1 hour before its effective start. The operation can only be carried out on the online customer area, no request for modification or cancellation can be made by e-mail or telephone. ",
            text_5_2: "Changes can be made without limit and without charge",
            text_5_3: "Cancellations are subject to a fixed administrative fee:",
            text_5_3_1: "Cancellation up to 24 hours before the start of the booking, CHF 25.00",
            text_5_3_2: "Cancellation less than 24 hours before the start of the booking, 50.-CHF",
            text_5_3_3: "In case of no-show, no refund",
            title_6: "6. Liability and exemption from liability",
            text_6_1:
                "6.1 As a service provider, PFG undertakes to use its best endeavours to execute its mandate with all due diligence to satisfy the client.",
            text_6_2:
                "6.2 As a service provider, PFG shall only be liable in the event of proven gross negligence, whether in respect of material damage, personal injury or death to the vehicle or the customer, or in the event of late return. All claims must be notified to PFG before the vehicle leaves the drop-off point. Under PFG's responsibility the vehicle is only insured against collision, not against theft, flood, fire, hail. PFG is not responsible for theft of items inside the vehicle (such as cameras, mobile phones, car radios, jewellery and other valuables...) any damage to rims, tyres or scratches to the bodywork would not be covered - PFG cannot be held responsible for anything beyond its reasonable control",
            text_6_3:
                "6.3 PFG is entitled to return the vehicle to the person in possession of the pick-up email. To ensure the security of the vehicle, PFG may only return the vehicle upon presentation of suitable identification. PFG shall not be liable for the loss or theft of the pick-up e-mail, in particular if it is presented by a third party who uses it fraudulently to obtain the return of the vehicle",
            text_6_4:
                "6.6 In the event that PFG loses the keys to the vehicle, we undertake to provide a vehicle for the customer to return. We will arrange for the replacement key to be collected from the customer in good time for the return of the vehicle. PFG will cover the cost of making a replacement key",
            text_6_5: "",
            title_7: "7. Return of the vehicle keys",
            text_7: "The customer contractually undertakes to hand over the keys of the vehicle to PFG's employees upon handover",
            title_8: "8. Other",
            text_8_1:
                "8.1. The personal data provided by the client to PFG, in particular at the time of booking, shall be used exclusively to ensure the implementation of the contractual services, and may not be disclosed to any third party.",
            text_8_2:
                "8.2. Any changes to the Terms and Conditions must be notified in writing. Unfair or unequal changes are prohibited",
            text_8_3:
                "8.3. The contractual relationship between the client and PFG is subject exclusively to Swiss law. The exclusive place of jurisdiction shall be Geneva",
        },
        privacy_title: "Privacy Policy",
        privacy_text:
            "These Terms and Conditions constitute a legally binding agreement between you, whether in your individual capacity or on behalf of an entity ('you') and [name of business entity] ('we' or 'us'), regarding your access to and use of the [name of website.com] website and any other form of media, media channel, mobile website or mobile application associated with, linked to, or otherwise connected to it (collectively, the 'Site'). You acknowledge that by accessing the Site, you have read, understand and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, you are expressly prohibited from using the Site and must immediately cease using it. These Terms and Conditions constitute a legally binding agreement between you, either personally or on behalf of an entity ('you') and [name of business entity] ('we', 'us' or 'our'), regarding your access to and use of the [name of website.com] website and any other form of media, media channel, mobile website or related mobile application linked or otherwise connected to it (collectively, the 'Site'). You acknowledge that by accessing the Site, you have read, understand and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, you are expressly prohibited from using the Site and must discontinue using the Site immediately",
    },
    client_booking: {
        days_to_stay: "Days to stay",
        price_label: "Price",
        next: "Next step",
        back: "Return",
        phone_placeholder: "p.ex. 79 123 45 67",

        side_bar: {
            title: "Book your driver",
            step_1: "Date and time",
            step_2: "Information",
            step_3: "Vehicle & payment",
            result_title: "Summary of your booking",
            duration: "Duration",
            hours: "Days",
            departure: "Departure",
            arival: "Return",
            price: "Total price",
            info: "For more information",
        },
        step_1: {
            title_drop_off: "When will you drop off your vehicle?",
            return: "When do you return?",
            dateFormat: "DD-MM-YY",
            mobile_title: "Booking amount",
            for: "for",
            days_stay: "days of parking",
            depart: "Departure",
            arival: "Return",
            date: "Date",
            hour: "Time",
            select: "Select",
            error_dates: "Please select departure and return date correctly!",
        },
        step_2: {
            login: "login",
            login_title: "Log in to my account",
            forgot_pass: "Forgot your password?",
            forgot_pass_link: "Reset",
            reg_title: "New customer?",
            reg_title_main: "Create your account in just a few clicks",
            reg_text:
                "Create your account in just a few clicks. In order to guarantee an optimal service, the creation of an account is necessary. The creation of your account will not take more than 2 minutes. You will then be able to continue your reservation.",
            reg_link: "Create an account",
            email: "Email",
            password: "Password",
            login_btn: "Log in",
            sec_title: "Create an account",
            reg_title_form: "Login to my account",
            country: "Country",
            first_name: "Name",
            last_name: "First name",
            phone: "Mobile",
            street: "Address",
            street_number: "Number",
            post_code: "Postcode",
            city: "City",
            company: "'Optional' company",
        },
        step_3: {
            go_back: "Go Back",
            sec_title: "Edit",
            car_title: "Car details",
            new_car: "Create a new car",
            new_car_2: "Or create a new car",
            select_car: "Select from your vehicles",
            flight_number: "Return flight number",
            brand: "Vehicle",
            model: "Model",
            plate: "Plate number",
            payment_title: "Payment details",
            card_number: "Card number",
            card_date: "Expiry date",
            cvc: "Cvc",
            tt_text: "I agree with the",
            tt_link: "Terms and conditions",
            pay_btn: "Pay and finalise my booking",
        },
        thankyou: {
            title_1: "We thank you and you",
            title_2: "confirm your booking",
            text: "The details of your reservation, as well as the instructions for handing over the keys of your vehicle are sent directly to you by email. The whole team of Park Fly Geneva wishes you a good holiday",
            greetings: "For any questions, please contact us",
        },
    },
    client_profile: {
        nav_1: "Profile",
        nav_2: "Reservations",
        personal_information: "Personal information",
        edit: "Edit",
        full_name: "Full name",
        first_name: "First name",
        last_name: "Last name",
        company: "Company name",
        email: "Email address",
        phone: "Phone number",
        country: "country",
        city: "city",
        address: "address",
        street_number: "number",
        post_code: "zip",
        brand: "brand",
        model: "model",
        plate: "plate number",
        actions: "actions",
        cars: "cars",
        no_cars: "No cars to show!",
        add_car: "Add another car",
        change_pass: "Change password",
        edit_personal_title: "Change personal information",
        update: "update",
        cancel: "cancel",
        аdd_reservation: "add a reservation",
        edit_car: {
            title: "add another vehicle",
            title_edit: "edit vehicle details",
            brand: "make of vehicle",
            model: "model",
            plate: "Plate number",
            submit: "add",
            submit_edit: "Apply changes",
        },

        reservations: {
            id: "id",
            arrival: "Arrival",
            departure: "Departure",
            stay: "stay",
            status: "status",
            photos: "photos",
            tax: "tax",
            actions: "actions",
            no_photos: "no photos",
            no_results: "no reservations at the moment!",
            when_departure: "When is your appointment?",
            when_arrival: "When_arrival?",
            when_arrival: "When will you return?",
            select_car: "Select a vehicle",
            edit_title: "Modify the reservation",
            edit: "Modify",
            price_label: "It will cost you",
            price: "price",
            for: "for",
            days_stay: "days of stay",
            add_car: "Add another vehicle",
            sorry_to_go: "We are sorry to see you go!",
            successfully_canceled: "You have successfully cancelled your reservation",
            continue: "continue",
            contact: "Contact us",
            any_issues: "if there are any problems",
            unsucess: "Unsuccessful cancellation!",
            unsucess_message: "Unable to cancel your booking.",
            unsucess_decreese: "You cannot reduce your booking!",
            successfully_updated: "You have successfully updated your reservation",
            decreased: "Your booking has been reduced by",
            days: "days",
            refunded_amount: "CHF has been refunded to your credit card",
            cancel: "Cancel",
            edit_2: "Edit",
        },
    },

    client_auth: {
        login: "login",
        email: "email address",
        password: "password",
        forgot: "Forgot your password?",
        forgot_title: "Forgot your password",
        forgot_info:
            "Enter the email address associated with your account and we will send you a link to reset your password",
        reset: "Reset",
        no_account: "Don't have an account?",
        book_now: "Book now and create",
        send: "send",
        reset: "Reset password",
        new_pass: "New password",
        pass_reset_info: "*Your new password must be different from previous passwords",
        confirm_pass: "Confirm password",
        update_pass: "Update password",
        unauthorized: "Wrong email or password",
    },
    date_picker_range: {
        confirm: "confirm",
    },
    time_picker: {
        hours: "hours",
        minutes: "minutes",
        confirm: "confirm",
    },
    validations: {
        password_rule:
            "Must contain 8 characters, one upper case, one lower case, one number and one special case character",
    },
    cansel_reservation: {
        title: "Cancel reservation",
        info_text:
            "Are you sure you want to delete your reservation? You cannot cancel this action.",
        delete: "Delete",
        cancel: "Cancel",
    },
    components: {
        table: {
            search: "Search...",
            result: "result",
            of: "of",
            per_page: "Rows per page",
        },
        delete_pop: {
            yes: "yes",
            no: "no",
        },
    },
    sidebar: {
        Statistics: "Dashboard",
        VacationsDays: "Vacation calendar",
        Employees: "Employees",
        Customers: "Customers",
        Reservations: "Reservations",
        AllClients: "All Clients",
        Calendar: "Calendar",
        Parkings: "Parking lots",
        Prices: "Prices",
        LogOut: "Log Out",
    },
    employees: {
        title: "Employees",
        inviteButton: "Invite a new employee",
        inviteForm: {
            title: "Invite a new employee",
            first_name: "first name",
            last_name: "last name",
            email: "email",
            assign_role: "Assign a role",
            manager: "Manager",
            valet: "Valet",
            cancel: "cancel",
            submit: "Submit",
        },
        delete: {
            question_1: "Are you sure you want to delete ",
            question_2: "of your employees? ",
        },
        table: {
            id: "ID",
            first_name: "first name",
            last_name: "last name",
            email: "email",
            phone: "phone",
            address: "address",
            role: "role",
            reservations: "Month reservations",
            overtime: "heures supplémentaires",
            actions: "Actions",
            noResults: "No results yet!",
        },
        preview: {
            info_title: "Account information",
            address_title: "Address",
            name: "Full name",
            email: "email",
            insurance: "Insurance number",
            phone: "Phone number",
            birghtday: "Birthday",
            reservations: "Reservations made",
            hours: "Overtime",
            city: "City",
            district: "district",
            zip: "zip",
            street: "street",
            roleName: "Role",
        },
        simple: {
            title: "Account information",
            info_title: "Account information",
            address_title: "Address",
            first_name: "First name",
            last_name: "Last name",
            email: "email",
            insurance: "Insurance number",
            password: "password",
            phone: "Phone number",
            birghtday: "Birthday",
            city: "Ville",
            district: "district",
            zip: "zip",
            street: "street",
            street_number: "street number",
            submit: "confirm",
            roleName: "Role",
        },
    },
    statistics: {
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        May: "May",
        june: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        december: "December",
        title_profit: "Total profit",
        reservations_title: "Total reservations",
        statistics: "statistics",
        reservations_btn: "Reservations",
    },
    clients: {
        title: "Clients",
        add_new_client: "Add new client",
        add_new_reservation: "Add a new reservation",
        delete: {
            question_1: "Are you sure you want to delete ",
            question_2: "of your customers? ",
        },
        table: {
            id: "ID",
            first_name: "first name",
            last_name: "last name",
            country: "country",
            email: "email",
            phone: "phone",
            reservations: "reservations",
            amount: "amount",
            actions: "actions",
            noResults: "No results yet!",
        },
        preview: {
            main_title: "Clients",
            information: "information",
            reservations: "reservations",
            modify: "Modify Client",
            delete: "Delete Client",
            first_title: "Account Information",
            second_title: "Address",
            first_name: "First name",
            last_name: "Last name",
            company: "company",
            email: "email",
            phone: "phone",
            country: "country",
            city: "city",
            district: "district",
            post_code: "zip",
            street: "street",
            street_number: "street_number",
            brand: "brand",
            model: "model",
            plate: "plate number",
            noResults: "No results yet!",
        },
        single: {
            title: "Edit information",
            first_title: "Account information",
            second_title: "Address",
            first_name: "First name",
            last_name: "Last name",
            company: "company",
            email: "email",
            phone: "phone",
            country: "country",
            city: "city",
            district: "district",
            discount_title: "discount",
            discount: "discount",
            post_code: "zip",
            street: "street",
            street_number: "street number",
            reset: "cancel",
            submit: "submit",
            vehicles: "vehicles",
            make: "make",
            model: "model",
            plate: "plate number",
        },
    },
    reservations: {
        title: "Reservations",
        add_new_button: "Add a new reservation",
        delete: {
            question_1: "Are you sure you want to cancel",
            question_2: "?",
        },
        table: {
            id: "Id",
            from: "Booking To",
            arrival_at: "Arrival At",
            departure_at: "Departure AT",
            stay: "stay",
            status: "status",
            payment_status: "payment status",
            images: "Gallery",
            tax: "tax",
            price: "price",
            flight: "flight",
            register_number: "register number",
            unpaid_notification: "notification not paid",
            send: "Send",
            notsend: "Do not send",
            action: "Actions",
            noResults: "No results yet!",
            none: "none",
        },
        single: {
            edit_title: "Modify the reservation",
            create_title: "Add a new reservation",
            create_label: "Created",
            client: "Choose the client",
            car: "Choose the car",
            parking: "Parking",
            departure_at: "departure at",
            departure_time: "departure time",
            arrival_at: "arrival at",
            arrivaltime: "arrival time",
            duration: "duration",
            note: "note",
            flight: "flight number",
            departure_valet: "Departure Valet",
            arrival_valet: "Departure VALET",
            submit: "Confirm",
            clear: "clear",
        },
        preview: {
            title: "Booking",
            arrived: "arrived",
            returned: "returned",
            stay: "to stay",
            parked: "to park in the car park",
            note: "Note",
            photos: "Photos",
            upload: "Upload a picture",
            departure_valet: "arrival valet",
            arrival_valet: "Departure valet",
            edit: "Edit information",
            complete: "Mark as complete",
            delete: "Delete",
            unknown: "unknown",
            no_images: "No images uploaded",
            submit: "submit",
            active: "Arrived",
            not_come: "No show",
            not_done: "Not delivered",
            declined: "cancelled",
            done: "Delivered",
            status: "Status",
            payment_status: "Payment status",
            change_status: "Change status",
            assign: "assign",
        },
    },
    reservation_calendar: {
        title: "reservation calendar",
        add_new: "Add a new reservation",
        come: "Coming",
        active: "Arrival",
        returned: "returned",
        go: "going",
        not_done: "not delivered",
        not_come: "not come",
        day: "day",
        week: "Week",
        month: "Month",
        ending: "ending",
        incoming: "incoming",
    },
    parkings: {
        title: "Parkings",
        add_new_button: "Add a new car park",
        table: {
            id: "ID",
            name: "name",
            city: "city",
            street: "street",
            number: "number",
            phone: "phone",
            lots: "lots",
            action: "actions",
            noResults: "No results yet!",
        },
        preview: {
            title: "Parking",
            sub_title: "Parking information",
            name: "Full name",
            address: "address",
            city: "City",
            street: "street",
            street_number: "street number",
            phone: "phone number",
            lots: "lots",
            edit_btn: "edit information",
        },
        single: {
            edit_title: "Modify parking",
            create_title: "Add a new car park",
            name: "Full name",
            address: "Address",
            city: "City",
            street: "street",
            street_number: "number",
            phone: "telephone number",
            lots: "lots",
            submit_btn: "submit",
            clear_btn: "clear",
        },
    },
    vacations: {
        title: "Holiday calendar",
        add_vacations_btn: "Add non-working days",
        approve_vacations_btn: "Approve holidays",
        legend: {
            aproved: "Approved paid holiday days",
            pending: "Pending approval",
            sickness: "Sick leave",
        },
        vacations_form: {
            title: "Add days not worked",
            from_date: "From Date *",
            to_date: "To Date *",
            sick: "Sickness",
            vacation: "Vacation",
            cancel_btn: "Cancel",
            submit_btn: "Submit",
        },

        approve_list: {
            title: "Approve holiday",
            employee: "Employee",
            reason: "Reason",
            from_date: "From date",
            to_date: "To date",
            actions: "Actions",
            aprove_btn: "Approve",
            disaprove_btn: "Disapprove",
            aprove_label: "Approved",
            disaprove_label: "Disapproved",
            noResults: "No vacancy for approval!",
        },
    },
    price: {
        title: "Price",
        day: "Day",
        submit_btn: "Submit",
    },
};
