import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { getSingleClient } from "./_actions";

const SingleLogic = (match) => {
    const { t } = useTranslation();

    const history = useHistory();

    const dispatch = useDispatch();
    const confirmDelete = useSelector((state) => state.confirmDelete);

    useEffect(() => {
        if (match.params._clientId) {
            getSingleClient(match.params._clientId, dispatch);
        }
    }, []);

    const [editMode, setEditMode] = useState(false);
    useEffect(() => {
        if (history.location.search === "?edit=true") {
            setEditMode(true);
        } else {
            setEditMode(false);
        }
    }, [history]);

    const setTab = (tab) => {
        history.push(history.location.path);
        dispatch({
            type: "SET_CLIENT_TAB",
            payload: tab,
        });
    };
    const openEditClient = (id) => {
        history.push(urlBase + id + "?edit=true");
    };

    const openReservationEdit = (id) => {
        dispatch({
            type: "SHOW_EDIT_RESERVATION_POPUP",
            payload: { id, client_id: match.params._clientId, status: true },
        });
    };

    const urlBase = "/dashboard/clients/";

    return { editMode, openEditClient, setTab, urlBase, openReservationEdit };
};

export default SingleLogic;
