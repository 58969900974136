import React from 'react';
import st from './styles.module.scss'
const ButtonLoader = ({show}) => {
    return (  
        <div className={st['btn-loader-wrapper'] +' '+ (show ? st.show: '')}> 
            <div className={st['lds-spinner']}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
     );
}
 
export default ButtonLoader;