import React from "react";
import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-scroll";

import st from "./styles.module.scss";

const ProfilePreview = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.clientProfile.profile);
    const vehicles = useSelector((state) => state.clientProfile.vehicles);

    const { first_name, last_name, country, company, email, phone, city, street, street_number } =
        profile;
    const { name: countryName } = country || {};
    const toggleScreen = (value) => {
        dispatch({
            type: "CHANGE_CLIENT_SCREEN",
            payload: value,
        });
    };
    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POP_CLIENT",
            payload: { name, value },
        });
    };
    const setSelectedCar = (value) => {
        dispatch({
            type: "SET_SELECTED_CAR",
            payload: value,
        });
    };

    return (
        <>
            <div className={st.profile + " row"}>
                <div className='col-md-6'>
                    <h3>
                        {t("client_profile.personal_information")}
                        <span onClick={() => toggleScreen(2)}>
                            <i className='fas fa-pen'></i>
                            {t("client_profile.edit")}
                        </span>
                    </h3>
                    <table className={st.personal_info}>
                        <tbody>
                            <tr>
                                <td> {t("client_profile.full_name")}</td>
                                <td>{first_name + " " + last_name}</td>
                            </tr>
                            <tr>
                                <td>{t("client_profile.company")}</td>
                                <td>{company ? company : "-"}</td>
                            </tr>
                            <tr>
                                <td>{t("client_profile.email")}</td>
                                <td>{email ? email : "-"}</td>
                            </tr>
                            <tr>
                                <td>{t("client_profile.phone")}</td>

                                <td>{phone ? phone : "-"}</td>
                            </tr>
                            <tr>
                                <td>{t("client_profile.country")}</td>
                                <td>{countryName ? countryName : "-"}</td>
                            </tr>
                            <tr>
                                <td>{t("client_profile.address")}</td>
                                <td>{city + " " + street + " " + street_number}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='col-md-6'>
                    <h3>{t("client_profile.cars")}</h3>
                    <div className={st.cars_table_wrapper}>
                        <table className={st.cars_info}>
                            <thead>
                                <tr>
                                    <th> {t("client_profile.brand")} </th>
                                    <th> {t("client_profile.model")} </th>
                                    <th>{t("client_profile.plate")} </th>
                                    <th>{t("client_profile.actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vehicles.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.brand}</td>
                                            <td>{item.model}</td>
                                            <td>{item.plate}</td>
                                            <td>
                                                <i
                                                    className='fas fa-pen'
                                                    onClick={() => {
                                                        setSelectedCar(item);
                                                        togglePopUp("showEditCar", true);
                                                    }}
                                                ></i>
                                                {/* <i className='ms-3 fas fa-trash'></i> */}
                                            </td>
                                        </tr>
                                    );
                                })}
                                {vehicles.length === 0 && (
                                    <tr>
                                        <td colSpan='4' className='text-center'>
                                            {t("client_profile.no_cars")}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <p
                        className={st.add_car}
                        onClick={() => {
                            togglePopUp("showEditCar", true);
                            setSelectedCar({});
                        }}
                    >
                        <i className='fas fa-plus'></i>
                        {t("client_profile.add_car")}
                    </p>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-md-6'>
                    <button
                        className='grey_btn_h mt-3 ms-0 inline'
                        onClick={() => {
                            togglePopUp("showProfile", false);
                            togglePopUp("showChangePassword", true);
                        }}
                    >
                        {t("client_profile.change_pass")}
                    </button>
                </div>
                <div className='col-md-6'>
                    <Link
                        to='book_section'
                        spy={true}
                        smooth={true}
                        duration={500}
                        className='green_btn_h mt-3 ms-0 inline'
                        onClick={() => {
                            togglePopUp("showProfile", false);
                            dispatch({
                                type: "RESET_BOOKING_DATA",
                                payload: "",
                            });
                        }}
                    >
                        {t("client_profile.аdd_reservation")}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ProfilePreview;
