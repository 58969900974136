import React from "react";
import { useTranslation } from "react-i18next";

import "../../../../languages/i18nextConf";

import paf_logo_l from "../../../../images/home/paf_logo_l.png";

import Logic from "./Logic";

import st from "./styles.module.scss";

const Index = () => {
    const { t } = useTranslation();

    const { parkingLots } = Logic();

    return (
        <div className={st.parking_section}>
            <div className='container'>
                <div className='row'>
                    <div className=' col-7'>
                        <div className={st.text_wrapper}>
                            <h4 className={st.title}>{t("parking_section.title")}</h4>
                            <p className={st.text}>
                                <b>{t("parking_section.text_1")}</b>
                                {t("parking_section.text_2")}
                            </p>
                            <a
                                target='_blank'
                                href='https://lowcostpark.ch/'
                                className='grey_btn_h'
                            >
                                {t("parking_section.link")}
                            </a>
                        </div>
                    </div>
                    <div className='col-5'>
                        <div className={st.map_wrapper}>
                            <div
                                className={st.map}
                                onClick={() => {
                                    window.open(
                                        "https://www.google.com/maps/place/Park%26Fly+Geneva+-+Parking+Gen%C3%A8ve+A%C3%A9roport/@46.2232626,6.0971104,17z/data=!3m1!4b1!4m5!3m4!1s0x478c63c8779cabe5:0x55bf6176ced476a7!8m2!3d46.2232626!4d6.0992991?hl=en",
                                        "_blank"
                                    );
                                }}
                                target='_blank'
                            ></div>
                            <div className={st.info}>
                                <div className={st.info_text}>
                                    {/* {parkingLots < 50 && ( */}
                                    <>
                                        <p>
                                            {parkingLots} {t("parking_section.slots")}
                                        </p>
                                    </>
                                    {/* )} */}
                                </div>
                                <img src={paf_logo_l} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
