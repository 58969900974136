import { useSelector } from "react-redux";
import Dispatcher from "../_dispatcher";
const Logic = () => {
    const parkingLots = useSelector((state) => state.homePageReducer.parkingLots);
    const { resetBookingForm } = Dispatcher();

    return { parkingLots, resetBookingForm };
};

export default Logic;
