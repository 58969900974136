// profileInfo

const initialState = {
    profile: {},
    permitions: [],
};
const arrangePermissions = (data) => {
    const arranged = data.map((item) => {
        return { [item.name]: item.id };
    });

    return arranged.reduce(function (result, item) {
        var key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
    }, {});
};
const authReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SET_PROFILE_SETUP":
            const { roles = [] } = payload || {};
            const { permissions } = roles[0] || {};

            return {
                profile: payload,
                permitions: arrangePermissions(permissions),
                role: roles[0].id,
            };

        default:
            return state;
    }
};

export default authReducer;
