// employeesList

const initialState = {
    items: [],
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "GET_EMPLOYEES_LIST":
            const employeesList = payload.map((item) => {
                const role = item.roles[0] || {};
                return {
                    id: item.id,
                    first_name: item.first_name,
                    last_name: item.last_name,
                    email: item.email,
                    phone: item.phone,
                    adress: item.city + " " + item.street + " " + item.street_number,
                    role: role.name,
                    reservations: item.reservations,
                    additional_hours: item.additional_hours,
                };
            });
            return {
                ...state,
                items: employeesList,
            };
        default:
            return state;
    }
};

export default reducer;
