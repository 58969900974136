import React from 'react'; 
import{Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'; 
import MainRoute from './routing/MainRoute'
import store from './_redux/configStore' 



import './App.scss'

function App() {

    const [maintenanceMode, setMaintenanceMode] = React.useState(false)

    // const IPS = ['82.67.53.41'];

    // fetch('https://api.ipify.org?format=json')
    //     .then(response => response.json())
    //     .then(data => {
    //         if(IPS.indexOf(data.ip) != -1)
    //         {
    //             setMaintenanceMode(false);
    //         }
    //     })
    //     .catch(error => {
    //         console.log('Error:', error);
    //     });

    if(maintenanceMode)
    {
        return (
            <div>
                <div>
                    <h1>Maintenance en cours</h1>
                    <p>ParkFly Geneva est actuellement en maintenance. Nous serons de retour très rapidement. Merci pour votre patience.</p>
                    <p>Pour toute question veuillez contacter Damien au +41782505507</p>
                </div>
            </div>
        )
    }

    return (
        <Provider store={store}> 
            <BrowserRouter>
                <MainRoute/>
            </BrowserRouter> 
        </Provider>
    );
}

export default App;
