import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useSelector } from "react-redux";

import PreviewLogic from "./PreviewLogic";

import ClosePopup from "../../../../images/icons/close-popup-icon.svg";

import st from "./styles.module.scss";

const Preview = () => {
    const { t } = useTranslation();
    const userPermitions = useSelector((state) => state.dashboardReducer.permitions);

    const show = useSelector((state) => state.singlePromocode.showPreview);
    const promocode = useSelector((state) => state.singlePromocode.promocode);

    const { openPromocodePreview, openPromocodeEdit } = PreviewLogic();

    const { id, code, type, value, active, number_of_uses, from_date, to_date } = promocode;
    return (
        <>
            <div className={"pop " + (show ? "show" : "")}>
                <div className={st.inner + " inner pb-5"}>
                    <img
                        className={st.close}
                        src={ClosePopup}
                        onClick={() => openPromocodePreview(false)}
                    />

                    <h3>
                        {t("promocodes.preview.title")} - {id}
                    </h3>
                    <div className={st.summary}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h3 className={st.sub_title}> {t("promocodes.preview.sub_title")}</h3>
                                <table className={st.personal_table}>
                                    <tbody>
                                        <tr>
                                            <td>{t("promocodes.preview.code")}</td>
                                            <td>{code}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("promocodes.preview.type")}</td>
                                            <td>{type === 1 ? "Percent" : "Fixed"}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("promocodes.preview.value")}</td>
                                            <td>{value}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("promocodes.preview.active")}</td>
                                            <td>{active === 1 ? "Active" : "Not active"}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("promocodes.preview.number_of_uses")}</td>
                                            <td>{number_of_uses}</td>
                                        </tr>

                                        <tr>
                                            <td>{t("promocodes.preview.from_date")} </td>
                                            <td>{from_date}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("promocodes.preview.to_date")} </td>
                                            <td>{to_date}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {userPermitions["update_promo_code"] && (
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className={st.buttons_wrapper}>
                                        <button
                                            onClick={() => openPromocodeEdit(id)}
                                            type='button'
                                            className={st.btn + " btn btn-submit"}
                                        >
                                            {t("promocodes.preview.edit_btn")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Preview;
