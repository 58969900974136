import React from "react";

import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";

import Dates from "./Dates/Dates";

import Header from "../../../layout/Header";
import SideBar from "./SideBar";
import ClientType from "./ClientType";
import RegisterUser from "./RegisterUser";
import ArrowBack from "../../../images/icons/arrowBack.svg";
import StripeWrapper from "./PaymentStripe/StripeWrapper";
import PaymentSuccess from "./PaymentSuccess";
import TopNavigation from "./TopNavigation";

import Logic from "./Logic";

import st from "./styles.module.scss";

const Booking = () => {
    const { t } = useTranslation();

    const { active_step, isAuth } = Logic();
    // const { isAuth } = Logic();
    // const active_step = 2.4;
    return (
        <div className={st.booking_page_wrapper}>
            <Header />
            <div className={st.container + " container"}>
                <div className='row'>
                    <div className='col-md-12'>
                        <a href='/' className={st.arrow_back_btn}>
                            <img src={ArrowBack} alt='' />
                            Back
                        </a>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className={st.book_form}>
                            {active_step < 4 && (
                                <>
                                    <div className={st.main_wrapper}>
                                        <TopNavigation />
                                        <div
                                            style={{
                                                display: active_step === 1 ? "block" : "none",
                                            }}
                                        >
                                            <Dates />
                                        </div>
                                        <div
                                            style={{
                                                display:
                                                    active_step === 2.2 && !isAuth
                                                        ? "block"
                                                        : "none",
                                            }}
                                        >
                                            <ClientType />
                                        </div>
                                        <div
                                            style={{
                                                display:
                                                    active_step === 2.4 && !isAuth
                                                        ? "block"
                                                        : "none",
                                            }}
                                        >
                                            <RegisterUser />
                                        </div>

                                        <div
                                            style={{
                                                display:
                                                    active_step === 2.9 && isAuth
                                                        ? "block"
                                                        : "none",
                                            }}
                                        >
                                            <StripeWrapper />
                                        </div>
                                    </div>
                                    <SideBar />
                                </>
                            )}

                            {active_step === 4 && isAuth && <PaymentSuccess />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Booking;
