// auth

const initialState = {
    token: ("; " + document.cookie).split("; accessToken=").pop().split(";").shift(),
    refresh_token: ("; " + document.cookie).split("; refreshToken=").pop().split(";").shift(),
    profileSetup: ("; " + document.cookie).split("; profileSetup=").pop().split(";").shift(),
};

const delete_cookie = (name) => {
    document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=." + document.domain;
};

const authReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "LOGIN_SUCCESS":
            document.cookie =
                "accessToken=" + payload.access_token + "; Path=/; Domain=." + document.domain;
            document.cookie =
                "refreshToken=" + payload.refresh_token + "; Path=/; Domain=." + document.domain;

            return {
                ...state,
                token: payload.access_token,
                refresh_token: payload.refresh_token,
            };
        case "LOGIN_PROFILE_SETUP":
            document.cookie =
                "accessToken=" + payload.access_token + "; Path=/; Domain=." + document.domain;
            document.cookie =
                "refreshToken=" + payload.refresh_token + "; Path=/; Domain=." + document.domain;
            document.cookie = "profileSetup=true; Path=/; Domain=." + document.domain;

            return {
                ...state,
                token: payload.access_token,
                refresh_token: payload.refresh_token,
                profileSetup: true,
            };
        case "LOGOUT":
            delete_cookie("accessToken");
            delete_cookie("refreshToken");
            delete_cookie("profileSetup");
            return {
                token: null,
                refresh_token: null,
                profileSetup: null,
            };
        case "EXIT_PROFILE_SETUP":
            delete_cookie("profileSetup");
            return {
                ...state,
                profileSetup: null,
            };
        default:
            return state;
    }
};

export default authReducer;
