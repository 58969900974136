import { useState } from "react";
import { useDispatch } from "react-redux";

import dispatcher from "../../_dispatcher";
import { login } from "../../_actions";

const LoginClientLogic = () => {
    const { setBookingScreen, toggleHomePopUps } = dispatcher();
    const dispatch = useDispatch();

    const [loading, setLoader] = useState(false);

    const [passType, setPassType] = useState(false);

    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);

        login(data, dispatch).then((response = {}) => {
            setLoader(false);
            if (response.status === 200) {
                setBookingScreen("active_step", 2.9);
            }

            if (response.data.errors) {
                setErrors(response.data.errors);
                toggleHomePopUps("showErrorEmail", true);
            }
        });
    };

    return {
        onSubmit,
        passType,
        setPassType,
        loading,
    };
};

export default LoginClientLogic;
