import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";
import { Link as ScrollLink } from "react-scroll";
import CkeckIcon from "../../../../images/home/ckeck.png";

import Logic from "./Logic";

import st from "./styles.module.scss";

const Index = () => {
    const { t } = useTranslation();

    const { lang } = Logic();

    return (
        <>
            <div className={st.trust_us_section}>
                <div className={st.image_holder}></div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className={st.bg}>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className={st.text_wrapper}>
                                            <h4 className={st.title}>
                                                {t("trust_us_section.title")}
                                            </h4>
                                            <h4 className={st.sub_title}>
                                                {t("trust_us_section.sub_title")}
                                            </h4>

                                            <p className={st.text}>{t("trust_us_section.text")}</p>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className={st.list}>
                                            <p>
                                                <div className={st.check_icon}>
                                                    <img src={CkeckIcon} alt='' />
                                                </div>
                                                {t("trust_us_section.list_1")}
                                            </p>
                                            <p className={st.with_link}>
                                                <div className={st.check_icon}>
                                                    <img src={CkeckIcon} alt='' />
                                                </div>
                                                <span>
                                                    100 {t("trust_us_section.list_2")}
                                                    {lang === "en" ? (
                                                        <a
                                                            target='_blank'
                                                            href='https://www.google.com/maps/place/Park%26Fly+Geneva+-+Parking+Gen%C3%A8ve+A%C3%A9roport/@46.2232626,6.0971104,17z/data=!3m1!4b1!4m5!3m4!1s0x478c63c8779cabe5:0x55bf6176ced476a7!8m2!3d46.2232626!4d6.0992991?hl=en'
                                                        >
                                                            {t("trust_us_section.list_2_link")}
                                                        </a>
                                                    ) : (
                                                        <a
                                                            target='_blank'
                                                            href='https://www.google.com/maps/place/Park%26Fly+Geneva+-+Parking+Gen%C3%A8ve+A%C3%A9roport/@46.2232626,6.0971104,17z/data=!3m1!4b1!4m5!3m4!1s0x478c63c8779cabe5:0x55bf6176ced476a7!8m2!3d46.2232626!4d6.0992991?hl=fr'
                                                        >
                                                            {t("trust_us_section.list_2_link")}
                                                        </a>
                                                    )}
                                                    {/* <ScrollLink
                                                        to='pricing'
                                                        spy={true}
                                                        smooth={true}
                                                        duration={500}
                                                    >
                                                        {t("trust_us_section.list_2_link")}
                                                    </ScrollLink> */}
                                                </span>
                                            </p>
                                            <p>
                                                <div className={st.check_icon}>
                                                    <img src={CkeckIcon} alt='' />
                                                </div>
                                                10 {t("trust_us_section.list_3")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
