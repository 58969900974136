import React from "react";

import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";
import { Link } from "react-router-dom";

import st from "./styles.module.scss";

import Logic from "./Logic";

const PriceCalculator = ({ className }) => {
    const { t } = useTranslation();

    const { value, resetBookingForm, days, setDaysFunc } = Logic();

    return (
        <>
            <div className={st.price_calc_wrapper + " " + className}>
                <div className={st.inner}>
                    <div className={st.form_wrapper}>
                        <div className='client_forms'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <label>{t("client_booking.days_to_stay")} </label>
                                    <input
                                        name='days'
                                        value={days}
                                        onChange={(e) => {
                                            setDaysFunc(e.target.value);
                                        }}
                                        className={st.calcInput}
                                        type='number'
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <div className={st.priceResult}>
                                        <div className={st.val}>
                                            <div className={st.small}>
                                                {t("client_booking.price_label")}
                                            </div>

                                            {value}
                                        </div>
                                        <span> CHF</span>
                                    </div>
                                </div>
                            </div>
                            <div className={st.btn_wrapper}>
                                <Link
                                    className='green_btn_h'
                                    to='reservation'
                                    onClick={() => {
                                        resetBookingForm();
                                    }}
                                >
                                    {t("book_btn")}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PriceCalculator;
