import { combineReducers } from "redux";

import dashboardReducer from "../pages/Dashboard/_reducer";
import confirmDelete from "../components/ConfirmDelete/_reducer";
import listTableReducer from "../components/ListTable/_reducer";

import pageLoader from "../components/PageLoader/_reducer";
import alertMessage from "../components/AlertMessage/_reducer";
import rangeDatePicker from "../components/DatePickerRange/_reducer";
import timePicker from "../components/TimePicker/_reducer";
import timePickerMobile from "../components/TimePickerMobile/_reducer";

import auth from "../pages/Public/Login/_reducer";
import profileInfo from "../pages/Public/ProfileSetup/_reducer";

import inviteEmployee from "../pages/Dashboard/Employees/InviteEmployee/_reducer";
import singleEmployee from "../pages/Dashboard/Employees/Single/_reducer";
import employeesList from "../pages/Dashboard/Employees/List/_reducer";

import vacationDays from "../pages/Dashboard/VacationsDays/Calendar/_reducer";
import employeesFreeDays from "../pages/Dashboard/VacationsDays/AddFreeDays/_reducer";
import freeDaysForApproval from "../pages/Dashboard/VacationsDays/ForApproval/_reducer";

import clientsList from "../pages/Dashboard/Clients/List/_reducer";
import singleClient from "../pages/Dashboard/Clients/Single/_reducer";
import reservationsListClient from "../pages/Dashboard/Clients/Single/Reservations/_reducer";
import addReservationClient from "../pages/Dashboard/Clients/Single/ReservationsAdd/_reducer";

import reservationsList from "../pages/Dashboard/Reservations/List/_reducer";
import singleReservation from "../pages/Dashboard/Reservations/Single/_reducer";

import singleParking from "../pages/Dashboard/Parkings/Single/_reducer";
import parkingsList from "../pages/Dashboard/Parkings/List/_reducer";

import singlePromocode from "../pages/Dashboard/Promocodes/Single/_reducer";
import promocodesList from "../pages/Dashboard/Promocodes/List/_reducer";

import pricesList from "../pages/Dashboard/Prices/_reducer";

import reservationCalendar from "../pages/Dashboard/Calendar/_reducer";
import clientAuth from "../pages/Public/ClientAuth/_reducer";
import clientProfile from "../pages/Public/ClientProfile/_reducer";
import homePageReducer from "../pages/Public/Home/_reducer";
import clientBooking from "../pages/Public/ClientBooking/_reducer";

import statisticsReducer from "../pages/Dashboard/Statistics/_reducer";

export default combineReducers({
    clientAuth: clientAuth,
    dashboardReducer: dashboardReducer,
    clientProfile: clientProfile,
    homePageReducer: homePageReducer,
    clientBooking: clientBooking,
    auth: auth,
    alertMessage: alertMessage,
    rangeDatePicker: rangeDatePicker,
    timePicker: timePicker,
    timePickerMobile: timePickerMobile,
    confirmDelete: confirmDelete,
    profileInfo: profileInfo,
    listTableReducer: listTableReducer,
    pageLoader: pageLoader,
    employeesList: employeesList,
    singleEmployee: singleEmployee,
    inviteEmployee: inviteEmployee,
    vacationDays: vacationDays,
    employeesFreeDays: employeesFreeDays,
    freeDaysForApproval: freeDaysForApproval,
    clientsList: clientsList,
    singleClient: singleClient,
    reservationsListClient: reservationsListClient,
    addReservationClient: addReservationClient,
    reservationsList: reservationsList,
    singleReservation: singleReservation,
    singleParking: singleParking,
    parkingsList: parkingsList,
    singlePromocode: singlePromocode,
    promocodesList: promocodesList,
    pricesList: pricesList,
    reservationCalendar: reservationCalendar,
    statisticsReducer: statisticsReducer,
});
