import React from "react";

import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import st from "./styles.module.scss";

import LoginForm from "./LoginForm";
import dispatcher from "../_dispatcher";

const ClientType = () => {
    const { t } = useTranslation();

    const { setBookingScreen, toggleHomePopUps } = dispatcher();

    return (
        <>
            <div className={st.client_type}>
                <h4>{t("client_booking.step_2.login")} </h4>

                <div className={st.client_type_inner}>
                    <div className={st.login_wrapp}>
                        <p className={st.sub_title}>{t("client_booking.step_2.login_title")}</p>
                        <LoginForm />

                        <p className={st.forgot_pass_link}>
                            {t("client_booking.step_2.forgot_pass")}{" "}
                            <span
                                onClick={() => {
                                    toggleHomePopUps("showForgotPassword", true);
                                }}
                                className='link'
                            >
                                {t("client_booking.step_2.forgot_pass_link")}
                            </span>
                        </p>
                    </div>
                    <div className={st.register_wrapp}>
                        <p className={st.sub_title}> {t("client_booking.step_2.reg_title")}</p>
                        <div>
                            <h5 className={st.title}>
                                {t("client_booking.step_2.reg_title_main")}
                            </h5>
                            <p className={st.text}>{t("client_booking.step_2.reg_text")}</p>
                        </div>

                        <button
                            className={st.reg_btn}
                            onClick={() => setBookingScreen("active_step", 2.4)}
                        >
                            {t("client_booking.step_2.reg_link")}
                        </button>
                    </div>
                </div>

                <div className='bottom_links_wrapper'>
                    <button
                        type='button'
                        className='btn_back'
                        onClick={() => setBookingScreen("active_step", 1)}
                    >
                        {t("client_booking.back")}
                    </button>
                </div>
            </div>
        </>
    );
};

export default ClientType;
