import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import Logic from "./Logic";

import st from "./styles.module.scss";
import history_1 from "../../../../images/home_icons/history_1.svg";
import history_2 from "../../../../images/home_icons/history_2.svg";
import history_3 from "../../../../images/home_icons/history_3.svg";
const Index = () => {
    const { t } = useTranslation();

    const {} = Logic();

    return (
        <>
            <div className={st.history_wrapper}>
                <div className={st.image_holder}></div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className={st.bg}>
                                <h4 className={st.title}>
                                    <b> {t("history_section.title_1")} </b>
                                    {t("history_section.title_2")}
                                </h4>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <div className={st.single}>
                                            <div className={st.circle}>
                                                <img src={history_1} alt='' />
                                            </div>
                                            <p className={st.text}>{t("history_section.text_1")}</p>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className={st.single}>
                                            <div className={st.circle}>
                                                <img src={history_2} alt='' />
                                            </div>
                                            <p className={st.text}>{t("history_section.text_2")}</p>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className={st.single}>
                                            <div className={st.circle}>
                                                <img src={history_3} alt='' />
                                            </div>
                                            <p className={st.text}>{t("history_section.text_3")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
