const initialState = {
    show: false,
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SHOW_INVITE_EMPLOYEE_POPUP":
            return {
                ...state,
                show: payload,
            };
        default:
            return state;
    }
};

export default reducer;
