import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useSelector } from "react-redux";

import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Switch,
  FormControl,
  FormLabel,
  FormControlLabel,
  Box,
} from "@material-ui/core";

import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/en-gb";

import ButtonLoader from "../../../../components/ButtonLoader/ButtonLoader";
import ClosePopup from "../../../../images/icons/close-popup-icon.svg";

import EditPromocodesSchema from "../../../../_validations/EditPromocodesSchema";

import EditLogic from "./EditLogic";

import st from "./styles.module.scss";

const Edit = () => {
  const { t } = useTranslation();
  const show = useSelector((state) => state.singlePromocode.showEdit);

  const {
    openPromocodeEdit,
    state,
    onSubmit,
    loading,
    promocodeId,
    clearForm,
    active,
    toggleActive,
    type,
    toggleType,
    switchState,
    handleChange
  } = EditLogic();

  return (
    <>
      <div className={"pop  " + (show ? "show" : "")}>
        <div className={st.inner + " inner"}>
          <img
            className={st.close}
            src={ClosePopup}
            onClick={() => openPromocodeEdit()}
          />
          <div className={st.summary}>
            <Formik
              enableReinitialize
              initialValues={state}
              validationSchema={EditPromocodesSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched, isValidating, isValid, dirty }) => {
                return (
                  <Form>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={MomentUtils}
                      locale="en-gb"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <h3>
                            <strong>
                              {promocodeId
                                ? t("promocodes.single.edit_title")
                                : t("promocodes.single.create_title")}
                            </strong>
                          </h3>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-border mb-3">
                            <Field
                              name="code"
                              type="text"
                              label={t("promocodes.single.code")}
                              component={TextField}
                            ></Field>
                          </div>
                          <div>
                            <Field
                              autoOk={true}
                              showTodayButton={true}
                              format="DD.MM.YYYY"
                              name="from_date"
                              type="text"
                              label={t("promocodes.single.from_date")}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <i className="far fa-calendar-alt"></i>
                                  </InputAdornment>
                                ),
                              }}
                              className="full-width date-input mb-3"
                              InputLabelProps={{ shrink: true }}
                              component={DatePicker}
                              inputVariant="outlined"
                            ></Field>
                          </div>
                          <div className="col-md-6">
                            <label><b>Discount Type:</b></label>
                            <Box display="flex" alignItems="center">
                              <FormLabel component="legend" style={{marginBottom: '-5px'}}>
                                Fixed
                              </FormLabel>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={switchState.isChecked}
                                    onChange={handleChange}
                                    color="primary"
                                    name="isChecked"
                                  />
                                }
                                label="Percent"
                                labelPlacement="end"
                              />
                            </Box>
                          </div>
                          <div className="input-border mb-3">
                            <Field
                              name="value"
                              type="number"
                              label={t("promocodes.single.value")}
                              component={TextField}
                            ></Field>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-border mb-3">
                            <Field
                              name="number_of_uses"
                              type="number"
                              label={t("promocodes.single.number_of_uses")}
                              component={TextField}
                            ></Field>
                          </div>
                          <div className="mb-3">
                            <Field
                              autoOk={true}
                              showTodayButton={true}
                              format="DD.MM.YYYY"
                              name="to_date"
                              type="text"
                              label={t("promocodes.single.to_date")}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <i className="far fa-calendar-alt"></i>
                                  </InputAdornment>
                                ),
                              }}
                              className="full-width date-input mb-3"
                              InputLabelProps={{ shrink: true }}
                              component={DatePicker}
                              inputVariant="outlined"
                            ></Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="client_checkbox mb-3">
                              <Checkbox
                                checked={active}
                                onChange={() => toggleActive(!active)}
                                name="active"
                                color="primary"
                              />
                              {t("promocodes.single.active")}{" "}
                            </div>
                          </div>
                          {/* <div className="col-md-4">
                            <div className="mb-3">
                              <div className="client_checkbox mb-3">
                                <Checkbox
                                  checked={type}
                                  onChange={() => toggleType(!type)}
                                  name="type"
                                  color="primary"
                                />
                                {t("promocodes.single.type")}{" "}
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </MuiPickersUtilsProvider>
                    <button
                      disabled={loading}
                      type="submit"
                      className="btn btn-submit me-2"
                    >
                      {t("promocodes.single.submit_btn")}
                    </button>
                    {!promocodeId && (
                      <button
                        type="button"
                        onClick={clearForm}
                        className="btn simple-btn"
                      >
                        {t("promocodes.single.clear_btn")}
                      </button>
                    )}

                    <ButtonLoader show={loading} />
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
