import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";
import { Link } from "react-router-dom";

import Star from "../../../../images/home/star_b.png";
import logo_g from "../../../../images/home/logo_g.png";

import Logic from "./Logic";

import st from "./styles.module.scss";

const Index = () => {
    const { t } = useTranslation();

    const {} = Logic();

    return (
        <div className={st.google_baner}>
            <div className={st.text_wrapper}>
                <h3 className={st.title}>{t("google_baner.title")}</h3>
                <p className={st.text}>{t("google_baner.info")}</p>
                <a
                    target='_blank'
                    href='https://www.google.com/search?q=park%20fly%20geneva&rlz=1C1CHBF_enBG814BG814&sxsrf=AOaemvIUvX_Iettwxi7N3nCWrM-eTlWMbg:1641315564120&ei=5HzUYZjbDOqKxc8PpM2A0AU&ved=2ahUKEwihh6f5yJj1AhUQQfEDHeghApIQvS56BAgwEBs&uact=5&oq=park+fly+geneva&gs_lcp=Cgdnd3Mtd2l6EAMyBAgjECcyBQgAEIAEMgYIABAWEB4yBggAEBYQHjIICAAQFhAKEB46BwgAEEcQsAM6CggAEEcQsAMQyQNKBAhBGABKBAhGGABQnwdYxghg_wloAXACeACAAWmIAc4BkgEDMC4ymAEAoAEByAEIwAEB&sclient=gws-wiz&tbs=lf:1,lf_ui:3&tbm=lcl&rflfq=1&num=10&rldimm=6178764376679675559&lqi=Cg9wYXJrIGZseSBnZW5ldmFIlbuWsOutgIAIWh0QABABEAIYABgBGAIiD3BhcmsgZmx5IGdlbmV2YZIBFHB1YmxpY19wYXJraW5nX3NwYWNl&rlst=f#rlfi=hd:;si:6178764376679675559;mv:[[46.22620123169867,6.108135494210818],[46.22173279592099,6.095475467660525],null,[46.22396705927293,6.101805480935671],17]'
                >
                    {t("google_baner.link")}{" "}
                </a>
            </div>
            <div className={st.score}>
                <div className={st.val_wrapper}>
                    <p className={st.val}>5 </p>
                    <div className={st.starts}>
                        <img src={Star} alt='' />
                        <img src={Star} alt='' />
                        <img src={Star} alt='' />
                        <img src={Star} alt='' />
                        <img src={Star} alt='' />
                    </div>
                    <p className={st.reviews}>75 {t("google_baner.reviews")} </p>
                </div>
                <img className={st.g_logo} src={logo_g} alt='' />
            </div>
        </div>
    );
};

export default Index;
