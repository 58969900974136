// confirmDelete

const initialState = {
    show: false,
    text: "",
    location: "",
    id: "",
    confirm: false,
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "SET_DELETE_CONFIRM":
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export default reducer;
