import axios from "axios";
import { apiUrl } from "./helpers/apiUrl";

const instance = axios.create({
    baseURL: apiUrl,
    validateStatus: function (status) {
        return status >= 200 && status <= 302;
    },
});

instance.interceptors.request.use(
    function (config) {
        config.headers["Accept"] = "application/json";
        const token = ("; " + document.cookie).split("; accessToken=").pop().split(";").shift();

        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

function refreshToken() {
    return instance.post(apiUrl + "refresh", {
        refresh_token: ("; " + document.cookie).split("; refreshToken=").pop().split(";").shift(),
    });
}

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (err.response) {
            // Access Token was expired

            if (
                window.location.pathname !== "/" &&
                err.response.status === 401 &&
                !originalConfig._retry
            ) {
                originalConfig._retry = true;
                try {
                    const rs = await refreshToken();
                    const { access_token } = rs.data.data;

                    document.cookie =
                        "accessToken=" + access_token + "; Path=/; Domain=." + document.domain;

                    instance.defaults.headers.common["Authorization"] = "Bearer " + access_token;

                    return instance(originalConfig);
                } catch (_error) {
                    if (_error.response && _error.response.data) {
                        return Promise.reject(_error.response.data);
                    }

                    return Promise.reject(_error);
                }
            }

            if (err.response.status === 403 && err.response.data) {
                return Promise.reject(err.response.data);
            }
        }

        return Promise.reject(err);
    }
);

export default instance;
