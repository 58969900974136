import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import InputAdornment from "@material-ui/core/InputAdornment";
import { DatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/en-gb";

import DeleteIcon from "../../../images/icons/delete-icon.svg";

import ButtonLoader from "../../../components/ButtonLoader/ButtonLoader";
import ProfileSetupLogic from "./ProfileSetupLogic";
import profileSetupSchema from "../../../_validations/profileSetupSchema";

import st from "./style.module.scss";

const ProfileSetup = ({ history }) => {
    const { disableSubmit, onSubmit, loading, state } = ProfileSetupLogic(history);

    return (
        <div className={st["intro-setup-page"]}>
            <div className={st["setupintro-page-inner"]}>
                <h1 className={st.title}>Set up Your Profile</h1>
                <Formik
                    initialValues={state}
                    enableReinitialize
                    validationSchema={profileSetupSchema}
                    onSubmit={onSubmit}
                >
                    {({ errors, touched, isValidating, isValid, dirty }) => {
                        return (
                            <Form>
                                <h2 className={st["sub-title"]}>Personal info:</h2>
                                <div className='form-wrapper'>
                                    <div className='row '>
                                        <div className='col-md-6 pb-10'>
                                            <div className='input-border'>
                                                <Field
                                                    name='first_name'
                                                    type='text'
                                                    label='First Name:'
                                                    disabled
                                                    component={TextField}
                                                ></Field>
                                            </div>
                                        </div>
                                        <div className='col-md-6 pb-10'>
                                            <div className='input-border'>
                                                <Field
                                                    name='last_name'
                                                    type='text'
                                                    label='Last Name:'
                                                    disabled
                                                    component={TextField}
                                                ></Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row  '>
                                        <div className='col-md-6 pb-10'>
                                            <div className='input-border'>
                                                <Field
                                                    name='email'
                                                    type='text'
                                                    label='Email:'
                                                    disabled
                                                    component={TextField}
                                                ></Field>
                                            </div>
                                        </div>
                                        <div className='col-md-6 pb-10'>
                                            <div className='input-border'>
                                                <Field
                                                    name='phone'
                                                    type='text'
                                                    label='Phone number:'
                                                    component={TextField}
                                                ></Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row '>
                                        <div className='col-md-4 pb-10'>
                                            <div className='input-border'>
                                                <Field
                                                    name='district'
                                                    type='text'
                                                    label='District'
                                                    component={TextField}
                                                ></Field>
                                            </div>
                                        </div>
                                        <div className='col-md-4 pb-10'>
                                            <div className='input-border'>
                                                <Field
                                                    name='city'
                                                    type='text'
                                                    label='City'
                                                    component={TextField}
                                                ></Field>
                                            </div>
                                        </div>
                                        <div className='col-md-4 pb-10'>
                                            <div className='input-border'>
                                                <Field
                                                    name='post_code'
                                                    type='text'
                                                    label='Zip'
                                                    component={TextField}
                                                ></Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row '>
                                        <div className='col-md-8 pb-10'>
                                            <div className='input-border'>
                                                <Field
                                                    name='street'
                                                    type='text'
                                                    label='Street'
                                                    component={TextField}
                                                ></Field>
                                            </div>
                                        </div>
                                        <div className='col-md-4 pb-10'>
                                            <div className='input-border'>
                                                <Field
                                                    name='street_number'
                                                    type='text'
                                                    label='Number'
                                                    component={TextField}
                                                ></Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row '>
                                        <div className='col-md-6 pb-10'>
                                            <MuiPickersUtilsProvider
                                                libInstance={moment}
                                                utils={MomentUtils}
                                                locale='en-gb'
                                            >
                                                <Field
                                                    autoOk={true}
                                                    showTodayButton={true}
                                                    format='DD.MM.YYYY'
                                                    name='birth_date'
                                                    type='text'
                                                    label='Birth Date: *'
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='start'>
                                                                <i className='far fa-calendar-alt'></i>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    className='full-width date-input'
                                                    InputLabelProps={{ shrink: true }}
                                                    component={DatePicker}
                                                    // onChange={(e, d) =>
                                                    //     onChangeDate("birthDate", e, d)
                                                    // }
                                                    inputVariant='outlined'
                                                ></Field>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className='col-md-6 pb-10'>
                                            <div className='input-border'>
                                                <Field
                                                    name='insurance_number'
                                                    type='text'
                                                    label='Insurance number'
                                                    component={TextField}
                                                ></Field>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='text-end'>
                                        <button
                                            disabled={loading || !(isValid && dirty)}
                                            type='submit'
                                            className='btn-large btn-submit'
                                        >
                                            Finish
                                        </button>
                                        <ButtonLoader show={loading} />
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default ProfileSetup;
