import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useSelector } from "react-redux";

import PreviewLogic from "./PreviewLogic";

import ClosePopup from "../../../../images/icons/close-popup-icon.svg";

import st from "./styles.module.scss";

const Preview = () => {
    const { t } = useTranslation();
    const userPermitions = useSelector((state) => state.dashboardReducer.permitions);

    const show = useSelector((state) => state.singleParking.showPreview);
    const parking = useSelector((state) => state.singleParking.parking);

    const { openParkingPreview, openParkingEdit } = PreviewLogic();

    const { id, name, city, address, street, number, phone, lots } = parking;
    return (
        <>
            <div className={"pop " + (show ? "show" : "")}>
                <div className={st.inner + " inner pb-5"}>
                    <img
                        className={st.close}
                        src={ClosePopup}
                        onClick={() => openParkingPreview(false)}
                    />

                    <h3>
                        {t("parkings.preview.title")} - {id}
                    </h3>
                    <div className={st.summary}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h3 className={st.sub_title}> {t("parkings.preview.sub_title")}</h3>
                                <table className={st.personal_table}>
                                    <tbody>
                                        <tr>
                                            <td>{t("parkings.preview.name")}</td>
                                            <td>{name}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("parkings.preview.address")}</td>
                                            <td>{address}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("parkings.preview.city")}</td>
                                            <td>{city}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("parkings.preview.street")}</td>
                                            <td>{street}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("parkings.preview.street_number")}</td>
                                            <td>{number}</td>
                                        </tr>

                                        <tr>
                                            <td>{t("parkings.preview.phone")} </td>
                                            <td>{phone ? phone : "-"}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("parkings.preview.lots")} </td>
                                            <td>{lots ? lots : "-"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {userPermitions["update_parking"] && (
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className={st.buttons_wrapper}>
                                        <button
                                            onClick={() => openParkingEdit(id)}
                                            type='button'
                                            className={st.btn + " btn btn-submit"}
                                        >
                                            {t("parkings.preview.edit_btn")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Preview;
