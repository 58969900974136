import React from "react";

import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";
import moment from "moment";

import phonePerson from "../../../../images/home/phone_person.png";
import Logic from "./Logic";

import st from "./styles.module.scss";

const Booking = () => {
    const { t } = useTranslation();

    const { active, reservation } = Logic();
    const { departure_at, arrival_at, price, days, real_days, order_total } = reservation;

    return (
        <>
            <div className={st.side_bar + " " + st["active_step_" + active]}>
                <div className={st.current_info + " " + (departure_at ? st.expand : "")}>
                    <ul className={st.info_list}>
                        <li>
                            {t("client_booking.side_bar.duration")}
                            <span>
                                {real_days || days
                                    ? (real_days ? real_days : days) +
                                      " " +
                                      t("client_booking.side_bar.hours")
                                    : "--"}
                            </span>
                        </li>
                        <li>
                            {t("client_booking.side_bar.depart")}
                            <span>
                                {departure_at
                                    ? moment(departure_at, "YYYY-MM-DD HH:mm").format(
                                          "DD.MM.YYYY HH:mm"
                                      )
                                    : "--"}
                            </span>
                        </li>
                        <li>
                            {t("client_booking.side_bar.arival")}
                            <span>
                                {arrival_at
                                    ? moment(arrival_at, "YYYY-MM-DD HH:mm").format(
                                          "DD.MM.YYYY HH:mm"
                                      )
                                    : "--"}
                            </span>
                        </li>

                        <li>
                            {t("client_booking.side_bar.price")}
                            <span>
                                {order_total || price
                                    ? order_total
                                        ? price + " CHF"
                                        : price + " CHF"
                                    : "--"}
                            </span>
                        </li>
                    </ul>
                </div>
                <div className={st.phone}>
                    <span> {t("client_booking.side_bar.info")} </span>
                    <a href='tel:+41225101440'>+41 22 510 14 40</a>
                </div>

                {/* <button
                    disabled={disableSumbit}
                    type='button'
                    className='green_btn_h'
                    onClick={submitDates}
                >
                    {t("client_booking.next")}
                </button> */}
            </div>
        </>
    );
};

export default Booking;
