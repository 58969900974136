import React, { Children } from "react";
import { useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";

import moment from "moment";

const Label = ({ label, drilldownView, onDrillDown }) => {
    if (!drilldownView) {
        return <span>{label}</span>;
    }
    return <a onClick={onDrillDown}>{label}</a>;
};

const DateHeader = ({ date, label, drilldownView, onDrillDown }) => {
    const birthdaysList = useSelector((state) => state.vacationDays.birthdaysList);
    const calendarDate = moment(date).format("YYYY-MM-DD");
    const isDate = birthdaysList.find(
        (item) => moment(item.date).format("YYYY-MM-DD") === calendarDate
    );
    if (isDate) {
        return (
            <>
                <Tooltip
                    className='hollyday-label'
                    interactive
                    arrow
                    PopperProps={{ disablePortal: true }}
                    placement='bottom'
                    // leaveDelay={22200}
                    title={<>{isDate.name} Has birthday. Dont Forget go give him bonus :) </>}
                >
                    <p>
                        <i className='fas fa-birthday-cake'></i> {isDate.name}
                    </p>
                </Tooltip>
                <Label {...{ label, drilldownView, onDrillDown }} />
            </>
        );
    } else {
        return <Label {...{ label, drilldownView, onDrillDown }} />;
    }
};

export default DateHeader;
