import React from "react";

import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";

import { useDispatch } from "react-redux";
import thankYou_img from "../../../images/home/thankYou_img.png";
import phonePerson from "../../../images/home/phone_person.png";
import st from "./styles.module.scss";

const PaymentSuccess = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    return (
        <div className={st.final_step}>
            <div className={st.text}>
                <h5 className={st.title}>
                    {t("client_booking.thankyou.title_1")}

                    <span> {t("client_booking.thankyou.title_2")} </span>
                </h5>

                <p>{t("client_booking.thankyou.text")}</p>
            </div>
            <div className={st.image}>
                <img src={thankYou_img} alt='' />
            </div>

            <div className={st.mobile}>
                <p className={st.greetings}>{t("client_booking.thankyou.greetings")}</p>
                <div className={st.phone}>
                    <img src={phonePerson} alt='' />
                    <a href='tel:+41225101440'>+41 22 510 14 40</a>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccess;
