import React from "react";
import { Link } from "react-scroll";

import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";
import { TextField } from "formik-material-ui";
import { useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";

import changePassSchema from "../../../../_validations/changePassSchema";
import ClosePopup from "../../../../images/icons/close-popup-icon.svg";
import st from "../styles.module.scss";

import ChangePassword from "./ChangePasswordLogic";

const ResetPassword = () => {
    const { t } = useTranslation();

    const show = useSelector((state) => state.clientProfile.showChangePassword);
    // const show = true;

    const { state, togglePopUp, onSubmit, loading, passType, togglePassType } = ChangePassword();
    return (
        <div className={st.info_popup + " pop  " + (show ? "show" : "")}>
            <div className={st.inner + " " + st.change_password + " inner "}>
                <img
                    className={st.close}
                    src={ClosePopup}
                    onClick={() => {
                        togglePopUp("showChangePassword", false);
                        togglePopUp("showProfile", true);
                    }}
                />
                <div className={st.form_wrapper}>
                    <br />
                    <h2 className='text-center'>Change password</h2>
                    <Formik
                        enableReinitialize
                        initialValues={state}
                        validationSchema={changePassSchema}
                        onSubmit={onSubmit}
                    >
                        {({ isValid, dirty, errors }) => {
                            Object.keys(errors).map((item) => {
                                errors[item] = t(errors[item]);
                                return errors[item];
                            });
                            return (
                                <Form className='client_forms'>
                                    <div className='password_input_label'>
                                        <Field
                                            name='current_password'
                                            type={passType ? "text" : "password"}
                                            className='input_label mb-0'
                                            component={TextField}
                                            label='Password'
                                        ></Field>
                                        <i
                                            className='password_icon fas fa-eye'
                                            onClick={togglePassType}
                                        ></i>
                                    </div>

                                    <div className='password_input_label'>
                                        <Field
                                            name='password'
                                            type={passType ? "text" : "password"}
                                            className='input_label'
                                            label='New Password'
                                            component={TextField}
                                        ></Field>
                                        <i
                                            className='password_icon fas fa-eye'
                                            onClick={togglePassType}
                                        ></i>
                                    </div>

                                    <div className='password_input_label'>
                                        <Field
                                            name='confirm_password'
                                            type={passType ? "text" : "password"}
                                            className='input_label'
                                            label='Confirm New Password'
                                            component={TextField}
                                        ></Field>
                                        <i
                                            className='password_icon fas fa-eye'
                                            onClick={togglePassType}
                                        ></i>
                                    </div>
                                    <br />
                                    <button
                                        disabled={loading || !(isValid && dirty)}
                                        type='submit'
                                        className='green_btn_h'
                                        style={{ width: "100%" }}
                                    >
                                        Update password
                                    </button>
                                    <br />
                                    <br />
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
