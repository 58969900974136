import React, {useEffect} from "react";
import moment from "moment-timezone";
import KucHelper from "../../../helpers/KucHelper";
import KucIcon from "../../../images/kuc_icon.png";

const CustomEvent = (event) => {
    const {type, id, payment_status, departure_valet, arrival_valet} = event.event;

    useEffect(() => {
        console.log("event", event);
    }, [event]);

    return (
        <div style={{color: event.color}}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                {/* {type === "start" ? (
                    <i className='fas fa-arrow-right'></i>
                ) : (
                    <i className='fas fa-arrow-up'></i>
                )} */}
                <span style={{marginRight: "10px", display: "flex", alignItems: "center"}}>
                    {id}
                    {KucHelper.isKucReservation(event.event) ?
                        <span>
                            <img src={KucIcon} alt='Kuc' style={{width: "20px", height: "20px", margin:"2px"}}/>
                        </span> : null
                    }
                    {type === "start" && !Boolean(departure_valet) && (
                        <i
                            style={{
                                fontSize: "10px",
                                margin: "0 10px",
                            }}
                            className='fas fa-user-slash'
                        ></i>
                    )}
                    {type === "end" && !Boolean(arrival_valet) && (
                        <i
                            style={{
                                fontSize: "10px",
                                margin: "0 10px",
                            }}
                            className='fas fa-user-slash'
                        ></i>
                    )}
                </span>
                {payment_status === "pending" && (
                    <span style={{marginRight: "10px"}}>
                        <i className='fas fa-exclamation-triangle'></i>
                    </span>
                )}

                {event.title}
            </div>
        </div>
    );
};

export default CustomEvent;
