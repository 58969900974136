import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import { getAllClients, createReservation } from "./_actions";
import { getReservationsList } from "../List/_actions";
import { getParkingsList } from "../../Parkings/List/_actions";

const EditLogic = () => {
    const dispatch = useDispatch();
    const allClinets = useSelector((state) => state.singleReservation.allClinets);
    const selectedClient = useSelector((state) => state.singleReservation.client_id);
    const parkingsList = useSelector((state) => state.parkingsList.items);
    const [loading, setLoader] = useState(false);

    const initialState = {
        id: "",
        client_id: "",
        vehicle_id: "",
        parking_id: "",
        departure_at: null,
        arrival_at: null,
        departure_time: null,
        arrival_time: null,
        note: "",
        flight_number: "",
        promocode: ""
    };

    const [clients_list, setClientList] = useState([]);
    const [cars_list, setCarsList] = useState([]);
    const [client_data, setClientData] = useState({
        name: "",
        id: "",
    });

    useEffect(() => {
        getAllClients(dispatch);
        getParkingsList("", dispatch);
    }, []);
    useEffect(() => {
        if (selectedClient && clients_list.length > 0) {
            const selected = clients_list.filter((item) => item.id == selectedClient)[0];
            setClientData({
                id: selectedClient,
                name: selected.name,
            });
        }
    }, [selectedClient, clients_list]);

    const handleClientChange = (event, value, type) => {
        if (value) {
            const selected = clients_list.filter((item) => item.id == value.id)[0];

            if (selected.vehicles.length > 0) {
                setCarsList(selected.vehicles);
            }

            setClientData({
                id: value.id,
                name: selected.name,
            });
        } else {
            setCarsList([]);
            setClientData({
                id: "",
                name: "",
            });
        }
    };

    useEffect(() => {
        if (allClinets.length > 0) {
            setClientList(allClinets);
        }
    }, [allClinets]);

    const [state, setState] = useState(initialState);

    const [daysDuration, setDaysDuration] = useState(0);

    useEffect(() => {
        const { departure_at, arrival_at } = state;
        if (departure_at && arrival_at) {
            const startR = moment(departure_at, "DD-MM-YYYY").format("YYYY-MM-DD");
            const endR = moment(arrival_at, "DD-MM-YYYY").format("YYYY-MM-DD");
            const startM = moment(startR, "YYYY-MM-DD");
            const endM = moment(endR, "YYYY-MM-DD");
            if (moment.duration(endM.diff(startM)).asDays() === 0) {
                setDaysDuration(1);
            } else {
                setDaysDuration(moment.duration(endM.diff(startM)).asDays() + 1);
            }
        }
    }, [state]);

    const openReservationEdit = (id) => {
        dispatch({
            type: "SHOW_EDIT_RESERVATION_POPUP",
            payload: { id, status: false, client_id: null },
        });
        setState(initialState);
    };

    const querySting = useSelector((state) => state.listTableReducer.query);

    const onSubmit = async (data, { setErrors }) => {
        setLoader(true);
        const { id, departure_at, departure_time, arrival_at, arrival_time, ...rest } = data;

        const moderated = {
            ...rest,
            client_id: client_data.id,
            departure_at:
                moment(departure_at).format("YYYY-MM-DD") +
                " " +
                moment(departure_time).format("HH:mm:ss"),
            arrival_at:
                moment(arrival_at).format("YYYY-MM-DD") +
                " " +
                moment(arrival_time).format("HH:mm:ss"),
        };

        createReservation(moderated, dispatch).then((response = {}) => {
            setLoader(false);
            if (response.status === 201) {
                getReservationsList(querySting, dispatch);
                dispatch({
                    type: "SHOW_EDIT_RESERVATION_POPUP",
                    payload: { id: data.id, status: false, client_id: null },
                });
            }

            if (response.errors) {
                let moderatedErrors = { error: "" };
                for (const property in response.errors) {
                    moderatedErrors = {
                        ...moderatedErrors,
                        [property]: response.errors[property].toString(),
                    };
                }

                setErrors(response.errors);
            }
        });
    };

    return {
        openReservationEdit,
        state,
        onSubmit,
        loading,
        daysDuration,
        handleClientChange,
        client_data,
        clients_list,
        parkingsList,
        selectedClient,
        cars_list,
    };
};

export default EditLogic;
