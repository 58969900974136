import i18n from "../../../../languages/i18nextConf";

export const faqList = () => {
    return [
        {
            id: 1,
            open: true,
            title: i18n.t("faq_section.title_1"),
            content: i18n.t("faq_section.text_1"),
        },
        {
            id: 2,
            open: false,
            title: i18n.t("faq_section.title_2"),
            content: i18n.t("faq_section.text_2"),
        },
        {
            id: 3,
            open: false,
            title: i18n.t("faq_section.title_3"),
            content: i18n.t("faq_section.text_3"),
        },
        {
            id: 4,
            open: false,
            title: i18n.t("faq_section.title_4"),
            content: i18n.t("faq_section.text_4"),
        },
        {
            id: 5,
            open: false,
            title: i18n.t("faq_section.title_5"),
            content: i18n.t("faq_section.text_5"),
        },
        {
            id: 6,
            open: false,
            title: i18n.t("faq_section.title_6"),
            content: i18n.t("faq_section.text_6"),
        },
        {
            id: 7,
            open: false,
            title: i18n.t("faq_section.title_7"),
            content: i18n.t("faq_section.text_7"),
        },
        {
            id: 8,
            open: false,
            title: i18n.t("faq_section.title_8"),
            content: i18n.t("faq_section.text_8"),
        },
        {
            id: 9,
            open: false,
            title: i18n.t("faq_section.title_9"),
            content: i18n.t("faq_section.text_9"),
        },
        {
            id: 10,
            open: false,
            title: i18n.t("faq_section.title_10"),
            content: i18n.t("faq_section.text_10"),
        },
        {
            id: 11,
            open: false,
            title: i18n.t("faq_section.title_11"),
            content: i18n.t("faq_section.text_11"),
        },
        // {
        //     id: 12,
        //     open: false,
        //     title: i18n.t("faq_section.title_12"),
        //     content: i18n.t("faq_section.text_12"),
        // },
    ];
};
