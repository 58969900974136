import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setVacationStatus } from "./_actions";
import { getVacationsCalendar } from "../Calendar/_actions";

const ForApprovalLogic = () => {
    const dispatch = useDispatch();
    const vacations = useSelector((state) => state.vacationDays.vacations);
    const selectedMonth = useSelector((state) => state.vacationDays.selectedMonth);

    useEffect(() => {
        if (vacations) {
            const onlyPending = vacations.filter((item) => item.status === "pending");
            setState(onlyPending);
        }
    }, [vacations]);

    const [state, setState] = useState([]);

    const openForApproval = (data) => {
        dispatch({
            type: "SHOW_FOR_APPROVAL_POPUP",
            payload: data,
        });
    };

    const setStatus = (id, status) => {
        setVacationStatus({ id, status }, dispatch).then((response = {}) => {
            const list = state;
            let findEdited = list.find((item) => item.id === id);
            findEdited.status = status;
            const updateList = list.map((item) => {
                return item.id === id ? findEdited : item;
            });
            setState(updateList);
            if (response.status === 204) {
                getVacationsCalendar(selectedMonth, dispatch);
            }
        });
    };
    return { state, setStatus, openForApproval };
};

export default ForApprovalLogic;
