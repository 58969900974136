import React from "react";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from "react-router-dom";

import Logic from "./Logic";

import st from "./styles.module.scss";

const Index = () => {
    const { t } = useTranslation();

    const { reveiwSettings, reviews, resetBookingForm } = Logic();

    return (
        <div className={st.reviews_section}>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h3 className={st.title}>
                            <b> {t("reviews_section.title_1")}</b> {t("reviews_section.title_2")}
                        </h3>
                        <Slider {...reveiwSettings} className={st.slider_wrapper}>
                            {reviews.length > 0 &&
                                reviews.map((item) => {
                                    return (
                                        <a
                                            target='_blank'
                                            href='https://www.google.com/search?q=park%20fly%20geneva&rlz=1C1CHBF_enBG814BG814&sxsrf=AOaemvIUvX_Iettwxi7N3nCWrM-eTlWMbg:1641315564120&ei=5HzUYZjbDOqKxc8PpM2A0AU&ved=2ahUKEwihh6f5yJj1AhUQQfEDHeghApIQvS56BAgwEBs&uact=5&oq=park+fly+geneva&gs_lcp=Cgdnd3Mtd2l6EAMyBAgjECcyBQgAEIAEMgYIABAWEB4yBggAEBYQHjIICAAQFhAKEB46BwgAEEcQsAM6CggAEEcQsAMQyQNKBAhBGABKBAhGGABQnwdYxghg_wloAXACeACAAWmIAc4BkgEDMC4ymAEAoAEByAEIwAEB&sclient=gws-wiz&tbs=lf:1,lf_ui:3&tbm=lcl&rflfq=1&num=10&rldimm=6178764376679675559&lqi=Cg9wYXJrIGZseSBnZW5ldmFIlbuWsOutgIAIWh0QABABEAIYABgBGAIiD3BhcmsgZmx5IGdlbmV2YZIBFHB1YmxpY19wYXJraW5nX3NwYWNl&rlst=f#rlfi=hd:;si:6178764376679675559;mv:[[46.22620123169867,6.108135494210818],[46.22173279592099,6.095475467660525],null,[46.22396705927293,6.101805480935671],17]'
                                            key={item.profile_photo_url}
                                            className={st.review_box}
                                        >
                                            <div className={st.stars}>
                                                {[1, 2, 3, 4, 5].map((n) => {
                                                    if (item.rating >= n) {
                                                        return (
                                                            <i
                                                                key={n + 10}
                                                                className='fas fa-star'
                                                            ></i>
                                                        );
                                                    } else {
                                                        return (
                                                            <i key={n} className='far fa-star'></i>
                                                        );
                                                    }
                                                })}
                                            </div>
                                            <div className={st.from_to}>
                                                <b>{item.rating}</b> {t("reviews_section.from")} 5
                                            </div>
                                            <div className={st.top_section}>
                                                <div
                                                    className={st.img}
                                                    style={{
                                                        backgroundImage:
                                                            "url(" + item.profile_photo_url + ")",
                                                    }}
                                                ></div>
                                                <div>
                                                    <p className={st.name}>{item.author_name}</p>
                                                    {/* <p className={st.days_stay}>
                                                        a laissé sa voiture durant{" "}
                                                        <b>10 semaines</b> en main de Parkfly Geneva
                                                    </p> */}
                                                </div>
                                            </div>

                                            <div className={st.review}>{item.text}</div>
                                        </a>
                                    );
                                })}
                        </Slider>
                        <div className={st.button_wrapper}>
                            <Link
                                className='green_btn_h'
                                to='reservation'
                                onClick={() => {
                                    resetBookingForm();
                                }}
                            >
                                {t("book_btn")}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
